import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatDateTime } from "shared/resources";
import { dropdownActions, initialModalState } from "./constants";
import { NoteIcon, Play, X } from "assets/images";
import { callSessionLogs } from "api";
import { Input, Modal, PlayAudioModal, TableManager } from "components";

function ShowNotesModal({ handleClose, notes }) {
  return (
    <div className="p-4 w-[500px] h-[500px] overflow-auto">
      <div className="flex justify-between">
        <h2 className="text-lg font-bold mb-4">Disposition</h2>
        <div className="cursor-pointer" onClick={handleClose}>
          <X />
        </div>
      </div>
      <div className="flex flex-col space-y-3 mt-4">
        <div className="flex flex-col gap-2">
          <label className="text-sm">Connected</label>
          <Input value={notes.connected} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">Promise to pay</label>
          <Input value={notes.promiseToPay} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">Right party contact</label>
          <Input value={notes.rightPartyContact} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">No. of times called</label>
          <Input value={notes.noOfTimesCalled} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">No. of times connected</label>
          <Input value={notes.noOfTimesConnected} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">Reason for delinquency</label>
          <Input value={notes.deliquencyReason} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">PIP Date</label>
          <Input value={notes.pipDate} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">PIP Amount</label>
          <Input value={notes.pipAmount} />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">Comment</label>
          <Input value={notes.comment} />
        </div>
      </div>
    </div>
  );
}

function SessionLogs() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(initialModalState);

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_at",
        header: () => t("overview.date"),
        cell: (props) => {
          const [date, time] = formatDateTime(props?.getValue());
          return (
            <span>
              {date}&nbsp;&#183;&nbsp;{time}
            </span>
          );
        },
      },
      {
        accessorKey: "from",
        header: () => t("overview.from"),
      },
      {
        accessorKey: "to",
        header: () => t("overview.to"),
      },
      {
        accessorKey: "status",
        header: () => t("overview.status"),
        cell: (props) => {
          const logs = props?.row?.original || {};
          return (
            <p className="uppercase font-medium">
              {logs.status === 1 ? (
                <p className="text-green-400">{t("overview.success")}</p>
              ) : (
                <p className="text-danger uppercase">{t("overview.failed")}</p>
              )}
            </p>
          );
        },
      },
      {
        accessorKey: "duration",
        header: () => t("overview.duration"),
      },
      {
        accessorKey: "action",
        header: () => "Play Recording",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <div className="flex gap-3">
              {element.recording_file ? (
                <span
                  className="text-green cursor-pointer"
                  onClick={() => {
                    setShowModal({
                      isShow: true,
                      modalType: dropdownActions.PLAY_RECORDING,
                      elementData: element,
                      type: "center",
                    });
                  }}
                >
                  <Play className="w-6 h-6" />
                </span>
              ) : (
                <p>---</p>
              )}
            </div>
          );
        },
        meta: {
          colType: "action",
          width: "5%",
        },
      },
      {
        accessorKey: "action",
        header: () => "Dispositions",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <div className="flex gap-3">
              {element.metadata &&
              JSON.parse(element.metadata) &&
              JSON.parse(element.metadata).notes ? (
                <span
                  className="text-green cursor-pointer"
                  onClick={() => {
                    setShowModal({
                      isShow: true,
                      modalType: dropdownActions.SHOW_NOTES,
                      elementData: JSON.parse(element.metadata).notes,
                      type: "center",
                    });
                  }}
                >
                  <NoteIcon className="w-6 h-6" />
                </span>
              ) : (
                <p>---</p>
              )}
            </div>
          );
        },
        meta: {
          colType: "action",
          width: "5%",
        },
      },
    ],
    [id]
  );
  const { isShow, modalType, elementData } = showModal;
  function handleCloseModal() {
    setShowModal(initialModalState);
  }
  return (
    <>
      <TableManager
        {...{
          fetcherKey: "callSessionLogs",
          fetcherFn: callSessionLogs,
          columns,
          showPagination: true,
          bulkActionsAccKey: "uuid",
          back: true,
          extraParams: {
            session_uuid: id,
          },
        }}
      />
      {isShow && modalType === dropdownActions.PLAY_RECORDING && (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <PlayAudioModal
            type="recording"
            fileNameAccessorKey={"recording_file"}
            showModal={showModal}
            handleClose={handleCloseModal}
            customLabel="Recording"
          />
        </Modal>
      )}
      {isShow && modalType === dropdownActions.SHOW_NOTES && (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={true}
        >
          <ShowNotesModal handleClose={handleCloseModal} notes={elementData} />
        </Modal>
      )}
    </>
  );
}

export default SessionLogs;
