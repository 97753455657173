import { useMutation } from "@tanstack/react-query";
import { contactListsWithoutBatch } from "api";

export default function useContactListWithoutBatch() {
  const contactListRequest = useMutation({
    mutationFn: contactListsWithoutBatch,
    mutationKey: ["contactListsWithoutBatch"],
  });
  return { ...contactListRequest };
}
