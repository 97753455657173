import {
  ArrowsLeftRight,
  Chair,
  Crown,
  DeviceTabletCamera,
  GroupUser,
  User,
  MusicNote,
  PhoneCall,
  Star,
  VinylRecord,
  Arrow,
  Voicemail,
  Integration,
  Omni,
  Bots,
  AnalyticsIcon,
  TemplateIcon,
  TicketIcon,
  KnowledgeIcon,
} from "assets/images";
import { toastEmitter } from "components/Toast";
import { Howl } from "howler";
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import { Braces, File } from "lucide-react";
import moment from "moment";
import Pdf from "assets/images/Pdf";
import Wps from "assets/images/Wps";
import Zip from "assets/images/Zip";

const synthesis = window?.speechSynthesis;

const INCOMING_RING = "incomingRing.ogg";
const OUTGOING_RING = "outgoingRing.mp3";

export const textToSpeechRegx = /^[a-zA-Z0-9,?.'" ]*$/;
export const alphaNumericRegx = /^[a-zA-Z0-9_ ]*$/;
export const numericRegx = /^[0-9]*$/;
export const passwordRegx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
export const IPRegex =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:(?:[0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,7}:|(?:[0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|(?:[0-9a-fA-F]{1,4}:){1,5}(?::[0-9a-fA-F]{1,4}){1,2}|(?:[0-9a-fA-F]{1,4}:){1,4}(?::[0-9a-fA-F]{1,4}){1,3}|(?:[0-9a-fA-F]{1,4}:){1,3}(?::[0-9a-fA-F]{1,4}){1,4}|(?:[0-9a-fA-F]{1,4}:){1,2}(?::[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:(?:(?::[0-9a-fA-F]{1,4}){1,6})?|:(?:(?::[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(?::[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(?:ffff(?::0{1,4}){0,1}:){0,1}(?:(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])|(?:[0-9a-fA-F]{1,4}:){1,4}:(?:(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

export const getRequiredText = (field) => {
  return `${field} is required.`;
};
export const MONTHLY = "MONTHLY";
export const ANNUALLY = "ANNUALLY";
export const DAILY = "DAILY";

export const ADMIN = 4;
export const OWNER = 2;
export const MEMBER = 3;

export const prefix = "+234";

export const NG_CURRENCY_SYMBOL = "₦";

export const PAY_WITH_CARD = "pay_with_card";

export function getToken() {
  return localStorage.getItem("fonu-acc-tkn") || null;
}

export const rolesArray = ({ Admin, Owner, Member }) => {
  return [
    { id: 2, label: Admin, value: 4 },
    { id: 3, label: Owner, value: 2 },
    { id: 1, label: Member, value: 3 },
  ];
};

export const roleIconLookup = {
  4: <Chair />,
  2: <Crown />,
  3: <Star />,
};

export const IncomingRing = new Howl({
  src: [`${window.location.origin}/${INCOMING_RING}`],
  loop: true,
});

export const OutgoigRing = new Howl({
  src: [`${window.location.origin}/${OUTGOING_RING}`],
  loop: true,
});

export const navigationArr = [
  {
    id: 1,
    path: "/overview",
    parent: "overview",
    label: "navigation.overview",
    access: [ADMIN, OWNER, MEMBER],
  },
  // {
  //   id: 2,
  //   path: '/inbox',
  //   parent: 'inbox',
  //   label: 'Inbox',
  //   access: [ADMIN, OWNER, MEMBER],
  // },
  {
    id: 3,
    path: "/phone-numbers/active-numbers",
    label: "navigation.phoneNumbers",
    parent: "phone-numbers",
    access: [ADMIN, OWNER],
    innerNavigation: [
      {
        id: 1,
        path: "/phone-numbers/active-numbers",
        label: "navigation.activeNumbers",
        icon: PhoneCall,
        access: [ADMIN, OWNER],
      },
      {
        id: 2,
        path: "/phone-numbers/port-number",
        label: "overview.portExistingNumber",
        icon: Arrow,
        access: [ADMIN, OWNER],
      },
      {
        id: 3,
        path: "/phone-numbers/details",
        label: "navigation.callDetails",
        icon: PhoneCall,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 4,
        path: "/phone-numbers/recordings",
        label: "navigation.callRecordings",
        icon: VinylRecord,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 5,
        path: "/phone-numbers/bulk-call-details",
        label: "navigation.bulkCallDetails",
        icon: PhoneCall,
        access: [ADMIN, OWNER],
      },
    ],
  },
  {
    id: 4,
    path: "/sms",
    parent: "sms",
    label: "SMS",
    access: [ADMIN, OWNER, MEMBER],
  },
  {
    id: 5,
    path: "/live-chat",
    parent: "live-chat",
    label: "Live chat",
    access: [ADMIN, OWNER, MEMBER],
  },

  {
    id: 7,
    path: "/bundle/purchase",
    parent: "bundle",
    label: "navigation.bundle",
    access: [ADMIN, OWNER],
    innerNavigation: [
      {
        id: 1,
        path: "/bundle/purchase",
        label: "navigation.bundle",
        icon: User,
        access: [ADMIN, OWNER],
      },
      {
        id: 2,
        path: "/bundle/data",
        label: "navigation.data",
        icon: GroupUser,
        access: [ADMIN, OWNER],
      },
    ],
  },
  {
    id: 8,
    path: "/teams/members",
    parent: "teams",
    label: "navigation.teams",
    access: [ADMIN, OWNER, MEMBER],
    innerNavigation: [
      {
        id: 1,
        path: "/teams/members",
        label: "navigation.members",
        icon: User,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 2,
        path: "/teams/all-teams",
        label: "navigation.teams",
        icon: GroupUser,
        access: [ADMIN, OWNER, MEMBER],
      },
    ],
  },
  // {
  //   id: 8,
  //   path: '/campaign',
  //   parent: 'campaign',
  //   label: 'Campaign',
  //   access: [ADMIN, OWNER],
  // },
  // {
  //   id: 9,
  //   path: '/report',
  //   parent: 'report',
  //   label: 'Report',
  //   access: [ADMIN, OWNER],
  // },
  // {
  //   id: 10,
  //   path: '/automations',
  //   parent: 'automations',
  //   label: 'Automations',
  //   access: [ADMIN, OWNER],
  // },
  // {
  //   id: 11,
  //   path: "/KYC/customer-kyc",
  //   parent: "KYC",
  //   label: "navigation.kycVerification",
  //   access: [ADMIN, OWNER],
  //   innerNavigation: [
  // {
  //   id: 1,
  //   path: "/KYC/customer-kyc",
  //   label: "navigation.customerKYC",
  //   icon: User,
  //   access: [ADMIN, OWNER],
  // },
  // {
  //   id: 2,
  //   path: "/KYC/kyc-details",
  //   label: "navigation.reports",
  //   icon: GroupUser,
  //   access: [ADMIN, OWNER],
  // },
  //   ],
  // },
  {
    id: 9,
    path: "/autodial/bulk-dial",
    parent: "autodial",
    label: "navigation.bulkDialContacts",
    access: [ADMIN, OWNER, MEMBER],
    innerNavigation: [
      {
        id: 1,
        path: "/autodial/bulk-dial",
        label: "Bulk Dial",
        icon: PhoneCall,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 2,
        path: "/autodial/contacts",
        label: "Contacts",
        icon: VinylRecord,
        access: [ADMIN, OWNER, MEMBER],
      },
    ],
  },
  {
    id: 10,
    path: "/settings/voicemails",
    parent: "settings",
    label: "overview.settings",
    access: [ADMIN, OWNER, MEMBER],
    innerNavigation: [
      {
        id: 1,
        path: "/settings/voicemails",
        label: "navigation.voicemails",
        icon: Voicemail,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 2,
        path: "/settings/forwarding",
        label: "navigation.setForwarding",
        icon: ArrowsLeftRight,
        access: [ADMIN, OWNER],
      },
      {
        id: 3,
        path: "/settings/greetings",
        label: "navigation.customGreetings",
        icon: MusicNote,
        access: [ADMIN, OWNER],
      },
      {
        id: 4,
        path: "/settings/phonemenu",
        label: "navigation.phoneMenu",
        icon: DeviceTabletCamera,
        access: [ADMIN, OWNER],
      },
      // {
      //   id: 5,
      //   path: "/settings/customer-kyc",
      //   label: "navigation.customerKYC",
      //   icon: User,
      //   access: [ADMIN, OWNER],
      // },
      // {
      //   id: 6,
      //   path: "/settings/kyc-details",
      //   label: "navigation.reports",
      //   icon: GroupUser,
      //   access: [ADMIN, OWNER],
      // },
      {
        id: 6,
        path: "/settings/verification",
        label: "Verification",
        icon: User,
        access: [ADMIN, OWNER],
      },
      {
        id: 9,
        path: "/settings/integrations",
        label: "navigation.integration",
        icon: Integration,
        access: [ADMIN, OWNER],
      },
      {
        id: 11,
        path: "/settings/developers",
        parent: "developers",
        label: "Developers",
        icon: Braces,
        access: [ADMIN, OWNER],
      },
      {
        id: 12,
        path: "/settings/omni-channel",
        label: "Omni Channel",
        icon: Omni,
        access: [ADMIN, OWNER],
      },
      {
        id: 13,
        path: "/settings/bots",
        label: "Bots",
        icon: Bots,
        access: [ADMIN, OWNER],
      },
      {
        id: 14,
        path: "/settings/analytics",
        label: "Analytics",
        icon: AnalyticsIcon,
        access: [ADMIN, OWNER],
      },
      {
        id: 15,
        path: "/settings/template-store",
        label: "Template Store",
        icon: TemplateIcon,
        access: [ADMIN, OWNER],
      },
      {
        id: 16,
        path: "/settings/tickets",
        label: "Tickets",
        icon: TicketIcon,
        access: [ADMIN, OWNER],
      },
    ],
  },
  {
    id: 10,
    path: "/ai-fonu/knowledge-base",
    parent: "ai-fonu",
    label: "AI Fonu",
    access: [ADMIN, OWNER, MEMBER],
    innerNavigation: [
      {
        id: 1,
        path: "/ai-fonu/knowledge-base",
        label: "Knowledge Base",
        icon: KnowledgeIcon,
        access: [ADMIN, OWNER, MEMBER],
      },
      {
        id: 2,
        path: "/ai-fonu/ai-agents",
        label: "Ai Agents",
        icon: Voicemail,
        access: [ADMIN, OWNER, MEMBER],
      },
    ],
  },
  {
    id: 11,
    path: "/flow",
    parent: "",
    label: "flow",
    access: [ADMIN, OWNER, MEMBER],
  },
];

export const stringToColour = (str) => {
  if (!str || !str.length) return;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
};

export const darkenColor = (hexColor, factor = 20) => {
  if (!hexColor) return "#fff";
  // Remove the '#' character if present
  hexColor = hexColor.replace("#", "");

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Darken the color by reducing the RGB values
  const newR = Math.max(0, r - factor);
  const newG = Math.max(0, g - factor);
  const newB = Math.max(0, b - factor);

  // Convert the darkened RGB back to hex
  const darkenedColor = `#${newR.toString(16).padStart(2, "0")}${newG
    .toString(16)
    .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;

  return darkenedColor;
};

export const useMonthYear = (isoDateString) => {
  return moment(isoDateString).format("DD MMM, YYYY");
};

export const formatTime = (isoDateString) => {
  return moment(isoDateString).format("hh:mm A");
};

export const formatFileSize = (size) => {
  if (size < 1024) {
    return size + " bytes";
  } else if (size < 1048576) {
    return (size / 1024).toFixed(2) + " KB";
  } else if (size < 1073741824) {
    return (size / 1048576).toFixed(2) + " MB";
  } else {
    return (size / 1073741824).toFixed(2) + " GB";
  }
};

export const formatType = (type) => {
  if (type.includes("png")) {
    return (
      <div className="relative">
        <File size={40} />
        <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-green-300 text-white text-[12px] font-bold">
          png
        </p>
      </div>
    );
  } else if (type.includes("pdf")) {
    return <Pdf />;
  } else if (type.includes("doc")) {
    return <Wps />;
  } else if (type.includes("zip")) {
    return <Zip />;
  } else if (type.includes("jpg")) {
    return (
      <div className="relative">
        <File size={40} />
        <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-[#a87532] text-white text-[12px] font-bold">
          jpg
        </p>
      </div>
    );
  } else if (type.includes("url")) {
    return (
      <div className="relative">
        <File size={40} />
        <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-[#2de6d9] text-white text-[12px] font-bold">
          url
        </p>
      </div>
    );
  } else if (type.includes("svg")) {
    return (
      <div className="relative">
        <File size={40} />
        <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-[#A8328f] text-white text-[12px] font-bold">
          svg
        </p>
      </div>
    );
  } else if (type.includes("jpeg")) {
    return (
      <div className="relative">
        <File size={40} />
        <p className="absolute inset-0 top-4 h-[20px] w-[25px] flex justify-center items-center rounded-sm text-center bg-[#a87532] text-white text-[12px] font-bold">
          jpeg
        </p>
      </div>
    );
  } else {
    return <File size={40} />;
  }
};

export const formatDuration = (durationInSeconds) => {
  const hours = Math.floor(durationInSeconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (durationInSeconds % 60).toString().padStart(2, "0");

  return `${hours} : ${minutes} : ${seconds}`;
};
export const lightenColor = (hexColor, factor = 20) => {
  if (!hexColor) return "#fff";

  // Remove the '#' character if present
  hexColor = hexColor.replace("#", "");

  // Convert the hex color to RGB
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Lighten the color by increasing the RGB values
  const newR = Math.min(255, r + factor);
  const newG = Math.min(255, g + factor);
  const newB = Math.min(255, b + factor);

  // Convert the lightened RGB back to hex
  const lightenedColor = `#${newR.toString(16).padStart(2, "0")}${newG
    .toString(16)
    .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;

  return lightenedColor;
};

export const formatPhoneNumber = (number) => {
  if (!number || typeof number === "object") return;
  const phoneNumber = parsePhoneNumber(`${number.replace(/\s/g, "")}`);
  return phoneNumber?.formatInternational() ?? number?.replace("+", "");
};

export const maskNumber = (number) => {
  const numStr = number.toString();
  if (numStr.length <= 4) return numStr;
  return "*".repeat(numStr.length - 4) + numStr.slice(-4);
};

export const ayt = (number) => {
  return new AsYouType().input(number);
};

export const formatDateTime = (timestring, utc = true) => {
  if (!timestring) return "---";
  if (utc) {
    const utcMoment = moment.utc(timestring);
    const localMoment = utcMoment.local();
    let timeStrings = localMoment?.format();
    if (!timeStrings) return "---";
    return moment(timeStrings).format("MMM DD, YYYY&hh:mm A")?.split("&");
  }
  return moment(timestring).format("MMM DD, YYYY&hh:mm A")?.split("&");
};

export const ForwardingArray = [
  { id: 1, label: "Teams", value: "TEAM" },
  { id: 2, label: "Members", value: "EXTENSION" },
  { id: 3, label: "Phone number", value: "NUMBER" },
  { id: 4, label: "Fonu number", value: "FONU_NUMBER" },
  { id: 5, label: "Hangup", value: "HANGUP" },
  { id: 6, label: "Voicemail", value: "VOICEMAIL" },
  { id: 7, label: "Phone menu", value: "IVR" },
  { id: 8, label: "SIP Address", value: "IP" },
];

export const parseJwt = (token) => {
  if (!token) return;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const handleSpeakText = (text = "") => {
  if (!text) return;
  if ("speechSynthesis" in window) {
    const utterance = new SpeechSynthesisUtterance(text);
    const voices = synthesis.getVoices();
    utterance.lang = "en";
    utterance.voice = voices?.[2];
    synthesis.speak(utterance);
  } else {
    toastEmitter(
      "error",
      "Speak feature is not supported in your browser. Please use a modern browser that supports this feature."
    );
  }
};

export const convertTextToSpeechFile = (text = "") => {
  if (!text) return;
};

export const downloadFile = (file, isUrl = false) => {
  if (!file) return;
  const element = document.createElement("a");
  element.setAttribute("href", isUrl ? file : URL.createObjectURL(file));
  element.setAttribute("download", isUrl ? "voicemail" : file?.name ?? "");
  element.setAttribute("target", "_blank");
  element.click();
};

export const secondsToHHMMSS = (seconds = 0) => {
  if (!seconds) return "00:00";
  return moment.utc(seconds * 1000).format("mm:ss");
};
export const SecondsTohhmmss = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;
  // round seconds
  seconds = Math.round(seconds * 100) / 100;
  let result = hours === 0 ? "" : hours < 10 ? "0" + hours + ":" : hours + ":";
  result += minutes < 10 ? "0" + minutes : minutes;
  result += ":" + (seconds < 10 ? "0" + seconds : seconds);
  return result;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const wantToSendMessage = (e) => {
  if (!e) return false;
  if (
    e?.type === "click" ||
    (e.type === "keydown" &&
      e?.key === "Enter" &&
      !(e?.ctrlKey || e?.shiftKey || e?.altKey))
  ) {
    return true;
  } else {
    return false;
  }
};

export function base64toFile(base64Data, fileName = "selfie.png") {
  if (!base64Data) return;
  // Split the base64 string to get the content type and the actual base64 content
  const base64Parts = base64Data.split(";base64,");
  const contentType = base64Parts[0].split(":")[1];
  const raw = window.atob(base64Parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([uInt8Array], { type: contentType });

  // Create a File from the Blob
  const file = new File([blob], fileName, { type: contentType });

  return file;
}

export function sumOfTwoDigits(digit1, digit2) {
  // Check if the inputs are valid numbers
  if (typeof digit1 !== "number" || typeof digit2 !== "number") {
    return "Please provide valid numbers.";
  }

  // Calculate the sum of the two digits
  let sum = digit1 + digit2;

  // Return the result
  return sum?.toFixed(2) ?? sum;
}

export function generateApiKey(length = 32) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let publicKey = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    publicKey += characters[randomIndex];
  }
  return publicKey;
}
