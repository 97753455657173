import { routes } from "api/routes";
import { HubspotIcon } from "assets/images";
import { Button } from "components";
import React, { useEffect } from "react";
import { getToken } from "shared/resources";
const Hubspot = () => {
  const CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
  const BACKEND_URL = process.env.REACT_APP_BASE_API_URL;
  const REDIRECT_URI = process.env.REACT_APP_HUBSPOT_REDIRECT_URI;

  const handleAuthorize = () => {
    const authUrl = `https://app.hubspot.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=oauth`;
    window.location.href = authUrl;
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      fetch(`${BACKEND_URL}${routes.CREATE_HUBSPOT_INTEGRATION.URL}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            console.log("data:", data);
          } else {
            console.log("data:", data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);
  return (
    <div
      className={`h-[236px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <HubspotIcon className="w-10 h-10" />
          <p className="">HubSpot</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            You can connect to CRM account to use CRM HubSpot actions in the
            flow.
          </p>

          <div className="w-[400px]">
            <Button onClick={handleAuthorize}>Connect CRM Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hubspot;
