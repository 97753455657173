/* eslint-disable no-unused-vars */
import { PaperPlaneTilt, Paperclip, Smiley, X } from 'assets/images';
import { Input } from 'components';
import PreviewModal from 'components/FilePreview/PreviewModal';
import EmojiPicker from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from 'react';
import { wantToSendMessage } from 'shared/resources';
import Mentions from '../../Mentions';
import axios from 'axios';
import { routes } from 'api/routes';
import { getToken } from 'shared/resources';
import { toastEmitter } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';

const Footer = ({
  chatId = '',
  messages,
  setMessages,
  fetchMessages,
  mockZigiResponses,
  isZigiBot = false,
  humanAgent = false,
  isInitialHumanAgent = false,
  createConversation,
  conversation,
  setConversation,
  buttonClicked,
}) => {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const filePreviews = selectedFiles && selectedFiles.length > 0;
  const [emojiSelection, setEmojiSelection] = useState(false);
  const [tryingToMention, setTryingToMention] = useState(false);
  const textAreaRef = useRef(null);
  const filePicker = useRef(null);

  useEffect(() => {
    if (textAreaRef?.current) {
      const element = textAreaRef.current;
      element.style.boxSizing = 'border-box';
      const offset = element.offsetHeight - element.clientHeight;
      element.addEventListener('input', function (event) {
        event.target.style.height = 0;
        event.target.style.height = event.target.scrollHeight + offset + 'px';
      });
      return () => {
        if (element) {
          element.removeEventListener('input', () => null, false);
        }
      };
    }
  }, [textAreaRef?.current]);

  useEffect(() => {
    if (!tryingToMention) {
      if (message) {
        textAreaRef.current.focus();
      }
    }
  }, [tryingToMention]);

  useEffect(() => {
    try {
      const conversationUuid = localStorage.getItem('conversation_uuid');
      if (conversationUuid) {
        const fetchConversations = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION_BY_UUID.URL}/${conversationUuid}`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                  "X-Company-UUID": user.company_uuid,
                },
              }
            );
            setConversation(response.data);
          } catch (error) {
            console.error('Error fetching conversations:', error);
          }
        };
        fetchConversations();
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  }, []);

  useEffect(() => {
    if (message) {
      const tokens = message?.trim()?.split(' ');
      const highlightedTokens = tokens?.map((token) => {
        if (token.charAt(0) === '@') {
          return `<span class="bg-green-100 p-1 rounded-md">${token}</span>`;
        } else {
          return token;
        }
      });
      const newValueTokens = highlightedTokens.join(' ');
      if (textAreaRef?.current) {
        textAreaRef.current.innerHTML = newValueTokens;
      }
      getCursorPositionAtEnd(textAreaRef.current);
    }
  }, [message]);

  function getCursorPositionAtEnd(contentEditableElement) {
    let range, selection;
    if (document.createRange) {
      range = document.createRange();
      range.selectNodeContents(contentEditableElement);
      range.collapse(false);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(contentEditableElement);
      range.collapse(false);
      range.select();
    }
  }

  function handleFileSelect(e) {
    const files = e?.target?.files ?? {};
    if (selectedFiles && selectedFiles.length > 0) {
      setSelectedFiles((prev) => [...prev, ...files]);
    } else {
      setSelectedFiles([...files]);
    }
    e.target.value = null;
  }

  function cleanUpTextArea() {
    if (textAreaRef?.current) {
      const element = textAreaRef.current;
      element.style.boxSizing = 'border-box';
      const offset = element.offsetHeight - element.clientHeight;
      element.innerText = '';
      element.style.height = 0;
      element.style.height = element.scrollHeight + offset + 'px';
    }
    setMessage('');
  }

  const sendMessageZigi = async (newMessage) => {
    if (!newMessage || !localStorage.getItem('conversation_uuid')) return;

    const API_BASE_URL = process.env.REACT_APP_ZIGI_BASE_API_URL;
    const SEND_MESSAGE_URL = routes.SEND_ZIGI_MESSAGE.URL;
    const UPDATE_CONVERSATION_URL = routes.UPDATE_CONVERSATION.URL;

    try {
      const message = {
        conversation_uuid: conversation.uuid,
        from: user?.company_uuid,
        to: conversation.initiator,
        metadata: {
          body: newMessage,
          iteration: conversation.metadata.iteration,
          displayName: user?.first_name + ' ' + user?.last_name,
        },
        company_uuid: user?.company_uuid,
        channel: 'live-chat',
      };

      // setMessages([
      //   ...messages,
      //   {
      //     ...message,
      //   },
      // ]);

      await axios.post(`${API_BASE_URL}${SEND_MESSAGE_URL}`, message, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "X-Company-UUID": user?.company_uuid,
        },
      });

      const updateData = {
        metadata: {
          ...conversation.metadata,
          lastMessage: newMessage, // Add last sent message to metadata
        },
      };

      await axios.patch(
        `${API_BASE_URL}${UPDATE_CONVERSATION_URL}/${conversation.uuid}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user?.company_uuid,
          },
        }
      );
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // const fetchMessagesZigi = async () => {
  //   try {
  //     const response = await axios.get(
  //       `http://localhost:8088/live-chat/messages?conversationUuid=${conversation.uuid}`
  //     );
  //     setMessages(response.data);
  //   } catch (error) {
  //     console.error("Error fetching messages:", error);
  //   }
  // };

  const sendMessage = async (userMessage) => {
    // handleButtonClick(userMessage);
    if (!userMessage?.trim()) return;
    if (humanAgent) {
      const botReply = mockZigiResponses(userMessage.trim());
      setMessages([
        ...messages,
        { from: 'zigi', metadata: { body: botReply } },
      ]);
    }

    const newMessages = [
      ...messages,
      { from: 'user', metadata: { body: userMessage.trim() } },
    ];

    if (isZigiBot && !localStorage.getItem('is_human_agent')) {
      if (userMessage.toLowerCase().includes('human')) {
        const newConversation = await createConversation();
        setConversation(newConversation);
        localStorage.setItem('conversation_uuid', newConversation.uuid);
        localStorage.setItem('is_human_agent', true);
        const botReply = mockZigiResponses('agent-connected');
        setMessages([
          ...newMessages,
          {
            from: 'zigi',
            metadata: { body: `Connecting you to a human agent...` },
          },
          { from: 'zigi', metadata: { body: botReply } },
        ]);
      } else {
        const botReply = mockZigiResponses(userMessage.trim());
        setMessages([
          ...newMessages,
          { from: 'zigi', metadata: { body: botReply } },
        ]);
      }
    } else {
      try {
        if (localStorage.getItem('is_human_agent')) {
          sendMessageZigi(userMessage);
        } else {
          await axios.post(
            `${process.env.REACT_APP_BASE_API_URL}${routes.SEND_WHATSAPP_MESSAGE.URL}`,
            {
              to: chatId,
              message: userMessage.trim(),
            },
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
              },
            },
          );
          setMessages([
            ...newMessages,
            { from: 'user', metadata: { body: userMessage.trim() } },
          ]);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          return (window.location.href = '/');
        } else {
          toastEmitter('error', error?.response?.data?.error?.message);
        }
      }
    }
    cleanUpTextArea();
  };

  function handleEmoji(e) {
    setEmojiSelection(false);
    const element = textAreaRef.current;
    if (element) {
      const messageWithEmoji = element.innerText + ' ' + e?.emoji;
      element.innerText = messageWithEmoji;
      setMessage(messageWithEmoji);
      element.focus();
    }
  }

  function handleSendMessage(e) {
    const messageToSend = message?.trim();
    if (wantToSendMessage(e) && messageToSend && !tryingToMention) {
      e.preventDefault();
      e.stopPropagation();
      sendMessage(messageToSend);
    }
  }

  function handleChangeMessage(e) {
    e.preventDefault();
    const message = `${e?.target?.textContent ?? ''}`.trim();
    const messageTokens = message?.trim()?.split(' ');
    const isTryingToMention =
      messageTokens?.pop()?.charAt(0) === '@' ? true : false;

    if (isTryingToMention) {
      setTryingToMention(true);
    } else {
      setTryingToMention(false);
    }
    setMessage(message);
  }
  return (
    <>
      {filePreviews ? (
        <div className="flex gap-2 justify-between">
          <PreviewModal
            files={selectedFiles}
            handleClose={() => setSelectedFiles([])}
            setSelectedFiles={setSelectedFiles}
          />
          <X
            className="text-danger cursor-pointer"
            onClick={() => setSelectedFiles([])}
          />
        </div>
      ) : null}
      <div className="flex items-center gap-4 p-6 bg-grey-100 border-grey-300 border-t">
        <span
          onClick={() => filePicker?.current?.click()}
          className="cursor-pointer"
        >
          <Paperclip className="text-grey-700" />
          <Input
            ref={filePicker}
            multiple
            extraClasses="hidden"
            type="file"
            id="filePicker"
            onChange={handleFileSelect}
          />
        </span>
        <span>
          <Smiley
            className="text-grey-700 cursor-pointer relative"
            onClick={() => setEmojiSelection(true)}
          />
          {emojiSelection ? (
            <span className="absolute bottom-20">
              <div className="flex gap-2">
                <EmojiPicker
                  lazyLoadEmojis={true}
                  onEmojiClick={handleEmoji}
                  emojiStyle="native"
                  defaultSkinTone="neutral"
                  theme="dark"
                  skinTonesDisabled={true}
                  width={350}
                  height={450}
                  previewConfig={{ showPreview: false }}
                  categories={['smileys_people', 'food_drink', 'activities']}
                  className="custom-emoji-picker"
                />
                <X
                  className="text-danger cursor-pointer"
                  onClick={() => setEmojiSelection(false)}
                />
              </div>
            </span>
          ) : null}
        </span>
        <div className="relative w-full flex items-center h-10">
          <span>
            <div
              role="input"
              ref={textAreaRef}
              name="message"
              onKeyDown={(e) => handleSendMessage(e)}
              onInput={(e) => handleChangeMessage(e)}
              contentEditable={true}
              className="w-full border min-h-[40px] p-2 overflow-auto bg-white rounded-md absolute bottom-0 focus:outline-none focus:ring font-medium text-base text-grey-900 resize-none border-grey-400 focus:border-green focus:ring-green-200 "
            />
          </span>
          {tryingToMention ? (
            <Mentions
              setMessage={setMessage}
              setTryingToMention={setTryingToMention}
              message={message}
              textAreaRef={textAreaRef}
            />
          ) : null}
          <PaperPlaneTilt
            onClick={() => sendMessage(message)}
            className={`${
              message ? 'text-green-400' : 'text-grey-400'
            } absolute right-3 cursor-pointer ml-2`}
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
