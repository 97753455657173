import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateContactListWithoutBatch } from "api";
import { toastEmitter } from "components/Toast";

export default function useUpdateContactWithoutBatch({
  onSuccessFn = () => null,
  onErrorFn = () => null,
} = {}) {
  const queryclient = useQueryClient();
  const Request = useMutation({
    mutationFn: updateContactListWithoutBatch,
    mutationKey: ["updateContactListWithoutBatch"],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        onErrorFn();
        return toastEmitter("error", responseData?.error?.message);
      }
      onSuccessFn();
      queryclient.invalidateQueries(["contactListsWithoutBatch"]);
      toastEmitter("success", responseData?.data?.message);
    },
  });
  return { ...Request };
}
