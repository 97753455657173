import React, { useEffect, useRef, useState } from 'react';
import { Header } from 'components';
import { useSip, useUserDetails } from 'hooks';
import { Outlet, useLocation } from 'react-router-dom';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import { io } from 'socket.io-client';
import { useAuth } from 'hooks/useAuth';
import ChatNotifier from 'components/ChatNotifier';

const splittedBaseUrl = process.env.REACT_APP_BASE_API_URL.split('/');
export const socket = io(`${splittedBaseUrl[0]}//${splittedBaseUrl[2]}`);

export default function AuthLayout() {
  const SIP = useSip();
  const { pathname } = useLocation();
  const { user, setOnlineUsers, setCallData, zigiSocket } = useAuth();
  const [conversation, setConversation] = useState(null);
  const [shouldShowNotification, setShouldShowNotification] = useState(false);
  let timer;
  useUserDetails(SIP);

  const emailRef = useRef(user?.email); // Declare ref at the top level

  // Update the ref whenever the user changes
  useEffect(() => {
    emailRef.current = user?.email;
  }, [user]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((tracks) => {
        tracks.getTracks().forEach((track) => track.stop());
      })
      .catch((err) => {
        console.log('Microphone access denied', err);
      });
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (zigiSocket) {
      zigiSocket.on('conversationStarted', (payload) => {
        console.log('conversation', payload.conversation);
        setConversation(payload.conversation);
        setShouldShowNotification(true);
        timer = setTimeout(() => {
          setShouldShowNotification(false);
        }, 20000);
      });

      zigiSocket.on('conversationAccepted', () => {
        setShouldShowNotification(false);
      });
    }
  }, [zigiSocket]);

  useEffect(() => {
    // Emit when user connects
    if (user && user.email) {
      socket.emit('userConnected', user.email);
    }

    // Listen for updates to the online users list
    socket.on('updateOnlineUsers', (users) => {
      setOnlineUsers([...users]);
    });

    socket.on('updateUserStatus', (callData) => {
      setCallData({ ...callData });
    });

    // Clean up on component unmount
    return () => {
      // Use email from ref to ensure it's the latest value
      socket.emit('userOffCall', emailRef.current);
      socket.off('updateUserStatus');
      socket.off('updateOnlineUsers');
    };
  }, [user]);

  return (
    <SipSocketContext.Provider value={{ socket: SIP }}>
      {pathname?.includes('verify-email') ? null : <Header />}
      <Outlet />
      <ChatNotifier
        isOpen={shouldShowNotification}
        onClose={() => {
          setShouldShowNotification(false);
        }}
        conversation={conversation}
      />
    </SipSocketContext.Provider>
  );
}
