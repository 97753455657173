import React, {
  useState,
  useContext,
  createContext,
  useMemo,
  useEffect,
} from "react";
import { ADMIN, OWNER, getToken } from "shared/resources";
import { io } from "socket.io-client";
const AuthContext = createContext(null);

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export const Auth = ({ children }) => {
  const [user, setCurrentUser] = useState(null);
  const [token, setToken] = useState(getToken());
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [callData, setCallData] = useLocalStorage("callData", {});
  const [zigiSocket, setZigiSocket] = useState();
  let currentURL = new URL(window.location.href);
  currentURL.search = "?current=from-base-origin";

  const setUser = (payload) => {
    if (!user) {
      if ([OWNER, ADMIN].includes(payload?.role_id)) {
        if (!payload?.virtual_number_count) {
          if (window.location.pathname === "/overview") {
            window.history.replaceState({}, "", currentURL);
          }
        }
      }
    }
    setCurrentUser(payload);
    setIsUserLoading(false);
  };

  const setUserToken = (token) => {
    setToken(token);
    localStorage.setItem("fonu-acc-tkn", token);
    window.location.reload(true);
  };

  const clearUser = () => {
    localStorage.clear();
    setCurrentUser(null);
    setToken(null);
  };

  useEffect(() => {
    if (user?.uuid && !zigiSocket) {
      const splittedZigiBaseUrl =
        process.env.REACT_APP_ZIGI_BASE_API_URL.split("/");
      const socket = io(
        `${splittedZigiBaseUrl[0]}//${splittedZigiBaseUrl[2]}`,
        {
          query: {
            companyUuid: user?.company_uuid,
            userId: user?.uuid,
          },
        }
      );
      setZigiSocket(socket);

      return () => {
        zigiSocket?.off("conversationStarted");
      };
    }
  }, [user]);

  const values = useMemo(
    () => ({
      user,
      token,
      setUser,
      clearUser,
      setUserToken,
      isUserLoading,
      setIsUserLoading,
      onlineUsers,
      setOnlineUsers,
      callData,
      setCallData,
      zigiSocket,
    }),
    [user, onlineUsers, callData, zigiSocket]
  );
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
