import * as React from "react";
const Pdf = () => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 67 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4032 0H46.9892L67 19.8123V80.625C67 83.5946 64.5796 86 61.5968 86H5.4032C2.42052 86 0 83.5946 0 80.625V5.37496C0 2.40536 2.4208 0 5.4032 0Z"
      fill="white"
    />
    <path
      d="M5.4032 0.5H46.7835L66.5 20.0208V80.625C66.5 83.3158 64.306 85.5 61.5968 85.5H5.4032C2.69405 85.5 0.5 83.3158 0.5 80.625V5.37496C0.5 2.68413 2.6943 0.5 5.4032 0.5Z"
      stroke="#E8E9EB"
    />
    <path
      d="M65.9198 20.4252H51.2864C48.6265 20.4252 46.468 18.2802 46.468 15.6368V1.0752"
      stroke="#E8E9EB"
    />
    <path
      d="M20.2338 64.4996C19.7451 64.4996 19.276 64.3525 18.8757 64.0751C17.4136 63.058 17.2169 61.9264 17.3096 61.1555C17.5652 59.0354 20.3926 56.8164 25.7157 54.5557C27.8281 50.2635 29.838 44.9751 31.036 40.5566C29.6343 37.7281 28.2719 34.0584 29.2649 31.9058C29.6132 31.1518 30.0472 30.5736 30.8577 30.3236C31.1779 30.2246 31.9869 30.0996 32.2847 30.0996C32.9925 30.0996 33.6147 30.9448 34.0558 31.4657C34.4701 31.9553 35.4097 32.9932 33.5319 40.3235C35.4252 43.9489 38.1078 47.6421 40.6781 50.1711C42.5194 49.8624 44.1037 49.7048 45.3945 49.7048C47.594 49.7048 48.9269 50.1802 49.4704 51.1595C49.9198 51.9694 49.7359 52.9162 48.9226 53.9723C48.1403 54.9867 47.0616 55.5233 45.8046 55.5233C44.0967 55.5233 42.1079 54.5231 39.8901 52.5476C35.9055 53.3199 31.2523 54.6976 27.491 56.2226C26.3168 58.5327 25.1918 60.3936 24.144 61.7584C22.7044 63.6271 21.4628 64.4996 20.2338 64.4996ZM23.9727 57.8243C20.9712 59.3883 19.7479 60.6736 19.6594 61.3977C19.6453 61.5175 19.6074 61.8326 20.2647 62.2988C20.474 62.2376 21.696 61.7206 23.9727 57.8243ZM43.1262 52.0398C44.2709 52.8563 44.5504 53.2691 45.299 53.2691C45.6276 53.2691 46.5644 53.2561 46.9984 52.6948C47.2077 52.4226 47.2892 52.2481 47.3215 52.1544C47.1487 52.0697 46.9198 51.8978 45.6712 51.8978C44.9619 51.8991 44.07 51.9278 43.1262 52.0398ZM32.6344 43.4684C31.6301 46.6902 30.3043 50.1684 28.8787 53.3186C31.8141 52.2625 35.0052 51.3405 38.0025 50.688C36.1064 48.6461 34.2117 46.0963 32.6344 43.4684ZM31.7818 32.4489C31.6442 32.4918 29.9138 34.7369 31.9167 36.6369C33.2496 33.8826 31.8422 32.4306 31.7818 32.4489Z"
      fill="#E2574C"
    />
  </svg>
);
export default Pdf;
