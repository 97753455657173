import { GoogleCalIcon } from "assets/images";
import { Button } from "components";
import ChatNotifier from "components/ChatNotifier";
import React, { useState } from "react";

function GCalendar() {
  const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  const SCOPE = process.env.REACT_APP_GOOGLE_SCOPE;
  const [isShow, setIsShow] = useState(false);

  const handleOpenModal = () => {
    setIsShow(true);
  };

  const handleCloseModal = () => {
    setIsShow(false);
  };

  const handleAuthorize = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&access_type=offline&prompt=consent`;
    window.location.href = authUrl;
  };

  return (
    <div
      className={`h-[236px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <GoogleCalIcon className="w-10 h-10" />
          <p className="">Google Calendar</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="font-bold text-[#0D121D]">
            You can connect to CRM account to use google calendar actions in the
            flow.
          </p>

          <div className="w-[400px]">
            <Button onClick={handleAuthorize}>Connect CRM Account</Button>
          </div>
        </div>
      </div>
      <div className="w-[200px]">
        <Button onClick={handleOpenModal}>Open Modal</Button>
      </div>
      <ChatNotifier isOpen={isShow} onClose={handleCloseModal} />
    </div>
  );
}

export default GCalendar;
