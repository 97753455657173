import React, { useContext, useEffect, useState, useCallback } from "react";
import { useAuth } from "hooks/useAuth";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import { useDidList } from "hooks";
import {
  BlackAdd,
  BlackHold,
  BlackKeypad,
  BlackMute,
  BlackRecord,
  BlackTransfer,
  CutCall,
  NoteIcon,
  WhiteHold,
  WhiteMute,
  WhiteRecord,
} from "assets/images";
import { toast } from "react-toastify";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";

const ConnectedScreen = ({
  activeCallData = {},
  setAction,
  setIsDispositionOpen,
}) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { user } = useAuth();
  const { mutate } = useDidList();
  const { _number = "", _status = "", callID = "" } = activeCallData;
  const { _terminate, _muteCall, _toggleHold, _uaSessions, _sendDtmf } = socket;

  const isUARecordingEnabled =
    !!_uaSessions?.[callID]?._request?.headers?.["X-Record"];

  const [isRecording, setIsRecording] = useState(isUARecordingEnabled);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    fetchDidDetails();
  }, [_uaSessions]);

  const fetchDidDetails = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.GET_DID_DETAILS.URL}`,
        {
          phone_number: user?.caller_id,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      if (
        _uaSessions?.[callID]?._direction === "outgoing" &&
        response.data?.data?.incoming_call?.auto_record_outgoing_calls
      ) {
        setIsRecording(true);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  }, [user, _uaSessions, callID]);

  const callRecording = (uuid) => {
    if (_number?.length <= 4) return;
    if (isRecording) {
      _sendDtmf(uuid, `*3`);
      setIsRecording(false);
    } else {
      _sendDtmf(uuid, `*2`);
      setIsRecording(true);
    }
  };

  return (
    <div className="flex flex-col h-full xxl:gap-8 xl:gap-4 lg:gap-8 md:gap-10 px-4 xxl:mt-8 xl:mt-3 lg:mt-8 md:mt-8 ">
      <div className="grid grid-cols-4 gap-6">
        {/* <div className="flex items-center justify-between xxl:px-5 xl:px-3 lg:px-5 md:px-8"> */}
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px] "
          onClick={() => _muteCall(callID)}
        >
          {_status === "mute" ? (
            <WhiteMute
              height={64}
              className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer"
            />
          ) : (
            <BlackMute className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          )}

          <div className="text-sm text-white">
            {_status === "mute" ? "Unmute" : "Mute"}
          </div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => setAction("transfer")}
        >
          <BlackTransfer className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          <div className="text-sm text-white">Transfer</div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => setAction("dial")}
        >
          <BlackKeypad className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          <div className="text-sm text-white">Keypad</div>
        </div>
        {/* </div> */}
        {/* <div className="flex items-center justify-between xxl:px-5 xl:px-3 lg:px-5 md:px-8"> */}
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => _toggleHold(callID)}
        >
          {_status === "hold" ? (
            <WhiteHold className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          ) : (
            <BlackHold className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          )}
          <div className="text-sm text-white">
            {_status === "hold" ? "Unhold" : "Hold"}
          </div>
        </div>
        <div
          className="flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
          onClick={() => setAction("add")}
        >
          <BlackAdd className="xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 cursor-pointer" />
          <div className="text-sm text-white">Add Call</div>
        </div>
        <div
          className={`flex items-center flex-col gap-2 justify-center xxl:w-auto xl:w-auto lg:w-auto md:w-[55px] ${
            user.role_id === 3 ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => {
            if (user.role_id !== 3) {
              const callRecordingFeatureEnabled =
                user?.get_company_details?.features.find(
                  (f) => f.key === "call_recording"
                )?.value;

              if (!callRecordingFeatureEnabled) {
                toast.error(
                  "Call Recording is not enabled for your Account Plan. Please upgrade to the Business plan"
                );
                return;
              }

              callRecording(callID);
            }
          }}
        >
          {_number?.length <= 4 ? (
            <BlackRecord
              className={`xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 ${
                user.role_id === 3 ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            />
          ) : isRecording ? (
            <WhiteRecord
              className={`xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 ${
                user.role_id === 3 ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            />
          ) : (
            <BlackRecord
              className={`xxl:w-auto xl:w-14 lg:w-[52px] md:w-12 ${
                user.role_id === 3 ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            />
          )}
          <div className="text-sm text-white">Record</div>
        </div>

        <div
          onClick={() => setIsDispositionOpen(true)}
          className="flex items-center flex-col gap-2 cursor-pointer justify-center xxl:w-auto xl:w-auto lg:w-auto  md:w-[55px]"
        >
          <NoteIcon />
          <div className="text-sm text-white">Disposition</div>
        </div>
      </div>
      <div
        className="flex items-center flex-col gap-2 justify-center "
        onClick={() => {
          _terminate(callID);
        }}
      >
        <CutCall className="xxl:w-auto xl:w-16 lg:w-16 md:w-14 cursor-pointer" />
      </div>
    </div>
  );
};

export default ConnectedScreen;
