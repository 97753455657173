// import React, { useState } from "react";
// import { ADMIN, MEMBER, OWNER } from "shared/resources";
// import Store from "./Store";
// import Installed from "./Installed";
// import Purchased from "./Purchased";
import ComingSoon from "components/ComingSoon";

// const TemplateStore = () => {
//   const [tab, setTab] = useState("store");
//   const SettingsArray = [
//     {
//       id: 1,
//       label: "Template Store",
//       value: "store",
//       access: [ADMIN, OWNER, MEMBER],
//     },
//     {
//       id: 2,
//       label: "Installed Templates",
//       value: "installed",
//       access: [ADMIN, OWNER],
//     },
//     {
//       id: 3,
//       label: "Purchased Templates",
//       value: "purchased",
//       access: [ADMIN, OWNER],
//     },
//   ];
//   return (
//     <div className="w-full ">
//       <div className=" w-full">
//         <div className="flex items-center w-full gap-[34px] pt-6 ">
//           {SettingsArray.map((setting) => {
//             return (
//               <div
//                 key={setting?.id}
//                 onClick={() => setTab(setting?.value)}
//                 className="flex justify-center items-center group relative"
//               >
//                 <div
//                   className={`${
//                     tab === setting?.value
//                       ? "text-grey-900 font-semibold"
//                       : "text-grey-600"
//                   } text-base  cursor-pointer mb-2`}
//                 >
//                   {setting?.label}
//                 </div>
//                 {tab === setting?.value && (
//                   <div className="group-hover:visible w-6 h-0.5 bg-grey-900  absolute top-[31px] visible rounded-full"></div>
//                 )}
//               </div>
//             );
//           })}
//         </div>

//         {tab === "store" ? (
//           <Store />
//         ) : tab === "installed" ? (
//           <Installed />
//         ) : (
//           <Purchased />
//         )}
//       </div>
//     </div>
//   );
// };
const TemplateStore = () => {
  return <ComingSoon />;
};
export default TemplateStore;
