// import React, { useEffect, useState } from "react";
// import { WebPagesIcon } from "assets/images";
// import { Button, Input } from "components";
// import {
//   Book,
//   BookOpen,
//   Captions,
//   Check,
//   ChevronDown,
//   ChevronRight,
//   Copy,
//   Download,
//   Edit,
//   Plus,
//   PlusIcon,
//   RefreshCcw,
//   Trash2,
//   Upload,
//   X,
// } from "lucide-react";
// import { formatFileSize, formatType } from "shared/resources";
import ComingSoon from "components/ComingSoon";

// function KnowledgeBased() {
//   const [showModal, setShowModal] = useState("");
//   const [showDropDown, setShowDropDown] = useState(false);
//   const [addTextModal, setAddTextModal] = useState(false);
//   const [addWebModal, setAddWebModal] = useState(false);
//   const [knowledgeBases, setKnowledgeBases] = useState([]);
//   const [currentKB, setCurrentKB] = useState(null);
//   const [kbName, setKbName] = useState("");
//   const [fileName, setFileName] = useState("");
//   const [textContent, setTextContent] = useState("");
//   const [uploads, setUploads] = useState([]);
//   const [webUrl, setWebUrl] = useState("");
//   const [siteMapModal, setSiteMapModal] = useState(false);
//   const [autoSyncUrls, setAutoSyncUrls] = useState(false);
//   const [showSite, setShowSite] = useState(false);
//   const [selectedItems, setSelectedItems] = useState({
//     all: false,
//     root: false,
//     subItem: false,
//   });

//   const hasUrlUploads = uploads.some((item) => item.type === "url");
//   const hasUrlUploadsInKb = currentKB?.items?.some(
//     (item) => item.type === "url"
//   );
//   const toggleShowSite = () => {
//     setShowSite(!showSite);
//   };
//   const addKnowledgeBase = (name, items) => {
//     const newKB = { name, id: Date.now(), items };
//     setKnowledgeBases((prevBases) => {
//       const updatedBases = [...prevBases, newKB];
//       localStorage.setItem("knowledgeBases", JSON.stringify(updatedBases));
//       return updatedBases;
//     });
//     setCurrentKB(newKB);
//     localStorage.setItem("currentKB", JSON.stringify(newKB));
//     setShowModal("");
//     setKbName("");
//     setUploads([]);
//   };

//   useEffect(() => {
//     const savedKnowledgeBases = localStorage.getItem("knowledgeBases");
//     const savedCurrentKB = localStorage.getItem("currentKB");

//     if (savedKnowledgeBases) {
//       setKnowledgeBases(JSON.parse(savedKnowledgeBases));
//     }
//     if (savedCurrentKB) {
//       setCurrentKB(JSON.parse(savedCurrentKB));
//     }
//   }, []);

//   const handleSaveKB = () => {
//     if (kbName) {
//       addKnowledgeBase(kbName, uploads);
//       setKbName("");
//       setUploads([]);
//     }
//   };

//   const handleSelectAll = () => {
//     const newValue = !selectedItems.all;
//     setSelectedItems({
//       all: newValue,
//       root: newValue,
//       subItem: newValue,
//     });
//   };

//   const handleSelectRoot = () => {
//     const newValue = !selectedItems.root;
//     setSelectedItems((prev) => ({
//       ...prev,
//       root: newValue,
//       subItem: newValue,
//       all: newValue && prev.all,
//     }));
//   };

//   const handleSelectSubItem = () => {
//     const newValue = !selectedItems.subItem;
//     setSelectedItems((prev) => ({
//       ...prev,
//       subItem: newValue,
//       all: newValue && prev.root,
//       root: prev.root,
//     }));
//   };

//   const getSelectedCount = () => {
//     let count = 0;
//     if (selectedItems.root) count++;
//     if (selectedItems.subItem) count++;
//     return count;
//   };

//   const handleFileUpload = (e) => {
//     const files = Array.from(e.target.files);
//     console.log("files:", files);
//     const newUploads = files.map((file) => ({
//       name: file.name,
//       type: file.type,
//       size: file.size,
//       file,
//     }));
//     setUploads((prevUploads) => [...newUploads, ...prevUploads]);
//   };

//   const handleSaveText = () => {
//     if (fileName && textContent) {
//       setUploads((prevUploads) => [
//         { name: fileName, content: textContent, type: "text" },
//         ...prevUploads,
//       ]);
//       setFileName("");
//       setTextContent("");
//       setAddTextModal(false);
//     }
//   };
//   const handleSaveUrl = () => {
//     if (webUrl) {
//       setUploads((prevUploads) => [
//         { name: webUrl, type: "url" },
//         ...prevUploads,
//       ]);
//       setWebUrl("");
//       setAddWebModal(false);
//       setSiteMapModal(true);
//     }
//   };

//   const removeUploads = (index) => {
//     setUploads(uploads.filter((_, i) => i !== index));
//   };

//   useEffect(() => {
//     localStorage.setItem("uploads", JSON.stringify(uploads));
//   }, [uploads]);

//   useEffect(() => {
//     const savedUploads = localStorage.getItem("uploads");
//     if (savedUploads) {
//       setUploads(JSON.parse(savedUploads));
//     }
//   }, []);

//   return (
//     <div className="flex relative">
//       {/* Sidebar */}
//       <div className="border-r-4 h-screen overflow-scroll p-4 border-[#D2D5DA] w-72 pt-6">
//         <div className="flex gap-2 items-center justify-between pb-4 border-b border-[#D2D5DA]">
//           <div className="flex gap-3 items-center">
//             <Book size={16} />
//             <p>Knowledge Base</p>
//           </div>
//           <button
//             type="button"
//             onClick={() => setShowModal("addKnowledge")}
//             className="w-[23px] h-[23px] font-bold rounded items-center flex justify-center bg-green"
//           >
//             <Plus color="white" />
//           </button>
//         </div>
//         <ul className="flex gap-2 flex-col mt-5">
//           {knowledgeBases.map((kb) => (
//             <li
//               key={kb.id}
//               className={`p-2 hover:bg-grey-200 cursor-pointer flex items-center gap-4 rounded-md ${
//                 currentKB?.id === kb.id ? "bg-grey-200" : ""
//               }`}
//               onClick={() => setCurrentKB(kb)}
//             >
//               <BookOpen size={16} />
//               <span>{kb.name}</span>
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Body/Main Content */}
//       <div className="h-screen overflow-y-scroll p-6 w-full">
//         {currentKB ? (
//           <div className="w-full">
//             <div className="w-full justify-between flex items-center mb-6">
//               <div className="flex flex-col gap-1">
//                 <p className="text-xl font-extrabold">{currentKB.name}</p>
//                 <div className="flex items-center gap-2">
//                   <p className="text-[11px] text-grey-700 flex gap-1 items-center">
//                     <span>ID; know... d1c</span>
//                     <Copy size={10} />
//                   </p>
//                   <Check color="green" size={10} className="font-bold" />
//                   <p className="text-[11px] text-grey-700">
//                     Uploaded By: 01/08/2025 14:20
//                   </p>
//                 </div>
//               </div>
//               <div className="flex gap-3 items-center">
//                 {hasUrlUploadsInKb && (
//                   <div className="w-[150px]">
//                     <Button extraClasses="flex items-center gap-3">
//                       <RefreshCcw />
//                       <span>Re-sync</span>
//                     </Button>
//                   </div>
//                 )}
//                 <div className="w-[150px]">
//                   <Button extraClasses="flex items-center gap-3">
//                     <Edit />
//                     <span>Edit</span>
//                   </Button>
//                 </div>
//                 <div
//                   onClick={() => setShowModal("deleteKnowledge")}
//                   className="bg-danger w-[50px] h-[50px] rounded-md items-center justify-center flex cursor-pointer"
//                 >
//                   <Trash2 color="white" />
//                 </div>
//               </div>
//             </div>
//             <ul className="mt-4 w-full flex-col gap-4 flex">
//               {currentKB.items.map((item, index) => (
//                 <li
//                   key={index}
//                   className=" w-full flex justify-between items-center h-[55px] rounded-md px-4 border"
//                 >
//                   <div className="flex gap-4 items-center">
//                     <span>
//                       {item.type === "text" ? (
//                         <Captions size={40} />
//                       ) : (
//                         formatType(item.type)
//                       )}
//                     </span>
//                     <div className="flex flex-col gap-[2px]">
//                       <p>
//                         {item?.name.length > 100
//                           ? `${item.name.substring(0, 100)}...`
//                           : item.name}
//                       </p>
//                       {!item.type.includes("text") &&
//                         !item.type.includes("url") && (
//                           <p className="text-[12.5px] text-grey-700">
//                             {formatFileSize(item.size)}
//                           </p>
//                         )}
//                     </div>
//                   </div>
//                   <div className="cursor-pointer">
//                     <Download />
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         ) : (
//           <div className="h-screen m-auto flex items-center justify-center flex-col gap-4 text-center">
//             <div className="flex items-center justify-center rounded-lg border border-black h-[60px] w-[40px]">
//               <Book />
//             </div>
//             <p>You don&apos;t have any knowledge base.</p>
//           </div>
//         )}
//       </div>

//       {showModal === "addKnowledge" && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
//           onClick={() => setShowModal(null)}
//         >
//           <div
//             className={`bg-white p-4 pb-2 rounded-lg w-full sm:w-[600px] ${
//               uploads.length > 1 && "h-[470px]"
//             } relative`}
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div
//               onClick={() => setShowModal(null)}
//               className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
//             >
//               <X className="w-4 h-4" />
//             </div>
//             <p className="text-lg">Add a Knowledge Base</p>
//             <div className="mt-5 mb-4 flex flex-col gap-4 relativ">
//               <div
//                 className={` flex flex-col gap-2 ${
//                   uploads.length > 1 && "h-[217px] overflow-y-scroll"
//                 } reltive`}
//               >
//                 <label>Knowledge base name</label>
//                 <Input
//                   placeholder="Enter"
//                   value={kbName}
//                   onChange={(e) => setKbName(e.target.value)}
//                 />
//                 <p>Documents</p>
//                 <ul className="mb-2 flex flex-col gap-3">
//                   {uploads.map((item, index) => (
//                     <li
//                       key={index}
//                       className="flex items-center justify-between w-full h-[55px] rounded-md px-4 border"
//                     >
//                       <div className="flex gap-4 items-center">
//                         <span>
//                           {item.type === "text" ? (
//                             <Captions size={40} />
//                           ) : (
//                             formatType(item.type)
//                           )}
//                         </span>
//                         <div className="flex flex-col gap-[2px]">
//                           <p>
//                             {item?.name?.length > 40
//                               ? `${item?.name?.substring(0, 40)}...`
//                               : item.name}
//                           </p>
//                           {!item.type.includes("text") &&
//                             !item.type.includes("url") && (
//                               <p className="text-[12.5px] text-grey-700">
//                                 {formatFileSize(item.size)}
//                               </p>
//                             )}
//                         </div>
//                       </div>
//                       <div
//                         className="cursor-pointer"
//                         onClick={() => removeUploads(index)}
//                       >
//                         <Trash2 />
//                       </div>
//                     </li>
//                   ))}
//                 </ul>
//               </div>
//               <div className="relative">
//                 <button
//                   type="button"
//                   onClick={() => setShowDropDown(!showDropDown)}
//                   className="border border-black w-[90px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-green hover:text-white duration-300"
//                 >
//                   <PlusIcon size={18} />
//                   <span>Add</span>
//                 </button>
//                 {showDropDown && (
//                   <div className="absolute bg-white z-50 top-10 rounded-md shadow-lg py-4 flex flex-col gap-3">
//                     <div
//                       onClick={() => {
//                         setShowDropDown(false);
//                         setAddWebModal(true);
//                       }}
//                       className="flex gap-3 items-center cursor-pointer hover:bg-grey-400 px-4 py-1"
//                     >
//                       <div className="border border-[#D2D5DA66] bg-[#F5F5F5] rounded-full w-[35px] h-[35px] flex items-center justify-center">
//                         <WebPagesIcon />
//                       </div>
//                       <div className="flex flex-col gap-1">
//                         <p className="text-[14.5px]">Add web pages</p>
//                         <p className="text-[12px] text-grey-600">
//                           Scan and synchronize your website
//                         </p>
//                       </div>
//                     </div>
//                     <div className="flex gap-3 items-center cursor-pointer hover:bg-grey-400 px-4 py-1 relative">
//                       <div className="border border-[#D2D5DA66] bg-[#F5F5F5] rounded-full w-[35px] h-[35px] flex items-center justify-center">
//                         <Upload size={15} />
//                       </div>
//                       <div className="flex flex-col gap-1">
//                         <p className="text-[14.5px]">Upload Files</p>
//                         <p className="text-[12px] text-grey-600">
//                           File size should be less than 100MB
//                         </p>
//                       </div>
//                       <input
//                         type="file"
//                         accept="image/bmp,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,message/rfc822,application/epub+zip,image/heic,text/html,image/jpeg,image/png,text/markdown,application/vnd.ms-outlook,application/vnd.oasis.opendocument.text,text/x-org,application/pkcs7-signature,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/x-rst,application/rtf,image/tiff,text/plain,text/tab-separated-values,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/xml"
//                         multiple
//                         className="opacity-0 cursor-pointer inset-0 absolute"
//                         onChange={handleFileUpload}
//                       />
//                     </div>
//                     <div
//                       onClick={() => {
//                         setShowDropDown(false);
//                         setAddTextModal(true);
//                       }}
//                       className="flex gap-3 items-center cursor-pointer hover:bg-grey-400 px-4 py-1"
//                     >
//                       <div className="border border-[#D2D5DA66] bg-[#F5F5F5] rounded-full w-[35px] h-[35px] flex items-center justify-center">
//                         <Captions size={15} />
//                       </div>
//                       <div className="flex flex-col gap-1">
//                         <p className="text-[14.5px]">Add Text</p>
//                         <p className="text-[12px] text-grey-600">
//                           Add articles manually
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>
//             </div>
//             <div className="flex gap-3 items-center justify-end ml-auto">
//               {hasUrlUploads && (
//                 <div className="flex items-center gap-2 mr-auto">
//                   <input
//                     type="checkbox"
//                     id="autoSync"
//                     checked={autoSyncUrls}
//                     onChange={(e) => setAutoSyncUrls(e.target.checked)}
//                     className="rounded"
//                   />
//                   <label htmlFor="autoSync" className="text-sm text-grey-700">
//                     Auto sync all URLs
//                   </label>
//                 </div>
//               )}
//               <button
//                 onClick={() => setShowModal(null)}
//                 className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
//               >
//                 Cancel
//               </button>
//               <div className="w-[150px]">
//                 <Button onClick={handleSaveKB} disabled={!kbName}>
//                   Save
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {addTextModal && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
//           onClick={() => setAddTextModal(false)}
//         >
//           <div
//             className="bg-white p-4 rounded-lg w-full sm:w-[600px] h-[400px] relative"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div
//               onClick={() => setAddTextModal(false)}
//               className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
//             >
//               <X className="w-4 h-4" />
//             </div>
//             <p className="text-lg">Add Text Content</p>
//             <div className="mt-5 mb-4 flex flex-col gap-4">
//               <label>File Name</label>
//               <Input
//                 placeholder="Enter file name"
//                 value={fileName}
//                 onChange={(e) => setFileName(e.target.value)}
//               />
//               <label>Text Content</label>
//               <textarea
//                 placeholder="Enter content"
//                 value={textContent}
//                 onChange={(e) => setTextContent(e.target.value)}
//                 className="border border-gray-300 rounded-lg p-2"
//               />
//             </div>
//             <div className="flex gap-3 items-center justify-end ml-auto">
//               <button
//                 onClick={() => setAddTextModal(false)}
//                 className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
//               >
//                 Cancel
//               </button>
//               <div className="w-[150px]">
//                 <Button
//                   onClick={handleSaveText}
//                   disabled={!fileName || !textContent}
//                 >
//                   Save
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {addWebModal && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
//           onClick={() => setAddWebModal(false)}
//         >
//           <div
//             className="bg-white p-4 rounded-lg w-full sm:w-[600px] relative"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div
//               onClick={() => setAddWebModal(false)}
//               className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
//             >
//               <X className="w-4 h-4" />
//             </div>
//             <p className="text-lg">Add Web Pages</p>
//             {/* Add your web pages form/content here */}
//             <div className="mt-5 flex flex-col gap-4">
//               <Input
//                 placeholder="Enter URL"
//                 value={webUrl}
//                 onChange={(e) => setWebUrl(e.target.value)}
//               />
//             </div>
//             <div className="flex gap-3 items-center justify-end ml-auto mt-4">
//               <button
//                 onClick={() => setAddWebModal(false)}
//                 className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
//               >
//                 Cancel
//               </button>
//               <div className="w-[150px]">
//                 <Button onClick={handleSaveUrl} disabled={!webUrl}>
//                   Save
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {siteMapModal && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
//           onClick={() => setSiteMapModal(false)}
//         >
//           <div
//             className="bg-white p-4 rounded-lg w-full sm:w-[600px] h-[600px] overflow-y-scroll relative"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <div
//               onClick={() => setSiteMapModal(false)}
//               className="w-[20px] h-[20px] cursor-pointer flex items-center justify-center absolute right-4 top-4 rounded-full border border-black"
//             >
//               <X className="w-4 h-4" />
//             </div>
//             <p className="text-lg">Select Site Maps</p>
//             <div className="flex w-full justify-between items-center mt-4">
//               <div className="flex items-center gap-2">
//                 <input
//                   type="checkbox"
//                   checked={selectedItems.all}
//                   onChange={handleSelectAll}
//                   className="rounded"
//                 />
//                 <p>Select All</p>
//               </div>
//               <p className="text-blue-light">Selected ({getSelectedCount()})</p>
//             </div>
//             <div className="flex items-center gap-3 mt-2">
//               <div onClick={toggleShowSite}>
//                 {showSite ? <ChevronDown /> : <ChevronRight />}
//               </div>
//               <div className="flex items-center gap-2">
//                 <input
//                   type="checkbox"
//                   checked={selectedItems.root}
//                   onChange={handleSelectRoot}
//                   className="rounded"
//                 />
//                 <p>Select crunchy.com</p>
//               </div>
//             </div>
//             {showSite && (
//               <div className="flex items-center gap-2 pl-10 mt-2">
//                 <input
//                   type="checkbox"
//                   checked={selectedItems.subItem}
//                   onChange={handleSelectSubItem}
//                   className="rounded"
//                 />
//                 <p>https://crunchy.com</p>
//               </div>
//             )}
//             <div className="absolute bottom-2 right-3 flex gap-3 items-center justify-end ml-auto mt-4">
//               <button
//                 onClick={() => setSiteMapModal(false)}
//                 className="border border-black w-[150px] rounded-md h-[40px] flex gap-2 items-center justify-center hover:bg-danger hover:text-white duration-300"
//               >
//                 Cancel
//               </button>
//               <div className="w-[150px]">
//                 <Button
//                   onClick={() => setSiteMapModal(false)}
//                   disabled={!selectedItems.root}
//                 >
//                   Save
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {showModal === "deleteKnowledge" && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
//           onClick={() => setShowModal(null)}
//         >
//           <div
//             className={`bg-white p-4 pb-2 rounded-lg relative flex flex-col gap-4`}
//             onClick={(e) => e.stopPropagation()}
//           >
//             <p className="font-bold text-xl">Delete Knowledge</p>
//             <p className="text-grey-700 text-[15px]">
//               Are you sure you want to delete this knowledge?
//             </p>
//             <div className="flex justify-end items-center ml-auto gap-3">
//               <button
//                 onClick={() => setShowModal(null)}
//                 className="border border-black w-[100px] rounded-md h-[40px] flex gap-2 items-center justify-center duration-300"
//               >
//                 Cancel
//               </button>

//               <button className=" w-[100px] rounded-md h-[40px] flex gap-2 items-center justify-center bg-danger text-white duration-300">
//                 Confirm
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }
function KnowledgeBased() {
  return <ComingSoon />;
}
export default KnowledgeBased;
