/* eslint-disable no-unused-vars */
import { Input } from 'components';
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import InboxListItem from '../InboxListItem';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { getToken } from 'shared/resources';
import { routes } from 'api/routes';

const InboxList = () => {
  const [search, setSearch] = useState('');
  const { user, zigiSocket } = useAuth();
  const [_, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState();

  const fetchConversations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION.URL}?companyUuid=${user?.company_uuid}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Company-UUID": user.company_uuid,
          },
        }
      );
      setConversations(response.data);
      const filteredConversation = response.data
        .map(filterAndUpdateConversation)
        .filter((conversation) => {
          // If responder, exclude Zigi Bot conversations
          if (conversation.role !== 'responder' && conversation.isZigiBot) {
            return false;
          }
          return true;
        });
      setFilteredConversations([...filteredConversation]);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  };

  const markConversationAsRead = async (conversationUuid) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.MARK_CONVERSATION_AS_READ.URL}/${conversationUuid}`,
        {
          userId: user?.uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user.company_uuid,
          },
        }
      );
    } catch (error) {
      console.error('Error marking conversation as read:', error);
    }
  };

  const markConversationAsUnread = async (conversationUuid) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.MARK_CONVERSATION_AS_UNREAD.URL}/${conversationUuid}`,
        {
          userId: user?.uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user.company_uuid,
          },
        }
      );
    } catch (error) {
      console.error('Error marking conversation as unread:', error);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (activeConversation) {
      const clonedConversations = [...filteredConversations];
      const conversationIdx = clonedConversations.findIndex(
        (c) => c.uuid === activeConversation,
      );
      clonedConversations[conversationIdx].unread = false;
      setFilteredConversations(clonedConversations);
      markConversationAsRead(activeConversation);
    }
  }, [activeConversation]);

  const filterAndUpdateConversation = (conversation) => {
    if (conversation.initiator === user.uuid) {
      // If the logged-in user is the initiator, leave as it is.
      return {
        ...conversation,
        displayName: 'Zigi Bot',
        isZigiBot: true,
        role: 'initiator',
        unread:
          conversation.read_by === null ||
          !conversation.read_by.includes(user?.uuid),
      };
    } else {
      // If the logged-in user is the responder, replace "Zigi Bot" with the initiator's name.
      return {
        ...conversation,
        displayName:
          conversation?.metadata?.company_name ||
          conversation?.metadata?.name ||
          'Unknown User',
        isZigiBot: true,
        role: 'responder',
        unread:
          conversation.read_by === null ||
          !conversation.read_by.includes(user?.uuid),
      };
    }
  };

  useEffect(() => {
    if (zigiSocket) {
      zigiSocket.on('conversationStarted', () => {
        fetchConversations();
      });

      zigiSocket.on('newMessage', (newMessage) => {
        setFilteredConversations((prevConversations) => {
          const conversationIndex = prevConversations.findIndex(
            (conversation) =>
              conversation.uuid === newMessage.conversation_uuid,
          );

          if (conversationIndex !== -1) {
            const updatedConversations = [...prevConversations];
            const [updatedConversation] = updatedConversations.splice(
              conversationIndex,
              1,
            );

            updatedConversation.metadata.lastMessage = newMessage.metadata.body;
            updatedConversations.unshift(updatedConversation);

            return updatedConversations;
          }

          return prevConversations;
        });
      });

      zigiSocket.on('joinedRoom', () => {
        fetchConversations();
      });

      zigiSocket.on('messageReceived', (newMessage) => {
        if (newMessage.conversation_uuid === activeConversation) {
          console.log('conversations', filteredConversations);
          const clonedConversations = [...filteredConversations];
          const conversationIdx = clonedConversations.findIndex(
            (c) => c.uuid === activeConversation,
          );
          clonedConversations[conversationIdx].unread = false;
          setFilteredConversations(clonedConversations);
          markConversationAsRead(newMessage.conversation_uuid);
        } else {
          const clonedConversations = [...filteredConversations];
          const conversationIdx = clonedConversations.findIndex(
            (c) => c.uuid === activeConversation,
          );
          clonedConversations[conversationIdx].unread = true;
          setFilteredConversations(clonedConversations);
          markConversationAsUnread(newMessage.conversation_uuid);
        }
        setFilteredConversations((prevConversations) => {
          const conversationIndex = prevConversations.findIndex(
            (conversation) =>
              conversation.uuid === newMessage.conversation_uuid,
          );

          if (conversationIndex !== -1) {
            const updatedConversations = [...prevConversations];
            const [updatedConversation] = updatedConversations.splice(
              conversationIndex,
              1,
            );

            updatedConversation.metadata.lastMessage = newMessage.metadata.body;
            updatedConversations.unshift(updatedConversation);

            return updatedConversations;
          }

          return prevConversations;
        });
      });
    }
  }, [zigiSocket]);

  if (loading) return <div>Loading conversations...</div>;

  return (
    <div className="flex">
      <div className="bg-white  border-grey-300 border-r">
        <div className="pt-6 px-4 pb-4">
          <Input
            type="text"
            name={'search'}
            value={search}
            height="h-10"
            onWhitebg={true}
            onChange={(e) => setSearch(e.target.value)}
            extraClasses="pl-10 mb-0 placeholder:text-grey-600 placeholder:font-normal placeholder:text-sm "
            placeholder={'Search conversation'}
            withSearchIcon={true}
          />
        </div>
        <span className="flex border-grey-300 border-b p-0 w-full "></span>

        <div className="w-96 flex flex-col gap-2 bg-white pt-2.5 h-[calc(100vh_-_161px)] overflow-auto">
          <span>
            {Array.from({ length: 1 }, (_, i) => i).map((val) => {
              return <InboxListItem key={val} item={val} />;
            })}
          </span>
          <div>
            {filteredConversations && filteredConversations.length ? (
              filteredConversations.map((conversation) => (
                <InboxListItem
                  key={conversation.uuid}
                  item={conversation}
                  isZigiBot={conversation.isZigiBot}
                  filteredConversations={filteredConversations}
                  setFilteredConversations={setFilteredConversations}
                  setActiveConversation={setActiveConversation}
                  deleteUUID={conversation.uuid}
                  activeConversation={activeConversation}
                  company_uuid={conversation.company_uuid}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default InboxList;
