export const dropdownActions = {
  PLAY_RECORDING: "play_recording",
  SHOW_NOTES: "show_notes",
};
export const initialModalState = {
  modalType: "",
  isShow: false,
  elementData: {},
  type: "",
};
