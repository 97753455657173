import { ChatIcon } from 'assets/images';
import axios from 'axios';
import Modal from 'components/Modal';
import { toastEmitter } from 'components/Toast';
import React, { useEffect } from 'react';
import { routes } from 'api/routes';
import { getToken } from 'shared/resources';
import { useAuth } from 'hooks/useAuth';

function ChatNotifier({ isOpen, onClose, conversation }) {
  const handleClose = () => {
    // const audio = document.querySelector('audio');
    // if (audio) {
    //   audio.pause();
    //   audio.currentTime = 0;
    // }
    onClose();
  };

  useEffect(() => {
    return () => {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, []);

  if (!isOpen) return null;

  const { user } = useAuth();

  console.log('user', user);

  return (
    <Modal
      handleClose={() => {}}
      headerComponent={null}
      footerComponent={null}
      shouldCloseOnClickOutside={false}
    >
      <div className="flex flex-col gap-4 w-[500px] h-[300px]">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="relative mb-8">
            <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center animate-bounce">
              <ChatIcon />
            </div>
            <div className="absolute -inset-2">
              <div className="w-20 h-20 rounded-full bg-green-500/30 animate-ping"></div>
            </div>
            <div className="absolute -inset-4">
              <div className="w-24 h-24 rounded-full bg-green-500/20 animate-pulse"></div>
            </div>
            {/* <audio autoPlay loop>
              <source src="/notification.mp3" type="audio/mpeg" />
            </audio> */}
          </div>

          <h2 className="text-xl font-semibold mb-6">
            Do you want to accept this incoming message?
          </h2>

          <div className="flex gap-4">
            <button
              onClick={async () => {
                await axios.post(
                  `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.ACCEPT_CONVERSATION.URL}/${conversation?.uuid}`,
                  {
                    userId: user?.uuid,
                    displayName: user?.first_name + " " + user?.last_name,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${getToken()}`,
                      "X-Company-UUID": user.company_uuid,
                    },
                  }
                );
                toastEmitter('success', 'Message accepted!');
                handleClose();
              }}
              className="px-6 py-2 bg-green text-white rounded-md hover:bg-green-600 transition-colors"
            >
              Yes
            </button>
            <button
              onClick={() => {
                toastEmitter('error', 'Message declined!');
                handleClose();
              }}
              className="px-6 py-2 bg-danger text-white rounded-md hover:bg-red-600 transition-colors"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ChatNotifier;
