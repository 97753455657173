// import { InstagramMessenger } from "assets/images";
// import { Button } from "components";
import ComingSoon from "components/ComingSoon";
import React from "react";

function Instagram() {
  return (
    // <div
    //   className={`h-[309px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    // >
    //   <div className="flex flex-col gap-7">
    //     <div className="flex items-center space-x-2">
    //       <InstagramMessenger className="w-8 h-8" />
    //       <p className="pt-1">Instagram Messenger</p>
    //     </div>
    //     <div className="flex flex-col gap-6">
    //       <p className="font-bold text-[#0D121D]">
    //         Connect your Instagram Messenger to your Fonu account{" "}
    //       </p>
    //       <p className="text-[#474A4E] text-[14px]">
    //         You can create Instagram bot on connected Instagram accounts.
    //         <br /> To connect Instagram account, you need to connect from the
    //         Facebook page which linked to the Instagram account
    //       </p>
    //       <div className="w-[400px]">
    //         <Button>Connect to Instagram Messenger</Button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <ComingSoon />
  );
}

export default Instagram;
