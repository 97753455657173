/* eslint-disable no-unused-vars */
import { X } from "assets/images";
import { Button, Input, Select } from "components";
import SwitchInput from "components/SwitchInput";
import { toastEmitter } from "components/Toast";
import { CircleAlert, TriangleAlert } from "lucide-react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "shared/resources";
import { useAuth } from "hooks/useAuth";
import { Loader2 } from "lucide-react";

function SDK() {
  const { user } = useAuth();
  const sdkSetting = user?.get_company_details.features.find(
    (f) => f.key === "sdk_enabled"
  );
  const [showModal, setShowModal] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [isDomainLocked, setIsDomainLocked] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(
    sdkSetting ? sdkSetting?.value : false
  );
  const [saveMessage, setSaveMessage] = useState("");
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [originator, setOriginator] = useState([]);
  const [destination, setDestination] = useState([]);
  const [allDids, setAllDids] = useState([]);
  const [selectedOriginator, setSelectedOriginator] = useState("");
  const [selectedDestination, setSelectedDestination] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllDids();
    fetchAllMembers();
    fetchConfiguration();
  }, []);

  const excludeDid = async (did, stateSetter) => {
    const clonedDids = [...allDids];
    const didIdx = clonedDids.findIndex((d) => d === did);
    clonedDids.splice(didIdx, 1);
    stateSetter(clonedDids);
  };

  useEffect(() => {
    if (selectedOriginator) excludeDid(selectedOriginator, setDestination);
    if (selectedDestination) excludeDid(selectedDestination, setOriginator);
  }, [selectedOriginator, selectedDestination]);

  const fetchConfiguration = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/get-sdk-configuration`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setSelectedOriginator(response.data.data.originator);
      setSelectedDestination(response.data.data.destination);
      setDomainName(response.data.data.origin || "");
      if (response.data.data.origin) {
        setIsDomainLocked(true);
        setSaveMessage(
          "This domain is locked and cannot be edited. Kindly reach out to support at: hello@fonu.com if you need to change it"
        );
      }
      setSelectedAgents(response.data.data.agents || []);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  useEffect(() => {
    const clonedAgentsList = [...agentsList];
    setAgentsList(
      clonedAgentsList.filter(
        (a) => !selectedAgents.some((ag) => ag.uuid === a.uuid)
      )
    );
  }, [selectedAgents]);

  const fetchAllMembers = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}member/list-all`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      setAgentsList(
        response.data.data.map((u) => {
          return {
            uuid: u.uuid,
            name: `${u.first_name} ${u.last_name}`,
          };
        })
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const fetchAllDids = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}did/list-all`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      const allDids = response.data.data.map((d) => d.did_number);
      setOriginator(allDids);
      setDestination(allDids);
      setAllDids(allDids);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const toggleSdk = async (sdkEnabled) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/toggle-sdk`,
        {
          sdkEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const handleAgentSelect = (agentUuid) => {
    const agent = agentsList.find((item) => item.uuid === agentUuid);
    if (agent) {
      setSelectedAgents([...selectedAgents, agent]);
      setAgentsList(agentsList.filter((item) => item.uuid !== agentUuid));
    }
  };

  const handleAgentRemove = (agentUuid) => {
    const agent = selectedAgents.find((item) => item.uuid === agentUuid);
    if (agent) {
      setSelectedAgents(
        selectedAgents.filter((item) => item.uuid !== agentUuid)
      );
      setAgentsList([...agentsList, agent]);
    }
  };
  const handleSwitchChange = () => {
    setIsSwitchOn(!isSwitchOn);
    toggleSdk(!isSwitchOn);
  };

  const handleContinue = () => {
    if (domainName.trim() && !isDomainLocked) {
      setShowModal(true);
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {
    setShowModal(false);
    if (!selectedOriginator || !selectedDestination) {
      toastEmitter("error", "Originator and Destination is required");
      return;
    }
    if (!domainName.includes("http") || !domainName.includes("https")) {
      toastEmitter("error", "Origin provided is not valid");
      return;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}webhook/save-sdk-configuration`,
        {
          originator: selectedOriginator,
          destination: selectedDestination,
          origin: domainName.trim(),
          members: selectedAgents.reduce((prev, cur) => {
            prev.push(cur.uuid);
            return prev;
          }, []),
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      toastEmitter("success", "Your SDK has been configured successfully");
      if (domainName.trim()) {
        setIsDomainLocked(true);
      }
      setSaveMessage(
        "This domain is locked and cannot be edited. Kindly reach out to support at: hello@fonu.com if you need to change it"
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  return (
    <div className="flex flex-col gap-2 justify-center mx-auto items-center">
      <p className="text-center">SDK Integration Configuration</p>
      <p className="text-center">
        Provide the originator details and target domain information to complete
        the SDK setup.
      </p>
      {loading ? (
        <div className="h-screen flex justify-center items-center">
          <Loader2 className="animate-spin" />
        </div>
      ) : (
        <div className="overflow-y-auto w-full md:w-[900px] py-6 border border-[#9B9DA4] rounded-[12px] mt-4 flex items-center justify-center">
          <form className="flex flex-col space-y-6 w-full md:w-[750px]">
            <div className="flex gap-2 m-auto items-center'">
              <SwitchInput
                name="sdk-integration"
                value={isSwitchOn}
                onChange={handleSwitchChange}
              />
              <p>{isSwitchOn ? "Turn Off" : "Turn On"}</p>
            </div>
            <div className="flex items-center gap-4">
              <p className="w-[200px] font-semibold">
                Originator <span className="text-danger">*</span>
              </p>
              <div className="w-full">
                <Select
                  name="originator"
                  disabled={!isSwitchOn}
                  options={originator}
                  placeholder={"Select a Fonu number"}
                  label="Choose originator"
                  renderOption={({ item }) => (
                    <option value={item}>{item}</option>
                  )}
                  value={selectedOriginator}
                  onChange={(event) => {
                    setSelectedOriginator(event.target.value);
                    if (!event.target.value) {
                      setDestination(allDids);
                      return;
                    }
                    const clonedDids = [...allDids];
                    const didIdx = clonedDids.findIndex(
                      (d) => d === event.target.value
                    );
                    clonedDids.splice(didIdx, 1);
                    setDestination(clonedDids);
                  }}
                />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <p className="w-[200px] font-semibold">
                Destination <span className="text-danger">*</span>
              </p>
              <div className="w-full">
                <Select
                  name="destination"
                  options={destination}
                  disabled={!isSwitchOn}
                  placeholder={"Select a Fonu number"}
                  label="Choose destination"
                  renderOption={({ item }) => (
                    <option value={item}>{item}</option>
                  )}
                  value={selectedDestination}
                  onChange={(event) => {
                    setSelectedDestination(event.target.value);
                    if (!event.target.value) {
                      setOriginator(allDids);
                      return;
                    }
                    const clonedDids = [...allDids];
                    const didIdx = clonedDids.findIndex(
                      (d) => d === event.target.value
                    );
                    clonedDids.splice(didIdx, 1);
                    setOriginator(clonedDids);
                  }}
                />
              </div>
            </div>
            <div className="flex items-center gap-4 w-full">
              <p className="w-[200px] font-semibold">Domain name</p>
              <div className="w-full">
                <Input
                  name="domain"
                  placeholder="api.fonu.com"
                  label="Provide your domain name (optional)"
                  value={domainName}
                  onChange={(e) => setDomainName(e.target.value)}
                  disabled={isDomainLocked || !isSwitchOn}
                />
                {isDomainLocked && (
                  <p className="text-[#FF0000] text-[12px] mt-1 flex items-center gap-2">
                    <CircleAlert width={15} height={15} />
                    <span>{saveMessage}</span>
                  </p>
                )}
                <p className="text-[#024DE3] text-[12px] mt-2">
                  http://localhost:3000 is whitelisted by default
                </p>
              </div>
            </div>
            <div className="flex items-center w-full gap-4">
              <p className="w-[200px] font-semibold">Agents</p>
              <div className="flex w-full flex-col">
                <div className="flex flex-wrap gap-2">
                  {selectedAgents.map((agent) => (
                    <div
                      key={agent.uuid}
                      className=" border-[#D9D9D9] text-[13.5px] px-4 p-2 border mb-2 rounded-full flex items-center gap-2"
                    >
                      <p>{agent.name}</p>
                      <button
                        type="button"
                        onClick={() => handleAgentRemove(agent.uuid)}
                        className="text-red-500"
                      >
                        <X width={16} height={16} />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="w-full">
                  <Select
                    name="agents"
                    options={agentsList}
                    disabled={!isSwitchOn || agentsList.length === 0}
                    placeholder="Select Agents"
                    // label="Choose agents"
                    renderOption={({ item }) => (
                      <option value={item.uuid}>{item.name}</option>
                    )}
                    onChange={(e) => handleAgentSelect(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Button
              onClick={handleContinue}
              disabled={isDomainLocked || !isSwitchOn}
              extraClasses="ml-auto w-[250px]"
            >
              Save
            </Button>
            {showModal && (
              <div className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50">
                <div
                  className="bg-white flex flex-col relative gap-8 text-center justify-center p-4 rounded-lg w-full sm:w-[450px] h-[300px] overflow-scroll"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    onClick={() => setShowModal(false)}
                    className="absolute top-5 right-4"
                  >
                    <X />
                  </div>
                  <p className="flex items-center justify-center gap-3">
                    Note <TriangleAlert color="red" size={20} />
                  </p>
                  <p>Domain Name will be locked and can&apos;t be changed</p>
                  <Button onClick={handleSave}>Save</Button>
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
}

export default SDK;
