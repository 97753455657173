import { useContext, createContext, useMemo, useState, useEffect } from 'react';
import { dummyChatData } from './config';
import axios from 'axios';
import { routes } from 'api/routes';
import { useAuth } from 'hooks/useAuth';
import { getToken } from 'shared/resources';

const ChatContext = createContext({
  getAllChats: () => {
    return [];
  },
});

export const Chat = ({ children }) => {
  const [allChats, setAllChats] = useState([]);
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [conversationsLoading, setConversationsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setAllChats(dummyChatData);
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION.URL}?companyUuid=${user?.company_uuid}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user.company_uuid,
          },
        }
      );
      const filteredConversation = response.data
        .map(filterAndUpdateConversation)
        .filter((conversation) => {
          // If responder, exclude Zigi Bot conversations
          if (conversation.role !== 'responder' && conversation.isZigiBot) {
            return false;
          }
          return true;
        });
      setConversations([...filteredConversation]);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setConversationsLoading(false);
    }
  };

  const filterAndUpdateConversation = (conversation) => {
    if (conversation.initiator === user.uuid) {
      // If the logged-in user is the initiator, leave as it is.
      return {
        ...conversation,
        displayName: 'Zigi Bot',
        isZigiBot: true,
        role: 'initiator',
      };
    } else {
      // If the logged-in user is the responder, replace "Zigi Bot" with the initiator's name.
      return {
        ...conversation,
        displayName:
          conversation?.metadata?.company_name ||
          conversation?.metadata?.name ||
          'Unknown User',
        isZigiBot: true,
        role: 'responder',
      };
    }
  };

  function getAllChats() {
    return allChats;
  }

  const values = useMemo(
    () => ({
      getAllChats,
      messages,
      setMessages,
      conversations,
      setConversations,
      conversationsLoading,
    }),
    [allChats, messages],
  );
  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};

export const useChat = () => useContext(ChatContext);
