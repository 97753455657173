// import { TelegramIcon } from "assets/images";
// import { Button } from "components";
import ComingSoon from "components/ComingSoon";
import React from "react";

function Telegram() {
  return (
    // <div
    //   className={`h-[309px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    // >
    //   <div className="flex flex-col gap-7">
    //     <div className="flex items-center space-x-2">
    //       <TelegramIcon className="w-8 h-8" />
    //       <p className="pt-1">Telegram</p>
    //     </div>
    //     <div className="flex flex-col gap-6">
    //       <p className="font-bold text-[#0D121D]">
    //         Connect your Telegram to your Fonu Account
    //       </p>
    //       <p className="text-[#474A4E] text-[14px]">
    //         We will need permission to manage your Telegram Account in order to
    //         automate your replies.
    //         <br /> You can create Telegram bot on connected accounts.
    //       </p>
    //       <div className="w-[400px]">
    //         <Button>Connect to Telegram</Button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <ComingSoon />
  );
}

export default Telegram;
