import {
  InfoGrey,
  Chevron,
  Download,
  Search,
  Trash,
  Refresh,
  PhoneCall,
  Pause,
  Play,
  Continue,
} from "assets/images";
import AddNewContact from "components/AddNewContact";
import ContactDetails from "components/ContactDetails";
import ImportCSV from "components/ImportCSV";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useGetContactList } from "hooks";
import { Avatar, Spinner, Modal } from "components";
import {
  formatDuration,
  formatPhoneNumber,
  formatTime,
  maskNumber,
  useMonthYear,
} from "shared/resources";
import useDeleteContact from "hooks/useDeleteContact";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";
import {
  dropdownActions,
  initialModalState,
} from "pages/PhoneNumbers/constants";
import AssignToModal from "pages/PhoneNumbers/AssignToModal";
import { useAuth } from "hooks/useAuth";
import useBatchList from "hooks/useBatchList";
import AddNewBatch from "components/AddNewBatch";
import useDeleteBatch from "hooks/useDeleteBatch";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Dot,
  SortDesc,
  StopCircle,
} from "lucide-react";
import { useTranslation } from "react-i18next";

function BulkDial() {
  const [showAddOptions, setAddOptions] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contactsToDial, setContactsToDial] = useState([]);
  const [showModal, setShowModal] = useState("");
  const [showCSVModal, setCSVModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState("");
  const [activeContact, setActiveContact] = useState(null);
  const [search, setSearch] = useState("");
  const [, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(initialModalState);
  const { user } = useAuth();
  const [batchToDelete, setBatchToDelete] = useState(null);
  const [contactsLeft, setContactsLeft] = useState([]);
  const [activeBatch, setActiveBatch] = useState(null);
  const [activeBatchName, setActiveBatchName] = useState("");
  const { t } = useTranslation();
  const { data: contactData, isError, mutate, isPending } = useGetContactList();
  const { mutateAsync: deleteContactMutate } = useDeleteContact();
  const { batchData, isBatchError, batchMutate, isBatchPending } =
    useBatchList();
  const { mutateAsync: deleteBatchMutate } = useDeleteBatch();
  const [batches, setBatches] = useState(batchData?.data?.data || []);
  const [contactList, setContactList] = useState();
  const [pausedBatches, setPausedBatches] = useState([]);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [batchToExport, setBatchToExport] = useState({});
  const [activeSession, setActiveSession] = useState("");
  const [activeCallData, setActiveCallData] = useState({});
  const [isCalling, setIsCalling] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [continueLastCallLoading, setContinueLastCallLoading] = useState(false);
  const [continueLastCall, setContinueLastCall] = useState(false);
  const [perPage] = useState(1000);
  const [isAlphabeticallySorted, setIsAlphabeticallySorted] = useState(false);
  const [uiSessions, setUISessions] = useState({});

  const obj = useContext(SipSocketContext);
  const { _makeCall = () => null } = obj.socket;

  const uiSessionsRef = useRef(obj.socket._uiSessions);

  const handleUnload = () => {
    localStorage.removeItem("isDispositionOpen");
  };

  useEffect(() => {
    const pausedBatchesInString = localStorage.getItem("pausedBatches");
    if (pausedBatchesInString) {
      setPausedBatches(JSON.parse(pausedBatchesInString));
    }
    batchMutate({
      page: 1,
      limit: 100,
      search,
    });
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    const stateSessions = Object.values(uiSessions);
    const socketSessions = Object.values(obj.socket._uiSessions);
    if (
      (stateSessions.length && !socketSessions.length) ||
      (socketSessions.length && !stateSessions.length)
    ) {
      setUISessions({ ...obj.socket._uiSessions });
    }
  }, [obj]);

  useEffect(() => {
    if (
      isCalling &&
      !isPaused &&
      !isStopped &&
      !Object.values(uiSessions).length
    ) {
      createSessionLog(activeCallData, activeSession, activeBatch);
      const isDispositionOpen = localStorage.getItem("isDispositionOpen");
      localStorage.removeItem("isDispositionOpen");
      if (!isPaused && !isStopped) {
        const delay = setTimeout(
          () => {
            repeatCall(contactsLeft);
          },
          isDispositionOpen === "true"
            ? parseInt(process.env.REACT_APP_AFTER_CALL_TIMEOUT) * 1000
            : 0
        );
        return () => clearTimeout(delay);
      }
    }
  }, [isCalling, isPaused, isStopped, uiSessions]);

  useEffect(() => {
    uiSessionsRef.current = obj.socket._uiSessions;
    if (
      !Object.values(obj.socket._uiSessions).length &&
      sessionStarted &&
      !contactsLeft.length
    ) {
      removeActiveBatchFromPausedSessions();
      setSessionStarted(false);
      localStorage.removeItem("activeBatchName");
      if (isStopped) {
        createSessionLog(activeCallData, activeSession, activeBatch);
      }
      setActiveSession("");
      setIsCalling(false);
    }
  }, [
    obj,
    sessionStarted,
    activeBatch,
    activeCallData,
    activeSession,
    isCalling,
    isPaused,
    isStopped,
  ]);

  useEffect(() => {
    if (Object.values(obj.socket._uiSessions).length) {
      const activeSession = Object.values(obj.socket._uiSessions)[0];
      setActiveCallData({
        ...activeCallData,
        [activeSession._number]:
          activeSession._status === "connected" ? true : false,
      });
    }
  }, [activeCallData, obj]);

  useEffect(() => {
    setBatches(batchData?.data?.data || []);
  }, [batchData]);

  useEffect(() => {
    if (activeBatch) {
      mutate({
        page: currentPage,
        limit: perPage,
        search,
        batch_uuid: activeBatch,
      });
    }
  }, [search, currentPage, perPage, activeBatch]);

  const handleContactDetails = (contact) => {
    setActiveContact(contact);
    setAddOptions(false);
  };

  const handleDetailsModalClose = () => {
    setActiveContact(null);
  };

  const handleAddOptions = () => {
    setAddOptions(!showAddOptions);
  };

  const handleModalClose = () => {
    setShowModal("");
  };

  const handleDeleteModal = () => {
    setDeleteModal("deleteContacts");
    setAddOptions(false);
  };

  const handleBatchDeleteModal = () => {
    setDeleteModal("deleteBatch");
    setAddOptions(false);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };

  const handleCSVModalClose = () => {
    setCSVModal(false);
  };

  const handleCreateNewContact = () => {
    setShowModal("newContact");
    setAddOptions(false);
  };

  const handleCreateNewBatch = () => {
    setShowModal("newBatch");
    setAddOptions(false);
  };

  const handleImportCSV = () => {
    setCSVModal(true);
    setAddOptions(false);
  };

  const removeActiveBatchFromPausedSessions = useCallback(() => {
    const clonedPausedBatches = [...pausedBatches];
    const batchIndex = clonedPausedBatches.findIndex(
      (batch) => batch.uuid === activeBatch
    );
    if (batchIndex >= 0) {
      clonedPausedBatches.splice(batchIndex, 1);
      localStorage.setItem(
        "pausedBatches",
        JSON.stringify(clonedPausedBatches)
      );
      setPausedBatches(clonedPausedBatches);
    }
  }, [activeBatch, pausedBatches]);

  const handleTerminateCall = useCallback(() => {
    if (!activeSession) return;
    setIsCalling(false);
    setContactsLeft([]);
    removeActiveBatchFromPausedSessions();
    setIsStopped(true);
    setIsPaused(false);
    setContinueLastCall(false);
  }, [activeCallData, activeSession]);

  const handlePauseCall = useCallback(() => {
    if (isStopped || !activeSession) return;
    if (isPaused) {
      const batchExists = pausedBatches.find(
        (batch) => batch.uuid === activeBatch
      );
      if (batchExists) {
        setContactsToDial(batchExists.contacts);
        setActiveSession(batchExists.sessionUuid);
        removeActiveBatchFromPausedSessions();
        setIsPaused(false);
        return;
      }
    } else if (!isPaused) {
      setIsPaused(true);
      const clonedPausedBatches = [...pausedBatches];
      const batchIndex = clonedPausedBatches.findIndex(
        (batch) => batch.uuid === activeBatch
      );
      if (batchIndex === -1) {
        clonedPausedBatches.push({
          uuid: activeBatch,
          contacts: contactsLeft,
          sessionUuid: activeSession,
        });
      } else {
        clonedPausedBatches[batchIndex] = {
          ...clonedPausedBatches[batchIndex],
          contacts: contactsLeft,
        };
      }
      setPausedBatches(clonedPausedBatches);
      localStorage.setItem(
        "pausedBatches",
        JSON.stringify(clonedPausedBatches)
      );
      setActiveCallData({});
    }
  }, [
    pausedBatches,
    activeBatch,
    contactsLeft,
    activeSession,
    activeCallData,
    isPaused,
    isCalling,
  ]);

  if (isError) {
    return <p>Error loading contacts</p>;
  }

  if (isBatchError) {
    return <p>Error loading batches</p>;
  }

  const handleDeleteBatch = async (uuid) => {
    await deleteBatchMutate({ uuid });
    setBatches((prevBatches) =>
      prevBatches.filter((batch) => batch.uuid !== uuid)
    );
    handleDeleteModalClose();
    batchMutate({ page: 1, limit: 100, search });
    mutate({ page: 1, limit: 100, search });
  };

  const repeatCall = useCallback(
    async (contactsToDial) => {
      if (contactsToDial?.length) {
        _makeCall(contactsToDial[0], contactsToDial[0], activeSession);
        setSessionStarted(true);
        setActiveCallData({ [contactsToDial[0]]: false });

        localStorage.setItem("activeBatchName", activeBatchName);

        const remainingContacts = contactsToDial.slice(1);
        setContactsLeft(remainingContacts);
      }
    },
    [obj, activeCallData, activeSession, activeBatchName, contactsLeft]
  );

  const ModalLookup = {
    [dropdownActions.ASSIGN_TO]: (
      <AssignToModal
        showModal={showAssignModal}
        handleClose={() => {
          setShowAssignModal(initialModalState);
        }}
      />
    ),
  };
  const { isShow, modalType, type } = showAssignModal;

  const handleToggleBatch = (uuid) => {
    setActiveBatch(activeBatch === uuid ? null : uuid);
  };

  const handleSort = () => {
    setIsAlphabeticallySorted(!isAlphabeticallySorted);
    if (!isAlphabeticallySorted) {
      sortContactsAlphabetically();
    } else {
      setContactList({
        all: contactData?.data?.data || [],
      });
    }
  };

  const sortContactsAlphabetically = () => {
    if (contactData?.data?.data) {
      const sortedContacts = {};
      const sorted = [...contactData.data.data].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      sorted.forEach((contact) => {
        const key = contact.first_name.charAt(0).toUpperCase();
        if (!sortedContacts[key]) {
          sortedContacts[key] = [];
        }
        sortedContacts[key].push(contact);
      });
      setContactList(sortedContacts);
    }
  };

  useEffect(() => {
    if (!isAlphabeticallySorted) {
      const contactList = (() => {
        if (contactData && typeof contactData === "object") {
          if (Array.isArray(contactData?.data?.data)) {
            return {
              all: contactData.data.data,
            };
          }
        }
        return { all: [] };
      })();
      setContactList(contactList);
    }
  }, [contactData, isAlphabeticallySorted]);

  useEffect(() => {
    setActiveSession("");
    setContactsToDial(contactData?.data?.data?.map((d) => d.phone));
  }, [contactList]);

  useEffect(() => {
    if (!selectedContacts?.length && !contactsToDial?.length) {
      setActiveSession("");
      setContactsToDial(contactData?.data?.data?.map((d) => d.phone));
    }
  }, [selectedContacts, contactsToDial, contactList]);

  useEffect(() => {
    if (activeBatch) {
      mutate({
        page: currentPage,
        limit: perPage,
        search,
        batch_uuid: activeBatch,
      });
    }
  }, [search, currentPage, perPage]);

  useEffect(() => {
    if (batchToExport.uuid) {
      handleExportBatchContacts();
    }
  }, [batchToExport]);

  useEffect(() => {
    if (activeSession && contactsToDial?.length) {
      repeatCall(contactsToDial);
    }
  }, [activeSession, contactsToDial]);

  useEffect(() => {
    if (continueLastCall && contactsLeft?.length === 0) {
      setContinueLastCall(false);
    }
  }, [contactsLeft]);

  useEffect(() => {
    if (!continueLastCall) {
      setActiveSession("");
      setContactsToDial(contactData?.data?.data?.map((d) => d.phone));
    }
  }, [continueLastCall]);

  const handleRefresh = () => {
    mutate({
      page: currentPage,
      limit: perPage,
      search,
      batch_uuid: activeBatch,
    });

    batchMutate({
      page: 1,
      limit: 100,
      search,
    });
  };

  const handleExportBatchContacts = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.EXPORT_BATCH.URL}`,
        {
          batch_uuid: batchToExport.uuid,
          batch_name: batchToExport.name,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `batch-${batchToExport.name
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase()}-contacts.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        console.error("error from export batch contacts", error);
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  }, [batchToExport]);

  const handleExportContactBatches = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.EXPORT_CONTACT.URL}`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "contacts-batches.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        console.error("error from export contact batches", error);
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const createSessionLog = async (
    activeCallData,
    activeSession,
    activeBatch
  ) => {
    try {
      const to = Object.keys(activeCallData)[0];
      if (to) {
        await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}${routes.CREATE_SESSION_LOG.URL}`,
          {
            session_uuid: activeSession,
            from: "",
            to,
            status: Object.values(activeCallData)[0],
            batch_uuid: activeBatch,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
      }
      setActiveCallData({});
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        console.error("error from create session log", error);
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    }
  };

  const handleBulkDial = useCallback(
    async (batchName, total_contacts) => {
      setActiveBatchName(batchName);
      setIsStopped(false);
      setIsCalling(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}${routes.CREATE_SESSION.URL}`,
          {
            batch_uuid: activeBatch,
            totalContacts: total_contacts,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );
        setActiveSession(response.data.data.result[0].uuid);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          return (window.location.href = "/");
        } else {
          console.error("error from bulk dial", error);
          toastEmitter("error", error?.response?.data?.error?.message);
        }
      }
    },
    [pausedBatches, activeBatch, contactsToDial, activeSession]
  );

  const handleContinueCall = async (batchName, total_contacts) => {
    setContinueLastCallLoading(true);
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_API_URL}${routes.LIST_CONTACTS_AFTER_LAST_DIALED.URL}`,
          {
            batch_uuid: activeBatch,
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.data.length) {
            toastEmitter("error", `There are no numbers to dial`);
            setContinueLastCallLoading(false);
            return;
          }
          setContactsToDial(res.data.data.map((c) => c.phone));
          setContinueLastCall(true);
          setContinueLastCallLoading(false);
          handleBulkDial(batchName, total_contacts);
        });
    } catch (error) {
      setContinueLastCallLoading(false);
      console.log("error from continue last call", error);
    }
  };

  const Pagination = () => {
    const { currentPage, lastPage, nextPage, prevPage } =
      contactData?.data?.pagination || {};

    const handleClickPage = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 4;

      if (lastPage <= maxPagesToShow) {
        for (let i = 1; i <= lastPage; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handleClickPage(i)}
              className={`px-2 py-1 mx-1 ${
                currentPage === i ? "font-bold text-green-300" : ""
              }`}
            >
              {i}
            </button>
          );
        }
      } else {
        pageNumbers.push(
          <button
            key={1}
            onClick={() => handleClickPage(1)}
            className={`px-2 py-1 mx-1 ${
              currentPage === 1 ? "font-bold text-green-300" : ""
            }`}
          >
            1
          </button>
        );

        if (currentPage > 3) {
          pageNumbers.push(<span key="left-ellipsis">...</span>);
        }

        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(lastPage - 1, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handleClickPage(i)}
              className={`px-2 py-1 mx-1 ${
                currentPage === i ? "font-bold  text-green-300" : ""
              }`}
            >
              {i}
            </button>
          );
        }

        if (currentPage < lastPage - 2) {
          pageNumbers.push(<span key="right-ellipsis">...</span>);
        }

        pageNumbers.push(
          <button
            key={lastPage}
            onClick={() => handleClickPage(lastPage)}
            className={`px-2 py-1 mx-1 ${
              currentPage === lastPage ? "font-bold text-green-300" : ""
            }`}
          >
            {lastPage}
          </button>
        );
      }

      return pageNumbers;
    };

    return (
      <div className="flex justify-between items-center mt-4 space-x-2">
        <p>
          Page {currentPage} of {lastPage}
        </p>
        <div className="flex justify-center items-center space-x-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="disabled:opacity-50"
          >
            <ChevronsLeft size={20} />
          </button>

          <button
            onClick={() => setCurrentPage(prevPage)}
            disabled={!prevPage}
            className="disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>

          {renderPageNumbers()}

          <button
            onClick={() => setCurrentPage(nextPage)}
            disabled={!nextPage}
            className="disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>

          <button
            onClick={() => setCurrentPage(lastPage)}
            disabled={currentPage === lastPage}
            className="disabled:opacity-50"
          >
            <ChevronsRight size={20} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={` mx-auto max-w-[1440px] relative`}>
      <div className="flex justify-between border-b border-black/20 pb-6">
        <div>
          <p className="font-bold text-[20px]">Bulk Dial</p>
          <p className={`pt-1 text-[15px] font-[400]`}>
            {t("overview.manageContacts")}
          </p>
        </div>
        {user.role_id !== 3 && (
          <button
            onClick={handleAddOptions}
            className="flex gap-1 relative bg-[#4F772D] w-[100px] h-[40px] rounded-md outline-none justify-center items-center text-white"
          >
            + {t("overview.add")}
            <Chevron />
          </button>
        )}
        {showAddOptions && (
          <div className="absolute top-10 z-40 right-12 rounded-md shadow-lg bg-white w-[180px] text-[14px] flex flex-col gap-2 py-2 ">
            <button
              onClick={handleCreateNewContact}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              {t("overview.createNewContact")}
            </button>
            <button
              onClick={handleCreateNewBatch}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              {t("overview.createNewBatch")}
            </button>
            <button
              onClick={handleImportCSV}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              {t("overview.importCsv")}
            </button>
          </div>
        )}
      </div>
      <section>
        <div className="flex gap-6 border-b border-black/20 py-6">
          {user.role_id !== 3 && (
            <button
              onClick={async (event) => {
                event.preventDefault();
                setLoading(true);
                handleExportContactBatches();
                setLoading(false);
              }}
              className="flex gap-1 relative border border-black/20 text-[14px] w-[170px] h-[40px] rounded-md outline-none justify-center items-center text-black"
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <Download />
                  {t("overview.exportCsv")}
                </>
              )}
            </button>
          )}
          <div className="w-full">
            <input
              type="text"
              value={search}
              onChange={(event) => {
                setPage(1);
                setSearch(event.target.value);
              }}
              placeholder={t("search.searchByNumberOrName")}
              className="w-[100%] relative rounded-md outline-none border-none bg-black/10 focus:border-black/10 pl-12 text-[14px]"
            />
            <Search className="absolute mt-[-30px] ml-3" />
          </div>
        </div>
      </section>
      <section className="flex items-center flex-wrap gap-6 my-4">
        <button
          onClick={handleRefresh}
          className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
        >
          <Refresh className="h-[20px] w-[20px]" />
          {t("overview.refresh")}
        </button>
        {user.role_id !== 3 && (
          <button
            onClick={() => {
              if (selectedContacts.length) {
                setShowAssignModal({
                  isShow: true,
                  modalType: dropdownActions.ASSIGN_TO,
                  elementData: {},
                  type: "center",
                });
              }
            }}
            className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
          >
            {t("overview.assignMember")}
          </button>
        )}
      </section>

      <section className="my-8">
        {isBatchPending ? (
          <div className="items-center flex justify-center my-4">
            <Spinner />
          </div>
        ) : (
          <>
            {batches?.length === 0 ? (
              <div>
                <p className="text-center text-[18px] font-bold text-black/50">
                  {t("overview.noBatches")}
                </p>
              </div>
            ) : (
              <div className="flex flex-col border rounded-md">
                {batches.map((batch, index) => {
                  return (
                    <div
                      key={batch.uuid}
                      className={`my-2 ${
                        index === batchData?.data?.data.length - 1
                          ? "border-none"
                          : "border-b"
                      }`}
                    >
                      <div
                        className="text-black font-bold text-xl cursor-pointer py-3 "
                        onClick={() => handleToggleBatch(batch.uuid)}
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center px-2 pr-4">
                            <span>{batch.name}</span>
                            <span className="text-base font-light ">
                              {batch.total_contacts > 0
                                ? batch.total_contacts === 1
                                  ? `(${batch.total_contacts} contact)`
                                  : `(${batch.total_contacts} contacts)`
                                : "(No contacts yet)"}
                            </span>
                          </div>
                          {user.role_id !== 3 && (
                            <div className="flex gap-2 items-center">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBatchToExport({
                                    uuid: batch.uuid,
                                    name: batch.name,
                                  });
                                }}
                                className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-green-300 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                              >
                                <Download className="h-[20px] w-[20px]" />
                                {t("overview.export")}
                              </button>

                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setBatchToDelete(batch.uuid);
                                  handleBatchDeleteModal();
                                }}
                                className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-danger hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                              >
                                <Trash className="h-[20px] w-[20px]" />
                                {t("overview.delete")}
                              </button>
                              <Chevron
                                className={`transition-transform ${
                                  activeBatch === batch.uuid
                                    ? "rotate-180"
                                    : "rotate-0"
                                }`}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {activeBatch === batch.uuid && (
                        <div className="p4 mt-1 bg-gray-100 rounded-md">
                          <div className="flex gap-2 items-center px-4">
                            <div className="flex gap-2 items-center">
                              {!Object.entries(contactList).length > 0 ? (
                                ""
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedContacts.length ===
                                    contactData?.data?.data.length
                                  }
                                  onChange={() => {
                                    selectedContacts.length ===
                                    contactData?.data?.data.length
                                      ? setSelectedContacts([])
                                      : setSelectedContacts(
                                          contactData?.data?.data?.map(
                                            (d) => d.uuid
                                          )
                                        );
                                  }}
                                  className="checked:bg-[#4F772D] rounded-sm focus:bg-[#4F772D] cursor-pointer"
                                />
                              )}
                            </div>
                            <div>
                              {!Object.entries(contactList)?.length > 0 ? (
                                ""
                              ) : (
                                <div className="flex gap-2 items-center">
                                  {user.role_id !== 3 && (
                                    <button
                                      onClick={handleDeleteModal}
                                      className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-danger hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                                    >
                                      <Trash className="h-[20px] w-[20px]" />
                                      {t("overview.delete")}
                                    </button>
                                  )}
                                  <button
                                    onClick={() => {
                                      if (activeSession) return;
                                      handleBulkDial(
                                        batch.name,
                                        batch.total_contacts
                                      );
                                    }}
                                    className="flex gap-2 w-[200px] h-[40px] items-center justify-center hover:bg-[#4F772D] hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                                  >
                                    <PhoneCall className="h-[20px] w-[20px]" />
                                    {t("overview.bulkDial")}
                                  </button>
                                  <button
                                    onClick={handlePauseCall}
                                    className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-[#4F772D] hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                                  >
                                    {pausedBatches.find(
                                      (batch) => batch.uuid === activeBatch
                                    ) ? (
                                      <span className="flex gap-2">
                                        <Play />
                                        {t("overview.resume")}
                                      </span>
                                    ) : (
                                      <span className="flex gap-2">
                                        <Pause width={20} height={20} />
                                        {t("overview.pause")}
                                      </span>
                                    )}
                                  </button>
                                  <button
                                    onClick={handleTerminateCall}
                                    className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-danger hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                                  >
                                    <StopCircle />
                                    {t("overview.stop")}
                                  </button>
                                  <button
                                    disabled={continueLastCallLoading}
                                    onClick={() =>
                                      handleContinueCall(
                                        batch.name,
                                        batch.total_contacts
                                      )
                                    }
                                    className="flex gap-2 w-[180px] h-[40px] items-center justify-center hover:bg-[#4F772D] hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                                  >
                                    <Continue />
                                    Continue Last call
                                  </button>
                                  <button
                                    onClick={handleSort}
                                    className={`flex gap-2 w-[180px] h-[40px] items-center justify-center ${
                                      isAlphabeticallySorted
                                        ? "bg-[#4F772D] text-white"
                                        : "hover:bg-[#4F772D] hover:border-0 hover:text-white"
                                    } duration-500 border-black/20 border text-[14px] rounded-md`}
                                  >
                                    <SortDesc />
                                    {isAlphabeticallySorted
                                      ? "Reset Sort"
                                      : "Sort A-Z"}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          {contactData?.data?.meta && (
                            <div className="mt-2">
                              <p className="p-3">Last number dialed</p>

                              <div className="bg-[#F9FAFB] h-[60px] w-full flex justify-around items-center p-3">
                                <div className="flex gap-1 items-center">
                                  <p>
                                    {useMonthYear(
                                      contactData?.data?.meta.created_at
                                    )}
                                  </p>
                                  <Dot />
                                  <p>
                                    {formatTime(
                                      contactData?.data?.meta.created_at
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <p>{contactData?.data?.meta.contact_name}</p>
                                  <p className="text-black/40">
                                    {formatPhoneNumber(
                                      contactData?.data?.meta.to
                                    )}
                                  </p>
                                </div>
                                {contactData?.data?.meta.member_name ===
                                "null null" ? (
                                  "----"
                                ) : (
                                  <div className="flex items-center gap-2">
                                    <Avatar
                                      name={`${
                                        contactData?.data?.meta.member_name[0]
                                      } ${
                                        contactData?.data?.meta.member_name.split(
                                          " "
                                        )[1]
                                      }`}
                                    />
                                    <p>{contactData?.data?.meta.member_name}</p>
                                  </div>
                                )}
                                <div>
                                  <p>
                                    {formatDuration(
                                      contactData?.data?.meta.duration
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          {isPending ? (
                            <div>
                              <Spinner />
                            </div>
                          ) : Object.entries(contactList)?.length === 0 ? (
                            <p className="mt-2 text-center font-bold">
                              {t("overview.noContacts")}
                            </p>
                          ) : (
                            <div className="flex flex-col gap-2 px-4">
                              <Pagination />
                              {Object.entries(contactList).map(
                                ([category, contacts]) => {
                                  return (
                                    <div
                                      key={category}
                                      className="flex gap-3 mt-2"
                                    >
                                      {isAlphabeticallySorted &&
                                        category !== "all" && (
                                          <h2 className="font-bold mt-2 text-[18px]">
                                            {category}
                                          </h2>
                                        )}
                                      <div className="w-full mt-2">
                                        {contacts.map((contact, index) => (
                                          <div
                                            key={index}
                                            className="flex gap-4 items-center pt-2 my-3 hover:bg-black/20 hover:px-2 rounded-md cursor-pointer duration-500"
                                          >
                                            <input
                                              type="checkbox"
                                              checked={selectedContacts.includes(
                                                contact.uuid
                                              )}
                                              onChange={() => {
                                                const contactIndex =
                                                  selectedContacts.findIndex(
                                                    (c) => c === contact.uuid
                                                  );
                                                const contactToDialIndex =
                                                  contactsToDial.findIndex(
                                                    (c) => c === contact.phone
                                                  );
                                                const shouldOverride =
                                                  contactsToDial.length >
                                                  selectedContacts.length;
                                                if (contactIndex !== -1) {
                                                  const clonedContacts = [
                                                    ...selectedContacts,
                                                  ];
                                                  clonedContacts.splice(
                                                    contactIndex,
                                                    1
                                                  );
                                                  setSelectedContacts(
                                                    clonedContacts
                                                  );
                                                } else {
                                                  setSelectedContacts([
                                                    ...selectedContacts,
                                                    contact.uuid,
                                                  ]);
                                                }
                                                if (contactToDialIndex !== -1) {
                                                  if (shouldOverride) {
                                                    setContactsToDial([
                                                      contact.phone,
                                                    ]);
                                                  } else {
                                                    const updatedContactsToDial =
                                                      contactsToDial.filter(
                                                        (c) =>
                                                          c !== contact.phone
                                                      );
                                                    setContactsToDial(
                                                      updatedContactsToDial
                                                    );
                                                  }
                                                } else {
                                                  setContactsToDial((prev) => [
                                                    ...prev,
                                                    contact.phone,
                                                  ]);
                                                }
                                              }}
                                              className="checked:bg-[#4F772D] rounded-sm focus:bg-[#4F772D] cursor-pointer"
                                            />
                                            <div className="flex border-b border-black/20 items-center w-full gap-4">
                                              <div
                                                onClick={() =>
                                                  handleContactDetails(contact)
                                                }
                                                className="flex justify-between w-full items-center"
                                              >
                                                <div className="flex gap-2 pb-3 w-full items-center">
                                                  <Avatar
                                                    name={`${
                                                      contact?.first_name
                                                    } ${
                                                      contact?.last_name ?? ""
                                                    }`}
                                                  />
                                                  <div className="flex flex-col gap-1">
                                                    <p className="text-[16px] font-semibold">
                                                      {contact.first_name}{" "}
                                                      {contact.last_name}
                                                    </p>

                                                    <p className="text-[14px] font-[400]">
                                                      {user.role_id === 3
                                                        ? maskNumber(
                                                            contact.phone
                                                          )
                                                        : formatPhoneNumber(
                                                            contact.phone
                                                          )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div>
                                                  <InfoGrey className="text-[#4F772D] cursor-pointer" />
                                                </div>
                                              </div>
                                              <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  _makeCall(
                                                    contact.phone,
                                                    contact.phone
                                                  );
                                                }}
                                              >
                                                <PhoneCall className="text-[#4F772D] cursor-pointer" />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      {showDeleteModal === "deleteBatch" && (
                        <div
                          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
                          onClick={handleDeleteModalClose}
                        >
                          <div
                            className="bg-white p-4 rounded-lg w-[350px] h-[250px] flex flex-col justify-center text-center items-center overflow-scroll"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <p className="text-[20px] font-bold pb-2">
                              {t("overview.deleteBatchConfirmation")}
                            </p>
                            <div className="flex gap-4 mt-6 justify-center items-center">
                              <button
                                onClick={handleDeleteModalClose}
                                className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
                              >
                                {t("overview.close")}
                              </button>
                              <button
                                type="submit"
                                className="bg-danger w-[100px] h-[40px] rounded flex items-center justify-center"
                                onClick={() => handleDeleteBatch(batchToDelete)}
                              >
                                {t("overview.yes")}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </section>

      {user.role_id !== 3 && activeContact && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleDetailsModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <ContactDetails
              handleDetailsModalClose={handleDetailsModalClose}
              contact={activeContact}
            />
          </div>
        </div>
      )}

      {showModal === "newContact" && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <AddNewContact
              handleContactModalClose={handleModalClose}
              mutate={mutate}
            />
          </div>
        </div>
      )}
      {showModal === "newBatch" && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[400px] h-[350px] overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <AddNewBatch
              handleBatchModalClose={handleModalClose}
              batchMutate={batchMutate}
            />
          </div>
        </div>
      )}
      {showCSVModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCSVModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[550px] overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <ImportCSV handleCSVModalClose={handleCSVModalClose} />
          </div>
        </div>
      )}
      {showDeleteModal === "deleteContacts" && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleDeleteModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-[350px] h-[250px] flex flex-col justify-center text-center items-center overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-[20px] font-bold pb-2">
              {t("overview.deleteConfirmation")}
            </p>
            <div className="flex gap-4 mt-6 justify-center items-center">
              <button
                onClick={handleDeleteModalClose}
                className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
              >
                {t("overview.close")}
              </button>
              <button
                type="submit"
                className="bg-danger w-[100px] h-[40px] rounded flex items-center justify-center"
                onClick={async () => {
                  if (selectedContacts.length) {
                    await deleteContactMutate({
                      uuid: selectedContacts.join(","),
                    });
                  }
                  handleDeleteModalClose();
                  mutate({
                    page: 1,
                    limit: 1000,
                    search,
                    batch_uuid: activeBatch,
                  });
                  batchMutate({
                    page: 1,
                    limit: 1000,
                    search,
                  });
                }}
              >
                {t("overview.yes")}
              </button>
            </div>
          </div>
        </div>
      )}

      {isShow && type === "center" ? (
        <Modal
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
}

export default BulkDial;
