import { SidebarLayout } from "layouts";
import {
  Automations,
  Bundle,
  CallRecordings,
  Calls,
  Campaign,
  Dashboard,
  Forwarding,
  Greetings,
  Inbox,
  Members,
  PageNotFound,
  PaymentVerification,
  MonoPaymentVerification,
  PhoneMenu,
  PhoneNumbers,
  PortNumber,
  PortNumberStatus,
  Report,
  SMS,
  Teams,
  Unauthorized,
  Verification,
  Voicemail,
  EmbeddedSignup,
} from "pages";
import Cart from "pages/PhoneNumbers/Cart";
import PortNumberForm from "pages/PortNumber/PortNumberForm";
import PortNumberListing from "pages/PortNumber/PortNumberListing";
import Profile from "pages/Profile";
import Blocklist from "pages/Profile/Blocklist";
import Pnb from "pages/Profile/Pnb";
import ChatList from "pages/SMS/Components/ChatList";
import ChatViewArea from "pages/SMS/Components/ChatViewArea";
import Layout from "pages/SMS/Layout";
import ProfileLayout from "pages/Profile/Layout";
import { lazy } from "react";
import { Outlet, createBrowserRouter } from "react-router-dom";
import { ADMIN, MEMBER, OWNER } from "shared/resources";
import ForgotPassword from "pages/ForgotPassword";
import VerificationLayout from "layouts/VerificationLayout";
import ChangePassword from "pages/Profile/ChangePassword";
import TermsNConditions from "pages/TermsNConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import { Transactions } from "pages/Transactions";
import { SavedCards } from "pages/SavedCards";
import { InviteAndEarn } from "pages/Profile/InviteAndEarn";
import { KYC } from "pages/KYC";
import KYCReadView from "pages/KYC/KYCReadView";
import { BundleData } from "pages/Bundle/BundleData";
import { KycReports } from "pages/KYC/KycReports";
import Wallet from "pages/Wallet";
import WalletPaymentVerification from "pages/WalletPaymentVerification";
import MonoWalletPaymentVerification from "pages/MonoWalletPaymentVerification";
import BulkCallDetails from "pages/Calls/BulkCallDetails";
import ApiKeys from "pages/ApiKeys";
// import Integrations from "pages/Integrations";
import Whatsapp from "pages/WhatsappBusiness";
import Telegram from "pages/Telegram";
import Instagram from "pages/Instagram";
import TwitterDm from "pages/TwitterDm";
import Facebook from "pages/Facebook";
import LinkedInMessaging from "pages/LinkedInMessaging";
import AiAgents from "pages/AiAgents";
import InboxLayout from "pages/Inbox/Layout";
import InboxList from "pages/Inbox/Components/InboxList";
import InboxViewArea from "pages/Inbox/Components/InboxViewArea";
import SDK from "pages/SDK";
import SmsSocials from "pages/SmsSocials";
import SessionLogs from "pages/SessionLogs";
import OmniChannel from "pages/OmniChannel";
import AllBots from "pages/AllBots";
import Tickets from "pages/Tickets";
import TemplateStore from "pages/TemplateStore";
import Analytics from "pages/Analytics";
import IntegrationsLayout from "pages/Integrations/Layout";
import VoiceSms from "pages/VoiceSms";
import Shopify from "pages/Shopify";
import FacebookBusiness from "pages/FacebookBusiness";
import Zoho from "pages/Zoho";
import Hubspot from "pages/Hubspot";
import MoMoPsb from "pages/MoMoPsb";
import Paystack from "pages/Paystack";
import Calendly from "pages/Calendly";
import Intercom from "pages/Intercom";
import Freshchats from "pages/Freshchats";
import Zapier from "pages/Zapier";
import OpenAi from "pages/OpenAi";
import Gemini from "pages/Gemini";
import ClaudeAi from "pages/ClaudeAi";
import GoogleSheet from "pages/GoogleSheet";
import MongoDB from "pages/MongoDb";
import MySql from "pages/MySql";
import PostgreSQL from "pages/PostgreSql";
import Cloudinary from "pages/Cloudinary";
import S3Storage from "pages/S3Storage";
import SmtpProfile from "pages/Smtp";
import Gmail from "pages/Gmail";
import SendGrid from "pages/SendGrid";
import GoogleMaps from "pages/GMaps";
import FacebookAds from "pages/FacebookAds";
import GoogleTag from "pages/GoogleTag";
import Slack from "pages/Slack";
import Flow from "pages/Flow";
import Zigi from "pages/Zigi";
import Freshworks from "pages/Freshworks";
import MCalendar from "pages/MCalendar";
import GCalendar from "pages/GCalendar";
import Cal from "pages/Cal";
import KnowledgeBased from "pages/KnowledgeBased";
import BulkDial from "pages/BulkDial";
import DialContacts from "pages/DialContacts";

const Login = lazy(() => import("pages/Login"));
const Signup = lazy(() => import("pages/Signup"));
const AuthRemover = lazy(() => import("auth/AuthRemover"));
const AuthProvider = lazy(() => import("auth/AuthProvider"));
const AuthLayout = lazy(() => import("layouts/AuthLayout"));
const Access = lazy(() => import("auth/Access"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthRemover>
        <Outlet />
      </AuthRemover>
    ),
    children: [
      {
        index: true,
        element: <Login />,
        id: "Login",
      },
      {
        path: "signup",
        element: <Signup />,
        id: "Signup",
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        id: "ForgotPassword",
      },
      {
        path: "term-n-conditions",
        element: <TermsNConditions />,
        id: "TermsAndConditions",
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
        id: "PrivacyPolicy",
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthProvider>
        <AuthLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: "mono-payment-verification",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <MonoPaymentVerification />
          </Access>
        ),
        id: "MonoPaymentVerification",
      },
      {
        path: "payment-verification",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <PaymentVerification />
          </Access>
        ),
        id: "PaymentVerification",
      },
      {
        path: "mono-wallet-payment-verification",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <MonoWalletPaymentVerification />
          </Access>
        ),
        id: "MonoWalletPaymentVerification",
      },
      {
        path: "wallet-payment-verification",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <WalletPaymentVerification />
          </Access>
        ),
        id: "WalletPaymentVerification",
      },

      {
        path: "overview",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <Dashboard />
          </Access>
        ),
        id: "Overview",
      },
      {
        path: "profile",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <ProfileLayout />
          </Access>
        ),
        children: [
          {
            path: "general",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Profile />
              </Access>
            ),
          },
          {
            path: "pnb",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Pnb />
              </Access>
            ),
          },
          {
            path: "invite-earn",
            element: (
              <Access allowedRoles={[OWNER, ADMIN, MEMBER]}>
                <InviteAndEarn />
              </Access>
            ),
          },
          {
            path: "transactions",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Transactions />
              </Access>
            ),
          },
          {
            path: "saved-cards",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <SavedCards />
              </Access>
            ),
          },
          {
            path: "wallet",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Wallet />
              </Access>
            ),
          },
          {
            path: "blocklist",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Blocklist />
              </Access>
            ),
          },
          {
            path: "password",
            element: (
              <Access allowedRoles={[OWNER, ADMIN, MEMBER]}>
                <ChangePassword />
              </Access>
            ),
          },
        ],
        id: "Profile",
      },

      {
        path: "live-chat",
        element: (
          <Access allowedRoles={[OWNER, ADMIN, MEMBER]}>
            <InboxLayout />
          </Access>
        ),
        children: [
          { index: true, element: <Inbox /> },
          {
            path: ":chatId",
            element: <InboxList />,
            children: [
              {
                index: true,
                element: <InboxViewArea />,
              },
            ],
          },
        ],
        id: "live-chat",
      },
      {
        path: "phone-numbers",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: "Phone",
        children: [
          {
            path: "active-numbers",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <PhoneNumbers />
              </Access>
            ),
          },
          {
            id: "PortNumber",
            path: "port-number",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <PortNumber />
              </Access>
            ),
            children: [
              {
                index: true,
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PortNumberListing />
                  </Access>
                ),
              },
              {
                path: "form",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PortNumberForm />
                  </Access>
                ),
              },

              {
                path: "status",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PortNumberStatus />
                  </Access>
                ),
              },
            ],
          },
          {
            path: "cart",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Cart />
              </Access>
            ),
          },
          {
            id: "call-details",
            path: "details",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Calls />
              </Access>
            ),
          },
          {
            id: "call-recordings",
            path: "recordings",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <CallRecordings />
              </Access>
            ),
          },
          {
            id: "bulk-call-details",
            path: "bulk-call-details",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <BulkCallDetails />
              </Access>
            ),
          },
          {
            id: "session-logs",
            path: "bulk-call-details/:id",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <SessionLogs />
              </Access>
            ),
          },
        ],
      },
      {
        path: "settings",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: "settings",
        children: [
          {
            id: "voicemails",
            path: "voicemails",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Voicemail />
              </Access>
            ),
          },
          {
            id: "forwarding",
            path: "forwarding",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Forwarding />
              </Access>
            ),
          },
          {
            id: "greetings",
            path: "greetings",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Greetings />
              </Access>
            ),
          },
          {
            id: "phone-menu",
            path: "phonemenu",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <PhoneMenu />
              </Access>
            ),
          },
          {
            path: "customer-kyc",
            children: [
              {
                index: true,
                element: <KYC />,
              },
              {
                path: "read-view",
                element: <KYCReadView />,
              },
            ],
          },
          {
            path: "kyc-details",
            element: <KycReports />,
          },
          {
            id: "api-keys-webhooks",
            path: "api-keys-webhooks",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <ApiKeys />
              </Access>
            ),
          },
          {
            id: "whatsapp",
            path: "whatsapp",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Whatsapp />
              </Access>
            ),
          },
          {
            id: "telegram",
            path: "telegram",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Telegram />
              </Access>
            ),
          },
          {
            id: "instagram",
            path: "instagram",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Instagram />
              </Access>
            ),
          },
          {
            id: "twitter",
            path: "twitter",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <TwitterDm />
              </Access>
            ),
          },
          {
            id: "facebook",
            path: "facebook",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Facebook />
              </Access>
            ),
          },
          {
            id: "linkedin",
            path: "linkedin",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <LinkedInMessaging />
              </Access>
            ),
          },
          {
            id: "zigi",
            path: "zigi",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Zigi />
              </Access>
            ),
          },
          {
            id: "flow",
            path: "flow",
            parent: "flow",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Flow />
              </Access>
            ),
          },
          {
            id: "sms-socials",
            path: "sms-socials",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <SmsSocials />
              </Access>
            ),
          },
          {
            id: "integrations",
            path: "integrations",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <IntegrationsLayout />
              </Access>
            ),
            children: [
              // { index: true, path: "voice-sms", element: <Integrations /> },
              {
                id: "voice-sms",
                path: "voice-sms",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <VoiceSms />
                  </Access>
                ),
              },
              {
                id: "shopify",
                path: "shopify",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Shopify />
                  </Access>
                ),
              },
              {
                id: "facebook-business",
                path: "facebook-business",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <FacebookBusiness />
                  </Access>
                ),
              },
              {
                id: "zoho",
                path: "zoho",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Zoho />
                  </Access>
                ),
              },
              {
                id: "hubspot",
                path: "hubspot",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Hubspot />
                  </Access>
                ),
              },
              {
                id: "cal",
                path: "cal",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Cal />
                  </Access>
                ),
              },
              {
                id: "microsoft-calendar",
                path: "microsoft-calendar",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <MCalendar />
                  </Access>
                ),
              },
              {
                id: "google-calendar",
                path: "google-calendar",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <GCalendar />
                  </Access>
                ),
              },
              {
                id: "momo-psb",
                path: "momo-psb",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <MoMoPsb />
                  </Access>
                ),
              },
              {
                id: "paystack",
                path: "paystack",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Paystack />
                  </Access>
                ),
              },
              {
                id: "calendly",
                path: "calendly",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Calendly />
                  </Access>
                ),
              },
              {
                id: "intercom",
                path: "intercom",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Intercom />
                  </Access>
                ),
              },
              {
                id: "freshchats",
                path: "freshchats",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Freshchats />
                  </Access>
                ),
              },
              {
                id: "freshworks",
                path: "freshworks",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Freshworks />
                  </Access>
                ),
              },
              {
                id: "zapier",
                path: "zapier",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Zapier />
                  </Access>
                ),
              },
              {
                id: "open-ai",
                path: "open-ai",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <OpenAi />
                  </Access>
                ),
              },
              {
                id: "google-gemini",
                path: "google-gemini",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Gemini />
                  </Access>
                ),
              },
              {
                id: "claude-ai",
                path: "claude-ai",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <ClaudeAi />
                  </Access>
                ),
              },
              {
                id: "google-sheet",
                path: "google-sheet",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <GoogleSheet />
                  </Access>
                ),
              },
              {
                id: "mongo-db",
                path: "mongo-db",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <MongoDB />
                  </Access>
                ),
              },
              {
                id: "my-sql",
                path: "my-sql",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <MySql />
                  </Access>
                ),
              },
              {
                id: "postgresql",
                path: "postgresql",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <PostgreSQL />
                  </Access>
                ),
              },
              {
                id: "cloudinary",
                path: "cloudinary",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Cloudinary />
                  </Access>
                ),
              },
              {
                id: "s3-storage",
                path: "s3-storage",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <S3Storage />
                  </Access>
                ),
              },
              {
                id: "smtp-profile",
                path: "smtp-profile",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <SmtpProfile />
                  </Access>
                ),
              },
              {
                id: "gmail",
                path: "gmail",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Gmail />
                  </Access>
                ),
              },
              {
                id: "sendgrid",
                path: "sendgrid",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <SendGrid />
                  </Access>
                ),
              },
              {
                id: "google-map",
                path: "google-map",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <GoogleMaps />
                  </Access>
                ),
              },
              {
                id: "facebook-ads",
                path: "facebook-ads",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <FacebookAds />
                  </Access>
                ),
              },
              {
                id: "google-tag",
                path: "google-tag",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <GoogleTag />
                  </Access>
                ),
              },
              {
                id: "slack-webhook",
                path: "slack-webhook",
                element: (
                  <Access allowedRoles={[OWNER, ADMIN]}>
                    <Slack />
                  </Access>
                ),
              },
            ],
          },
          {
            id: "sdk-integration",
            path: "sdk-integration",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <SDK />
              </Access>
            ),
          },

          {
            id: "omni-channel",
            path: "omni-channel",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <OmniChannel />
              </Access>
            ),
          },
          {
            id: "all-bots",
            path: "all-bots",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <AllBots />
              </Access>
            ),
          },
          {
            id: "tickets",
            path: "tickets",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Tickets />
              </Access>
            ),
          },
          {
            id: "template-store",
            path: "template-store",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <TemplateStore />
              </Access>
            ),
          },
          {
            id: "analytics",
            path: "analytics",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <Analytics />
              </Access>
            ),
          },
        ],
      },
      {
        path: "sms",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Layout />
          </Access>
        ),
        children: [
          { index: true, element: <SMS /> },
          {
            path: ":number",
            element: <ChatList />,
            children: [
              {
                index: true,
                element: <ChatViewArea />,
              },
              {
                path: ":id",
                element: <ChatViewArea />,
              },
            ],
          },
        ],
        id: "sms",
      },
      {
        path: "bundle",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        children: [
          {
            path: "purchase",
            element: <Bundle />,
          },
          {
            path: "data",
            element: <BundleData />,
          },
        ],
        id: "Bundle",
      },
      {
        path: "teams",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: "teams",
        children: [
          {
            path: "all-teams",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Teams />
              </Access>
            ),
          },
          {
            id: "Members",
            path: "members",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <Members />
              </Access>
            ),
          },
        ],
      },
      {
        path: "campaign",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Campaign />
          </Access>
        ),
        id: "campaign",
      },
      {
        path: "report",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Report />
          </Access>
        ),
        id: "report",
      },
      {
        path: "automations",
        element: (
          <Access allowedRoles={[OWNER, ADMIN]}>
            <Automations />
          </Access>
        ),
        id: "automations",
      },
      {
        path: "autodial",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: "autodial",
        children: [
          {
            path: "bulk-dial",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <BulkDial />
              </Access>
            ),
          },
          {
            path: "contacts",
            element: (
              <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
                <DialContacts />
              </Access>
            ),
          },
        ],
      },
      {
        path: "embedded-signup",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <EmbeddedSignup />
          </Access>
        ),
        id: "embedded-signup",
      },

      // {
      //   path: "KYC",
      //   element: (
      //     <Access allowedRoles={[OWNER, ADMIN]}>
      //       <SidebarLayout />
      //     </Access>
      //   ),
      //   children: [
      //     {
      //       path: "customer-kyc",
      //       element: <Outlet />,
      //       children: [
      //         {
      //           index: true,
      //           element: <KYC />,
      //         },
      //         {
      //           path: "read-view",
      //           element: <KYCReadView />,
      //         },
      //       ],
      //     },
      //     {
      //       path: "kyc-details",
      //       element: <KycReports />,
      //     },
      //   ],
      //   id: "KYC",
      // },
      {
        path: "ai-fonu",
        element: (
          <Access allowedRoles={[MEMBER, OWNER, ADMIN]}>
            <SidebarLayout />
          </Access>
        ),
        id: "ai-fonu",
        children: [
          {
            path: "knowledge-base",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <KnowledgeBased />
              </Access>
            ),
          },
          {
            path: "ai-agents",
            element: (
              <Access allowedRoles={[OWNER, ADMIN]}>
                <AiAgents />
              </Access>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "/account-verification/:token",
    element: <VerificationLayout />,
    children: [{ index: true, element: <Verification /> }],
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);
