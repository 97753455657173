/* eslint-disable no-unused-vars */
import { routes } from "api/routes";
import { Trash, WhatsAppLogo, ZigiIcon } from "assets/images";
import axios from "axios";
import { Avatar, EllipsisTextWithTooltip } from "components";
import { useChat } from "hooks/useChat";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { formatTime, getToken } from "shared/resources";

const selectedNameClasses = {
  true: "font-semibold text-sm tracking-[0.41px]",
  false: "font-medium text-sm text-grey-800 tracking-[0.41px]",
};
// const selectedDescriptionClasses = {
//   true: "font-semibold text-xs tracking-[0.41px]",
//   false: "font-medium text-grey-700 text-xs tracking-[0.41px]",
// };

const InboxListItem = ({
  item,
  isZigiBot = false,
  filteredConversations = [],
  setFilteredConversations = null,
  setActiveConversation,
  deleteUUID = null,
  activeConversation,
  company_uuid,
}) => {
  // const [isSelected, setIsSelected] = useState(false);

  // const handleClassName = (active) => {
  //   setIsSelected(active);
  // };

  const navigate = useNavigate();

  const { messages } = useChat();
  const [showModal, setShowModal] = useState(false);

  const deleteConversation = async () => {
    // console.log('companyUuid', company_uuid)
    try {
      await axios.delete(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.DELETE_CONVERSATION.URL}/${deleteUUID}`,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": company_uuid,
          },
        }
      );
      const clonedConversations = [...filteredConversations];
      const conversationIdx = clonedConversations.findIndex(
        (c) => c.uuid === deleteUUID
      );
      clonedConversations.splice(conversationIdx, 1);
      setFilteredConversations(clonedConversations);
      setShowModal(false);
      if (activeConversation && deleteUUID === activeConversation) {
        navigate("/live-chat/0");
      }
    } catch (error) {
      console.error("Error leaving conversation:", error);
    }
  };

  useEffect(() => {
    if (filteredConversations.length) {
      const clonedConversations = [...filteredConversations];
      const conversationIdx = clonedConversations.findIndex(
        (conv) => conv.uuid === item.uuid
      );
      let lastIndex = -1;
      for (let i = messages.length - 1; i >= 0; i--) {
        if (
          messages[i].conversation_uuid &&
          messages[i].conversation_uuid === item.uuid
        ) {
          lastIndex = i;
          break;
        }
      }
      if (lastIndex >= 0) {
        clonedConversations[conversationIdx].metadata.lastMessage =
          messages[lastIndex].metadata.body;
        setFilteredConversations(clonedConversations);
      }
    }
  }, [messages]);

  return (
    <NavLink
      onClick={() => {
        if (item.role === "responder" && setActiveConversation) {
          setActiveConversation(item.uuid);
        }
      }}
      to={`${
        isZigiBot
          ? item.role === "responder"
            ? `/live-chat/${item.uuid}`
            : `/live-chat/zigi`
          : `/live-chat/${item}`
      }`}
      className={({ isActive }) =>
        `p-4 flex justify-between items-center cursor-pointer hover:bg-grey-300 ${
          isActive ? "bg-grey-300" : ""
        }`
      }
    >
      <div className="flex gap-3 w-full">
        <span className="flex items-start relative">
          {isZigiBot ? (
            item.role === "responder" ? (
              <Avatar name={`${item.displayName}`} />
            ) : (
              <ZigiIcon />
            )
          ) : (
            <Avatar name={`${item} Deanna Curtis`} />
          )}
          {isZigiBot ? (
            <div className="absolute -bottom-1 right-0">
              <ZigiIcon className="w-[22px] h-[22px]" />
            </div>
          ) : (
            <div className="absolute -bottom-1 right-0">
              <WhatsAppLogo className="w-[22px] h-[22px]" />
            </div>
          )}
        </span>
        <div className="flex justify-between items-center w-full relative">
          <span className="flex items-start flex-col gap-1">
            <span
              className={selectedNameClasses[isZigiBot ? "true" : "false"]}
              style={isZigiBot && item?.unread ? { fontWeight: "bold" } : {}}
            >
              {isZigiBot
                ? item.role === "responder"
                  ? item.displayName
                  : "Zigi Bot"
                : "Deanna Curtis"}
            </span>
            <span
              className={selectedNameClasses[isZigiBot ? "true" : "false"]}
              style={isZigiBot && item?.unread ? { fontWeight: "bold" } : {}}
            >
              <EllipsisTextWithTooltip
                withTooltip={false}
                charLength={30}
                string={
                  isZigiBot
                    ? item.role === "responder"
                      ? `${item.metadata?.lastMessage || "No message yet"}`
                      : "How can I assist you today?"
                    : "Please I would like an update on this as soon as possible"
                }
              />
            </span>
          </span>
          {isZigiBot && !item.unread && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              className="cursor-pointer"
            >
              <Trash className="text-danger" />
            </button>
          )}
        </div>
      </div>

      {isZigiBot && item?.unread && (
        <span className="flex items-end flex-col gap-1">
          <span className="text-grey-600 font-medium text-xs">
            {/* {formatTime(messages?.created_at)} */}
          </span>
          <div className="flex justify-center items-center gap-2.5">
            <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
              1
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              className="cursor-pointer"
            >
              <Trash className="text-danger" />
            </button>
          </div>
        </span>
      )}
      {!isZigiBot && (
        <span className="flex items-end flex-col gap-1">
          <span className="text-grey-600 font-medium text-xs">
            {formatTime(messages?.created_at)}
          </span>
          <div className="flex justify-center items-center gap-2.5">
            <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
              100
            </div>
            {/* <span className="bg-green text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ">
              1
            </span> */}
            <span>
              <Trash className="text-danger" />
            </span>
          </div>
        </span>
      )}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={() => setShowModal(false)}
          />

          <div className="relative bg-white rounded-lg w-[400px] p-6">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              ✕
            </button>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">
                Delete Conversation
              </h3>
              <p className="text-grey-600">
                Are you sure you to want to delete this conversation? This
                action cannot be undone.
              </p>
            </div>

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 text-sm text-gray-600 border border-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={deleteConversation}
                className="px-4 py-2 text-sm text-white bg-danger hover:bg-red-700 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </NavLink>
  );
};

export default InboxListItem;
