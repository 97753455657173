const Center = ({ message }) => {
  return (
    <div className="flex justify-center items-center mt-4">
      <div className="text-sm font-normal text-black bg-grey-400 px-4 py-2 rounded">
        {message?.metadata?.body}
      </div>
    </div>
  );
};

export default Center;
