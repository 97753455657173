import { X } from "assets/images";

import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import ActiveCallScreen from "./ActiveCallScreen";
import Digits from "./Digits";
import DialerUpperScreen from "./DialerUpperScreen";
import axios from "axios";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";
import { Loader2 } from "lucide-react";
import Select from "components/Select";
import Input from "components/Input";
import Button from "components/Button";

const Dialer = ({
  openDialpad = () => null,
  callActiveKey = "",
  callSessions = [],
  action,
  setAction = () => null,
}) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { _makeCall = () => null } = socket;
  const [phoneNumber, setPhoneNumber] = useState("");
  const inputRef = useRef(null);
  const [isDispositionOpen, setIsDispositionOpen] = useState(false);
  const [countdown, setCountdown] = useState(
    parseInt(process.env.REACT_APP_AFTER_CALL_TIMEOUT)
  );
  const [isLoading, setIsLoading] = useState(false);

  const isCallExist = callSessions.length > 0;
  const activeCallData =
    callSessions.filter((call) => call?.callID === callActiveKey)?.[0] || {};

  useEffect(() => {
    if (!isCallExist && inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, []);

  const appendPhoneNumber = (key) => {
    inputRef?.current?.focus();
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + key);
  };

  const handleMakeCall = () => {
    if (phoneNumber?.length) {
      _makeCall(phoneNumber, phoneNumber);
    }
  };

  const connected = [
    { uuid: 1, title: "Yes" },
    { uuid: 2, title: "No" },
  ];
  const connected2 = [
    { uuid: 1, title: "Yes" },
    { uuid: 2, title: "No" },
  ];
  const promiseStat = [
    { uuid: 1, title: "Yes" },
    { uuid: 2, title: "No" },
  ];
  const numbers = [
    { uuid: 1, number: 1 },
    { uuid: 2, number: 2 },
    { uuid: 3, number: 3 },
    { uuid: 4, number: 4 },
    { uuid: 5, number: 5 },
    { uuid: 6, number: 6 },
    { uuid: 7, number: 7 },
    { uuid: 8, number: 8 },
    { uuid: 9, number: 9 },
    { uuid: 10, number: 0 },
  ];
  const numbers2 = [
    { uuid: 1, number: 1 },
    { uuid: 2, number: 2 },
    { uuid: 3, number: 3 },
    { uuid: 4, number: 4 },
    { uuid: 5, number: 5 },
    { uuid: 6, number: 6 },
    { uuid: 7, number: 7 },
    { uuid: 8, number: 8 },
    { uuid: 9, number: 9 },
    { uuid: 10, number: 0 },
  ];
  const reasons = [
    { uuid: 1, reason: "Financial Issues" },
    { uuid: 2, reason: "Bank Issues" },
    { uuid: 3, reason: "Waiting for salary" },
    { uuid: 4, reason: "Sick, hospitalized" },
    { uuid: 5, reason: "Unable to pay(payment issues)" },
    { uuid: 6, reason: "Doesn't know how to pay" },
    { uuid: 7, reason: "Travelled to remote areas" },
    { uuid: 8, reason: "Lost/damage phone" },
    { uuid: 9, reason: "Death" },
    { uuid: 10, reason: "Others" },
  ];
  const comments = [
    { uuid: 1, title: "No answer" },
    { uuid: 2, title: "Switched off" },
    { uuid: 3, title: "Barred" },
    { uuid: 4, title: "Busy" },
    { uuid: 5, title: "Hung up" },
    { uuid: 6, title: "Mute" },
    { uuid: 7, title: "No commitment" },
    { uuid: 8, title: "No precise amount" },
    { uuid: 9, title: "No precise date" },
    { uuid: 10, title: "Paid" },
    { uuid: 11, title: "Payment not updated" },
    { uuid: 12, title: "Loan not disbursed" },
    { uuid: 13, title: "Third party contact" },
    { uuid: 14, title: "Deceased" },
    { uuid: 16, title: "Wrong number" },
    { uuid: 17, title: "No knowledge of loan" },
    { uuid: 18, title: "Language barrier" },
    { uuid: 19, title: "PTP next week" },
    { uuid: 20, title: "PTP month end" },
    { uuid: 21, title: "Others" },
  ];

  const [connectedValue, setConnectedValue] = useState();
  const [rightPartyContact, setRightPartyContact] = useState();
  const [noOfTimesConnected, setNoOfTimesConnected] = useState();
  const [noOfTimesCalled, setNoOfTimesCalled] = useState();
  const [deliquencyReason, setDeliquencyReason] = useState();
  const [pipDate, setPipDate] = useState();
  const [pipAmount, setPipAmount] = useState();
  const [comment, setComment] = useState();
  const [promiseToPay, setPromiseToPay] = useState();
  const [lockDialPad, setLockDialPad] = useState(false);

  const [isFormActive, setIsFormActive] = useState(false);

  useEffect(() => {
    const isAnyFieldActive =
      connectedValue ||
      rightPartyContact ||
      promiseToPay ||
      noOfTimesConnected ||
      noOfTimesCalled ||
      deliquencyReason ||
      pipDate ||
      pipAmount ||
      comment;

    setIsFormActive(!!isAnyFieldActive);
  }, [
    connectedValue,
    rightPartyContact,
    promiseToPay,
    noOfTimesConnected,
    noOfTimesCalled,
    deliquencyReason,
    pipDate,
    pipAmount,
    comment,
  ]);

  const handleUnload = () => {
    localStorage.removeItem("isDispositionOpen");
    const notesObj = {
      callID: activeCallData?.callID,
      connected: connectedValue,
      rightPartyContact,
      noOfTimesConnected,
      noOfTimesCalled,
      deliquencyReason,
      pipDate,
      promiseToPay,
      pipAmount,
      comment,
    };
    if (Object.keys(notesObj).length > 1) {
      axios.post(
        `${process.env.REACT_APP_BASE_API_URL}did/submit-notes`,
        {
          ...notesObj,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
    }
  };

  const saveToLocalStorage = () => {
    localStorage.setItem(
      activeCallData?.callID,
      JSON.stringify({
        callID: activeCallData?.callID,
        connected: connectedValue,
        rightPartyContact,
        promiseToPay,
        noOfTimesConnected,
        noOfTimesCalled,
        deliquencyReason,
        pipDate,
        pipAmount,
        comment,
      })
    );
  };

  const handleFormSubmit = () => {
    setIsLoading(true);
    try {
      saveToLocalStorage();

      toastEmitter("success", "Disposition saved successfully!");

      resetFormFields();
    } catch (error) {
      toastEmitter("error", "An error occurred while saving data.");
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormFields = () => {
    setConnectedValue(null);
    setRightPartyContact(null);
    setNoOfTimesConnected(null);
    setNoOfTimesCalled(null);
    setDeliquencyReason(null);
    setPipDate(null);
    setPromiseToPay(null);
    setPipAmount(null);
    setComment(null);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("isDispositionOpen", isDispositionOpen);
    if (!isCallExist && isDispositionOpen) {
      setLockDialPad(true);
      const timeout = setTimeout(() => {
        setIsDispositionOpen(false);
        openDialpad(false);
      }, parseInt(process.env.REACT_APP_AFTER_CALL_TIMEOUT) * 1000);
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }
  }, [isCallExist, isDispositionOpen]);

  return (
    <div className="fixed inset-0 bg-black/10 bg-opacity-10 z-50 flex justify-center items-center h-screen flex-col">
      <div className="xxl:min-h-[656px] xl:min-h-[561px] lg:min-h-[540px] md:min-h-[540px] xxl:max-h-[656px] xl:max-h-[561px] lg:max-h-[540px] md:max-h-[540px] bg-black bg-opacity-85 flex flex-col items-center justify-center rounded-lg relative">
        <div className="flex justify-end items-center p-4 pb-0">
          <button
            type="button"
            className="absolute right-4 top-2"
            onClick={() => {
              if (lockDialPad) return;
              localStorage.removeItem("isDispositionOpen");
              openDialpad(false);
            }}
          >
            <X className="text-grey-600 w-6 h-6" />
          </button>
        </div>
        <div className=" xxl:w-96 xl:w-80 lg:w-80 md:w-80 mt-4">
          <div>
            <DialerUpperScreen
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              callSessions={callSessions}
              handleMakeCall={handleMakeCall}
              inputRef={inputRef}
              action={action}
              callActiveKey={callActiveKey}
              activeCallData={activeCallData}
            />
            {!isCallExist ? (
              <>
                <Digits
                  lockDialPad={lockDialPad}
                  onPresshandler={appendPhoneNumber}
                  handleMakeCall={handleMakeCall}
                />
              </>
            ) : (
              <ActiveCallScreen
                action={action}
                setAction={setAction}
                activeCallData={activeCallData}
                setIsDispositionOpen={setIsDispositionOpen}
              />
            )}
          </div>
        </div>
      </div>
      {isDispositionOpen && (
        <div className="fixed inset-0 flex justify-end bg-black/10 bg-opacity-10 -z-20 ">
          <div
            className="bg-black bg-opacity-80 h-screen overflow-auto w-1/3 shadow-lg backdrop-blur-md z-50 p-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="my-6 flex w-full items-center justify-between">
              {!isCallExist && (
                <p className="font-bold text-[30px] text-white">{countdown}</p>
              )}

              <button
                className={`text-gray-500 ml-auto hover:text-gray-800 ${
                  isCallExist ? "flex" : "hidden"
                }`}
                onClick={() => setIsDispositionOpen(false)}
              >
                <X className="text-white" />
              </button>
            </div>
            <div className="flex flex-col gap-2">
              <div>
                <p className="text-green">Connected</p>
                <Select
                  options={connected}
                  value={connectedValue}
                  onChange={(event) => {
                    setConnectedValue(event.target.value);
                  }}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  )}
                />
              </div>
              <div>
                <p className="text-green">Promise to pay</p>
                <Select
                  options={promiseStat}
                  value={promiseToPay}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  )}
                  onChange={(event) => {
                    setPromiseToPay(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">Right party contact</p>
                <Select
                  options={connected2}
                  value={rightPartyContact}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  )}
                  onChange={(event) => {
                    setRightPartyContact(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">No. of times connected</p>
                <Select
                  options={numbers}
                  value={noOfTimesConnected}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.number}
                    >
                      {item.number}
                    </option>
                  )}
                  onChange={(event) => {
                    setNoOfTimesConnected(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">No. of times called</p>
                <Select
                  options={numbers2}
                  value={noOfTimesCalled}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.number}
                    >
                      {item.number}
                    </option>
                  )}
                  onChange={(event) => {
                    setNoOfTimesCalled(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">Reason for delinquency</p>
                <Select
                  options={reasons}
                  value={deliquencyReason}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.reason}
                    >
                      {item.reason}
                    </option>
                  )}
                  onChange={(event) => {
                    setDeliquencyReason(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">PTP Date</p>
                <Input
                  extraClasses="bg-transparent focus:bg-transparent text-white mt-2"
                  type="date"
                  value={pipDate}
                  onChange={(event) => {
                    setPipDate(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">PIP Amount</p>
                <Input
                  extraClasses="bg-transparent focus:bg-transparent text-white mt-2"
                  type="text"
                  value={pipAmount}
                  onChange={(event) => {
                    setPipAmount(event.target.value);
                  }}
                />
              </div>
              <div>
                <p className="text-green">Comment</p>
                <Select
                  options={comments}
                  value={comment}
                  extraClasses="mt-2 text-white bg-transparent focus:bg-transparent"
                  renderOption={({ item }) => (
                    <option
                      className="bg-black bg-opacity-30 text-white"
                      key={item.uuid}
                      value={item.title}
                    >
                      {item.title}
                    </option>
                  )}
                  onChange={(event) => {
                    setComment(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="mt-6">
              <Button disabled={!isFormActive} onClick={handleFormSubmit}>
                {isLoading ? <Loader2 className="animate-spin" /> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dialer;
