/* eslint-disable no-unused-vars */
import { MenuIcon, PhoneCall, Prohibit, UserList, Trash } from "assets/images";
import { Avatar, Button, Dropdown, Modal } from "components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import { LogOut } from "lucide-react";

const Header = ({
  chatId = "",
  conversation,
  endConversation,
  needToJoinRoom,
  leaveRoom,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);

  const getModalContent = () => {
    switch (modalAction) {
      case "leave":
        return {
          title: "Leave Room",
          message:
            "Are you sure you want to leave this room? You will no longer have access to this conversation.",
        };
      case "end":
        return {
          title: "End Conversation",
          message:
            "Are you sure you want to end this conversation? This action cannot be undone.",
        };
      default:
        return {
          title: "Confirm Action",
          message: "Are you sure you want to proceed?",
        };
    }
  };

  const handleConfirm = () => {
    switch (modalAction) {
      case "leave":
        leaveRoom();
        break;
      case "end":
        endConversation();
        break;
    }
    setShowModal(false);
  };

  const getDropdownItems = () => {
    const baseItems = [
      {
        id: 1,
        title: "View contact details",
        icon: <UserList className="text-grey-700 w-5 h-5" />,
      },
    ];
    if (
      chatId !== "Zigi Bot" &&
      !needToJoinRoom &&
      conversation?.metadata?.status === "active"
    ) {
      return [
        ...baseItems,
        {
          id: 2,
          title: "Leave Room",
          icon: <LogOut className="text-grey-700 w-[18px] h-[18px]" />,
          action: "leave",
        },
        {
          id: 3,
          title: <p className="text-danger">End Conversation</p>,
          icon: <Prohibit className="text-danger w-[18px] h-[18px]" />,
          action: "end",
        },
      ];
    }

    return baseItems;
  };

  if (!chatId) return "Zigi";

  return (
    <>
      <div className="flex items-center gap-3">
        {chatId !== "Zigi Bot" && (
          <div className="relative">
            <Avatar
              name={`${
                chatId === "0"
                  ? "Deanna Curtis"
                  : conversation?.metadata?.company_name ||
                    conversation?.metadata?.name ||
                    chatId
              }`}
            />
          </div>
        )}

        <div>
          <div className={"font-medium text-sm tracking-[0.41px]"}>
            {chatId === "0"
              ? "Deanna Curtis"
              : conversation?.metadata?.company_name ||
                conversation?.metadata?.name ||
                chatId}
          </div>
          {chatId !== "Zigi Bot" && conversation?.metadata?.phone && (
            <div
              className={"font-medium text-sm text-grey-600  tracking-[0.41px]"}
            >
              +234 78 48 4584 878
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4 pr-2">
        <div>{conversation?.metadata?.status}</div>
        <div>
          <PhoneCall className="text-green" />
        </div>
        {/* <div>
          {chatId !== "Zigi Bot" &&
            !needToJoinRoom &&
            conversation?.metadata?.status === "active" && (
              <div className="flex items-center gap-2">
                <Trash
                  className="text-red-700 w-[18px] h-[18px]"
                  onClick={endConversation}
                />
                <div className="w-[100px]">
                  <Button className="cursor-pointer" onClick={leaveRoom}>
                    Leave Room
                  </Button>
                </div>
              </div>
            )}
        </div> */}
        <div>
          <Dropdown
            menuWidth="w-max"
            closeOnClickOutside={true}
            dropList={{
              component: ({ item }) => {
                const Icon = item?.icon ?? null;
                return (
                  <span
                    className="w-full h-full flex justify-start gap-2 items-center text py-2 px-3 cursor-pointer"
                    onClick={() => {
                      if (item.action) {
                        setModalAction(item.action);
                        setShowModal(true);
                      }
                    }}
                  >
                    {Icon}
                    <div className="text-sm text-grey-900">{item?.title}</div>
                  </span>
                );
              },
              data: getDropdownItems(),
            }}
            showcaret={false}
          >
            <div className="cursor-pointer w-1">
              <MenuIcon />
            </div>
          </Dropdown>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={() => setShowModal(false)}
          />

          <div className="relative bg-white rounded-lg w-[400px] p-6">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              ✕
            </button>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">
                {getModalContent().title}
              </h3>
              <p className="text-grey-600">{getModalContent().message}</p>
            </div>

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 text-sm text-gray-600 border border-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                className="px-4 py-2 text-sm text-white bg-danger hover:bg-red-700 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
