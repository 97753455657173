import React, { useEffect, useState } from "react";
import { useUpdateContactList } from "hooks";
import { Controller, useForm } from "react-hook-form";
import Spinner from "components/Spinner";
import Input from "components/Input";
import { Trash, Plus } from "assets/images";
import { useTranslation } from "react-i18next";
import useUpdateContactWithoutBatch from "hooks/useUpdateContactWithoutBatch";

function ContactDetails({
  handleDetailsModalClose,
  contact,
  mutate,
  withoutBatch = false,
}) {
  const {
    isPending: updateContactListLoad,
    mutateAsync: updateContactListMutate,
  } = useUpdateContactList();

  const {
    isPending: updateContactListWithoutBatchLoad,
    mutateAsync: updateContactListWihoutBatchMutate,
  } = useUpdateContactWithoutBatch();

  const [additionalFields, setAdditionalFields] = useState([]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      uuid: contact?.uuid ?? "",
      first_name: contact?.first_name ?? "",
      last_name: contact?.last_name ?? "",
      phone: contact?.phone ?? "",
      email: contact?.email ?? "",
      address: contact?.address ?? "",
      company_name: contact?.company_name ?? "",
      postal_code: contact?.postal_code ?? "",
      city: contact?.city ?? "",
      state: contact?.state ?? "",
      street: contact?.street ?? "",
      country: contact?.country ?? "",
      job_title: contact?.job_title ?? "",
      additional_details: contact?.additional_details ?? "",
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (contact && Object.keys(contact).length > 0) {
      setValue("uuid", contact?.uuid);
      setValue("first_name", contact?.first_name);
      setValue("last_name", contact?.last_name);
      setValue("email", contact?.email);
      setValue("phone", contact?.phone);
      setValue("address", contact?.address);
      setValue("company_name", contact?.company_name);
      setValue("postal_code", contact?.postal_code);
      setValue("city", contact?.city);
      setValue("state", contact?.state);
      setValue("country", contact?.country);
      setValue("job_title", contact?.job_title);
      setValue("street", contact?.street);

      if (contact.additional_details) {
        const theAdditionalDetails = contact.additional_details;
        setAdditionalFields(theAdditionalDetails);
      }
    }
  }, [contact, setValue]);

  const onSubmit = async (values) => {
    const updatedValues = {
      ...values,
      additional_details: JSON.stringify(additionalFields),
    };

    {
      withoutBatch
        ? await updateContactListWihoutBatchMutate(updatedValues)
        : await updateContactListMutate(updatedValues);
    }
    {
      withoutBatch &&
        mutate({
          page: 1,
          limit: 1000,
        });
    }
    handleDetailsModalClose();
  };

  const { t } = useTranslation();

  return (
    <div>
      <h2 className="text-[20px] font-bold text-center mb-6">
        {t("overview.contactDetails")}
      </h2>
      <form
        className="flex flex-col gap-4 my-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.firstName")}
                maxLength={50}
                error={errors?.first_name?.message}
                required={true}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.lastName")}
                maxLength={50}
                error={errors?.last_name?.message}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.phoneNumber")}
                maxLength={15}
                error={errors?.phone?.message}
                required={true}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.email")}
                error={errors?.email?.message}
              />
            )}
          />
        </div>

        <div className="flex flex-col gap-3 w-full text-[16px]">
          <p className="text-[18px] py-3 font-bold">
            {t("overview.residentAddress")}
          </p>
          <div>
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.residentAddress")}
                  error={errors?.street?.message}
                  maxLength={50}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.city")}
                  error={errors?.city?.message}
                  maxLength={50}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.state")}
                  error={errors?.state?.message}
                  maxLength={50}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="postal_code"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.postalCode")}
                  error={errors?.postal_code?.message}
                  maxLength={50}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.country")}
                  error={errors?.country?.message}
                  maxLength={50}
                />
              )}
            />
          </div>
        </div>

        <p className="text-[18px] py-3 font-bold">
          {t("overview.workDetails")}
        </p>

        <div className="flex flex-col gap-3 w-full text-[16px]">
          <div>
            <Controller
              name="company_name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.company")}
                  placeholder={t("overview.enterCompany")}
                  error={errors?.company_name?.message}
                  maxLength={50}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Controller
            name="job_title"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg={true}
                label={t("overview.jobTitle")}
                error={errors?.job_title?.message}
                maxLength={50}
              />
            )}
          />
        </div>

        <div className="flex justify-between w-full items-center">
          <p className="text-[18px] py-3 font-bold">
            {t("overview.additionalDetails")}
          </p>
          <button
            onClick={(event) => {
              event.preventDefault();
              const clonedAdditionalFields = [...additionalFields];
              clonedAdditionalFields.push({ key: "", value: "" });
              setAdditionalFields(clonedAdditionalFields);
            }}
            className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
          >
            <Plus className="h-[20px] w-[20px]" />
            {t("overview.addField")}
          </button>
        </div>
        {additionalFields.map((field, index) => {
          return (
            <div key={index} className="flex gap-3">
              <div className="w-[40%]">
                <Input
                  onWhitebg={true}
                  label={t("overview.title")}
                  maxLength={50}
                  placeholder={t("overview.title")}
                  value={field.key}
                  onChange={(event) => {
                    const clonedAdditionalFields = [...additionalFields];
                    clonedAdditionalFields[index].key =
                      event.target.value.trim();
                    setAdditionalFields(clonedAdditionalFields);
                  }}
                />
              </div>
              <div className="w-[60%] relative">
                <Input
                  {...field}
                  onWhitebg={true}
                  label={t("overview.content")}
                  maxLength={50}
                  placeholder={t("overview.content")}
                  value={field.value}
                  onChange={(event) => {
                    const clonedAdditionalFields = [...additionalFields];
                    clonedAdditionalFields[index].value =
                      event.target.value.trim();
                    setAdditionalFields(clonedAdditionalFields);
                  }}
                />
                <div
                  className="absolute top-[3px] right-0 cursor-pointer"
                  onClick={() => {
                    const clonedAdditionalFields = [...additionalFields];
                    clonedAdditionalFields.splice(index, 1);
                    setAdditionalFields(clonedAdditionalFields);
                  }}
                >
                  <Trash className="w-[20px] h-[20px]" />
                </div>
              </div>
            </div>
          );
        })}

        <div className="flex gap-4 ml-auto mt-6 justify-end items-center">
          <button
            onClick={handleDetailsModalClose}
            className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
          >
            {t("overview.close")}
          </button>
          {withoutBatch ? (
            <button
              type="submit"
              className="bg-[#4F772D] w-[100px] h-[40px] rounded flex items-center justify-center"
            >
              {updateContactListWithoutBatchLoad ? (
                <Spinner />
              ) : (
                `${t("overview.save")}`
              )}
            </button>
          ) : (
            <button
              type="submit"
              className="bg-[#4F772D] w-[100px] h-[40px] rounded flex items-center justify-center"
            >
              {updateContactListLoad ? <Spinner /> : `${t("overview.save")}`}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default ContactDetails;
