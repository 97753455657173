import { Button } from "components";
import React from "react";
import { Link } from "react-router-dom";

function ComingSoon() {
  return (
    <div className="h-full flex flex-col items-center justify-center space-y-4">
      <img src="/rocket.png" width={96} height={96} alt="rocket" />
      <h2 className="text-xl mt-4 font-medium">Coming soon</h2>
      <Link to="/" className="w-[150px]">
        <Button>Go Home</Button>
      </Link>
    </div>
  );
}

export default ComingSoon;
