// import {
//   NoRecord,
//   Plus,
//   WhatsappBusiness,
//   BotsTree,
//   Omni,
//   Coffee,
// } from "assets/images";
// import {
//   ChevronLeft,
//   ChevronRight,
//   Copy,
//   Edit,
//   Eye,
//   MoreVertical,
//   PenLine,
//   Trash2,
// } from "lucide-react";
// import { Button, Input } from "components";
// import React, { useState } from "react";
// import StepOne from "./StepOne";
// import StepTwo from "./StepTwo";
// import useFacebookChannel from "hooks/useFacebookChannel";
// import { useNavigate, useSearchParams } from "react-router-dom";
import ComingSoon from "components/ComingSoon";

// function WhatsApp() {
//   const { launchWhatsAppSignup } = useFacebookChannel({
//     appId: "9343719928976278",
//     graphApiVersion: "v21.0",
//     configId: "490887133306473",
//   });

//   const [whatsappConfig, setWhatsappConfig] = useState(
//     localStorage.getItem("whatsapp")
//       ? JSON.parse(localStorage.getItem("whatsapp"))
//       : null
//   );

//   const [searchParams] = useSearchParams();

//   const navigate = useNavigate();

//   const EmptyBot = () => {
//     return (
//       <div className="flex flex-col gap-6 justify-center items-center h-[200px] m-auto mt-20 w-[338px]">
//         <NoRecord />
//         <p className="mt-4 text-center text-black/60 text-[16px]">
//           No bots found
//         </p>
//       </div>
//     );
//   };
//   const [steps, setSteps] = useState(
//     searchParams.get("step") ? +searchParams.get("step") : 1
//   );
//   const handleNextStep = () => {
//     setSteps(steps + 1);
//   };
//   const handlePreviousStep = () => {
//     setSteps(steps - 1);
//   };

//   const [showOptions, setShowOptions] = useState(false);

//   const handleOptionsToggle = () => setShowOptions((prev) => !prev);

//   return whatsappConfig && whatsappConfig.linked ? (
//     <div>
//       {steps === 1 && (
//         <>
//           <div className="flex w-full justify-between">
//             <div className="w-[400px]">
//               <Input withSearchIcon extraClasses="pl-10" type="search" />
//             </div>
//             <div className="w-[300px]">
//               <Button onClick={handleNextStep}>
//                 <Plus />
//                 <span>Add New Bot</span>
//               </Button>
//             </div>
//           </div>

//           {!whatsappConfig.bots ? (
//             <div className="mt-8">
//               <EmptyBot />
//             </div>
//           ) : (
//             whatsappConfig.bots.map((bot, idx) => {
//               return (
//                 <div
//                   key={idx}
//                   onClick={() => {
//                     navigate("/flow");
//                   }}
//                 >
//                   <div className="mt-8">
//                     <div className="flex flex-col w-[338px] relative">
//                       <div className="h-[200px] bg-[#D2D5DA] hover:bg-[#D2D5DA]/50 cursor-pointer w-[338px] items-center flex justify-center rounded-t-lg">
//                         <BotsTree />
//                       </div>
//                       <div className="h-[44px] bg-[#0D440DCC] w-[338px]" />
//                       <div className="bg-[#F1F1F1] h-[62px] w-[338px] flex items-center justify-between rounded-b-lg px-3">
//                         <div className="flex gap-4 items-center">
//                           <Omni color="#084FA0" />
//                           <Coffee />
//                           <p className="font-bold text-[12px]">{bot}</p>
//                         </div>
//                         <div
//                           className="cursor-pointer"
//                           onClick={handleOptionsToggle}
//                         >
//                           <MoreVertical size={15} />
//                         </div>
//                         {showOptions && (
//                           <div className="absolute py- flex flex-col justify-center items-center gap-2 text-base top-4 h-[231px] right-2 bg-white rounded shadow-md mt-2 w-[240px] z-10">
//                             {/* <div className=""> */}
//                             <p className="text-blackß gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
//                               <Eye size={18} />
//                               <span>Open Flow</span>
//                             </p>
//                             <p className="text-black gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
//                               <Edit size={18} />
//                               Edit Flow
//                             </p>
//                             <p className="text-black gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
//                               <Copy size={18} />
//                               Copy Flow
//                             </p>
//                             <p className="text-black gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
//                               <PenLine size={18} />
//                               Rename
//                             </p>
//                             <p className="text-[#EF4444] gap-2 flex items-center cursor-pointer hover:bg-grey-400 w-full py-2 px-2">
//                               <Trash2 size={18} /> Delete
//                             </p>
//                             {/* </div> */}
//                           </div>
//                         )}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="flex gap-4 items-center mr-auto justify-end mt-12">
//                     <p>Total 1</p>
//                     <div className="flex gap-2 items-center">
//                       <button
//                         disabled
//                         className="w-6 h-6 bg-[#F1F1F1] flex items-center justify-center"
//                       >
//                         <ChevronLeft size={14} />
//                       </button>
//                       <p className="bg-green-400 flex items-center justify-center w-6 h-6">
//                         1
//                       </p>
//                       <button
//                         disabled
//                         className="w-6 h-6 bg-[#F1F1F1] flex items-center justify-center"
//                       >
//                         <ChevronRight size={14} />
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })
//           )}
//         </>
//       )}
//       {steps === 2 && (
//         <StepOne next={handleNextStep} previous={handlePreviousStep} />
//       )}
//       {steps === 3 && <StepTwo previous={handlePreviousStep} />}
//     </div>
//   ) : (
//     <>
//       {steps === 1 && (
//         <div
//           className={`h-[309px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
//         >
//           <div className="flex flex-col gap-7">
//             <div className="flex items-center space-x-2">
//               <WhatsappBusiness className="w-8 h-8" />
//               <p className="pt-1">Whatsapp Business</p>
//             </div>
//             <div className="flex flex-col gap-6">
//               <p className="font-bold text-[#0D121D]">
//                 Connect your WhatsApp Business to your Fonu Account
//               </p>
//               <p className="text-[#474A4E] text-[14px]">
//                 We will need permission to manage your WhatsApp Business Account
//                 in order to automate your replies.
//                 <br /> You can create WhatsApp bot on connected accounts.
//               </p>
//               <div className="w-[400px]">
//                 <Button
//                   onClick={() => {
//                     launchWhatsAppSignup();
//                     localStorage.setItem(
//                       "whatsapp",
//                       JSON.stringify({ linked: true })
//                     );
//                     setWhatsappConfig({ linked: true });
//                   }}
//                 >
//                   Connect to WhatsApp Business
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }
function WhatsApp() {
  return <ComingSoon />;
}
export default WhatsApp;
