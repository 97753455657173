import { getMemberListing } from "api";
import { Avatar, Skeletons } from "components";
import { useDidListWithCache } from "hooks";
import { useAuth } from "hooks/useAuth";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formatPhoneNumber } from "shared/resources";

const loader = (
  <>
    {[...Array(3)].map((_, index) => (
      <div key={index} className="flex gap-3 items-center">
        <div className="w-9 h-9 rounded-full">
          <Skeletons type="circle" />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium text-grey-900">
            <div className="w-24">
              <Skeletons />
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

const CustomLink = ({ nav, index }) => {
  const navigate = useNavigate();
  const { chatId } = useParams();
  const { user = {} } = useAuth();

  useEffect(() => {
    if (!chatId && index === 0) {
      navigate(`/live-chat/${index}`);
    }
  }, [chatId, index, navigate]);

  return (
    <Link
      key={index}
      to={`/live-chat/${index}`}
      className={` ${
        nav?.status === 0 && "hidden"
      } xxl:text-base xl:text-base lg:text-sm md:text-sm flex items-center gap-3 p-2 rounded-md hover:bg-grey-300 cursor-pointer ${
        nav.isActive ? "bg-grey-300" : ""
      }`}
    >
      <Avatar
        name={`${nav?.first_name} ${nav?.last_name}`}
        mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
          user?.company_uuid || user?.uuid
        }/profile/${nav?.profile_pic}`}
      />
      <div className={`flex flex-col`}>
        <span className="font-semibold text-sm">{`${nav?.first_name} ${nav?.last_name}`}</span>
        <span className="text-xs font-medium text-grey-600">
          {formatPhoneNumber(nav.phone)}
        </span>
      </div>
    </Link>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isLoading } = useDidListWithCache();
  const [members, setMembers] = useState([]);
  const [mutatedRoutes, setMutatedRoutes] = useState([]);

  const getMembers = useCallback(async () => {
    try {
      const response = await getMemberListing();
      setMembers(response?.data?.data || []);
    } catch (error) {
      console.error("Failed to fetch members:", error);
    }
  }, []);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  useEffect(() => {
    const routes = members.map((item, index) => ({
      ...item,
      id: index,
      isActive: location.pathname === `/live-chat/${index}`,
    }));
    setMutatedRoutes(routes);
  }, [location.pathname, members]);

  return (
    <div className="xxl:min-w-[290px] xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] pt-7 flex flex-col border-grey-300 border-r h-full">
      <div className="font-medium text-base text-grey-600 pb-[22px] px-4">
        Inboxes
      </div>
      <div className="flex-1 overflow-y-auto px-4">
        <div className="flex flex-col gap-2.5 mb-2">
          {isLoading
            ? loader
            : mutatedRoutes.map((nav, index) => (
                <CustomLink key={index} nav={nav} index={index} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
