import React from "react";

const baseInputLabelClasses = "text-inherit text-base font-medium";

const baseSelectClasses =
  "w-full border rounded-md focus:outline-none focus:ring bg-inherit font-medium text-base text-inherit";

export default function Select({
  options = [],
  renderOption: RenderOption = () => null,
  placeholder = "",
  label = "",
  name = "",
  error = "",
  extraClasses = "",
  height = "xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]",

  ...rest
}) {
  const conditioalClasses = error
    ? "border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100"
    : "border-grey-400 focus:border-green focus:ring-green-200";

  return (
    <div>
      <div className="flex flex-col gap-2">
        {label ? (
          <label htmlFor={name} className={baseInputLabelClasses}>
            {label}
          </label>
        ) : null}
        <select
          {...rest}
          placeholder={placeholder}
          className={
            extraClasses +
            " " +
            baseSelectClasses +
            " " +
            conditioalClasses +
            " " +
            height
          }
          id={name}
        >
          <option value="">{placeholder}</option>
          {options &&
            options.length > 0 &&
            options.map((item) => (
              <RenderOption key={item?.uuid} item={item} />
            ))}
        </select>
      </div>
      {error ? (
        <small className="text-danger text-xs	font-normal">{error}</small>
      ) : null}
    </div>
  );
}
