import { routes } from "api/routes";
import { ZohoIcon } from "assets/images";
import { Button } from "components";
import React, { useEffect } from "react";
import { getToken } from "shared/resources";

const Zoho = () => {
  const CLIENT_ID = process.env.REACT_APP_ZOHO_CLIENT_ID;
  const BACKEND_URL = process.env.REACT_APP_BASE_API_URL;
  const REDIRECT_URI = process.env.REACT_APP_ZOHO_REDIRECT_URI;

  const handleAuthorize = () => {
    const authUrl = `https://accounts.zoho.com/oauth/v2/auth?scope=zohocontacts.contactapi.ALL&client_id=${CLIENT_ID}&response_type=code&access_type=online&redirect_uri=${REDIRECT_URI}`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      fetch(`${BACKEND_URL}${routes.CREATE_ZOHO_INTEGRATION.URL}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access_token) {
            console.log("data:", data);
          } else {
            console.log("data:", data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);
  return (
    <div
      className={`h-[236px] bg-[#F1F1F1] shadow-md flex flex-col justify-center px-6`}
    >
      <div className="flex flex-col gap-7">
        <div className="flex items-center space-x-2">
          <ZohoIcon className="w-10 h-10" />
          <p className="">Zoho</p>
        </div>
        <div className="flex flex-col gap-6">
          <p className="text-[#0D121D]">
            You can connect to CRM account to use CRM Zoho actions in the flow.
          </p>

          <div className="w-[400px]">
            <Button onClick={handleAuthorize}>Connect CRM Account</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zoho;
