/* eslint-disable no-unused-vars */
import { ZigiIcon } from "assets/images";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "hooks/useAuth";
import Left from "./Left";
import Right from "./Right";
import Center from "./Center";

const MessageBoard = ({
  chatId = "",
  messages = [],
  isZigiBot = false,
  setMessages,
}) => {
  const [isFirstMessage, setIsFirstMessage] = useState(true);
  const [messageBoard, setMessageBoard] = useState(messages);
  const { user, zigiSocket } = useAuth();
  const conversationUuid = localStorage.getItem("conversation_uuid");

  useEffect(() => {
    setMessageBoard(messages);
  }, [messages]);

  useEffect(() => {
    const handleNewMessage = (message) => {
      if (message.conversation_uuid === conversationUuid) {
        setMessages((prev) => [...prev, message]);
      }
    };

    zigiSocket.on("newMessage", handleNewMessage);

    return () => {
      zigiSocket.off("newMessage", handleNewMessage);
    };
  }, [conversationUuid]);

  useEffect(() => {
    if (messages.length > 0 && messages[0].from === "zigi") {
      setIsFirstMessage(false);
    }
  }, [messages]);

  const renderMessage = (message, index) => {
    const isJoinMessage = message?.metadata?.body?.includes("join");
    const isLeaveMessage = message?.metadata?.body?.includes("left");
    const isEndMessage = message?.metadata?.body?.includes("end");

    if (isJoinMessage || isLeaveMessage || isEndMessage) {
      return <Center message={message} key={index} />;
    }
    if (message.from === "zigi") {
      return <Left message={message} key={index} />;
    }
    if (
      message.channel === "live-chat" &&
      message.from !== user.company_uuid &&
      message.from !== user.uuid &&
      message.from !== user.phone
    ) {
      return <Left message={message} key={index} />;
    }
    if (message?.from?.startsWith("234")) {
      return <Left message={message} key={index} />;
    }
    return <Right message={message} key={index} />;
  };

  const messageEndRef = useRef(null);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>
      <div className="text-xs text-grey-600 text-center">Today</div>

      {messageBoard.map((message, index) => {
        return renderMessage(message, index);
      })}
      <div ref={messageEndRef} />
    </>
  );
};

export default MessageBoard;
