/* eslint-disable no-unused-vars */
import { Chevron, InfoGrey, Refresh, Search } from "assets/images";
import AddNewContact from "components/AddNewContact";
import ImportCSV from "components/ImportCSV";
import { useAuth } from "hooks/useAuth";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Download,
  PhoneCall,
  SortDesc,
  Trash,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dropdownActions,
  initialModalState,
} from "pages/PhoneNumbers/constants";
import AssignToModal from "pages/PhoneNumbers/AssignToModal";
import { Avatar, Spinner } from "components";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import { formatPhoneNumber, getToken, maskNumber } from "shared/resources";
import ContactDetails from "components/ContactDetails";
import useContactListWithoutBatch from "hooks/useGetContactListWithoutBatch";
import useDeleteContactWithoutBatch from "hooks/useDeleteContactWithoutBatch";
import axios from "axios";
import { routes } from "api/routes";
import { toastEmitter } from "components/Toast";

const DialContacts = () => {
  const [showAddOptions, setAddOptions] = useState(false);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [search, setSearch] = useState("");
  const [, setPage] = useState(1);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const {
    data: contactData,
    isError,
    mutate,
    isPending,
  } = useContactListWithoutBatch();
  const { mutateAsync: deleteContactMutate } = useDeleteContactWithoutBatch();
  const [showModal, setShowModal] = useState("");
  const [showCSVModal, setCSVModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleAddOptions = () => setAddOptions(!showAddOptions);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(1000);
  const [contactList, setContactList] = useState({ all: [] });
  const [activeContact, setActiveContact] = useState(null);
  const [showDeleteModal, setDeleteModal] = useState("");
  const [isAlphabeticallySorted, setIsAlphabeticallySorted] = useState(false);

  const obj = useContext(SipSocketContext);
  const { _makeCall = () => null } = obj.socket;

  const handleCreateNewContact = () => {
    setShowModal("newContact");
    setAddOptions(false);
  };
  const handleImportCSV = () => {
    setCSVModal(true);
    setAddOptions(false);
  };
  const handleCSVModalClose = () => {
    setCSVModal(false);
  };
  const handleModalClose = () => {
    setShowModal("");
  };
  const handleDetailsModalClose = () => {
    setActiveContact(null);
  };
  const handleDeleteModal = () => {
    setDeleteModal("deleteContacts");
    setAddOptions(false);
  };
  useEffect(() => {
    mutate({
      page: currentPage,
      limit: perPage,
      search,
    });
  }, [search, currentPage, perPage]);
  const handleRefresh = () => {
    mutate({
      page: currentPage,
      limit: perPage,
      search,
    });
  };

  const handleContactDetails = (contact) => {
    setActiveContact(contact);
    setAddOptions(false);
  };
  useEffect(() => {
    if (
      !isAlphabeticallySorted &&
      contactData &&
      Array.isArray(contactData.data?.data)
    ) {
      const contactList = {
        all: contactData.data.data,
      };
      setContactList(contactList);
    }
  }, [contactData, isAlphabeticallySorted]);

  const handleSort = () => {
    setIsAlphabeticallySorted(!isAlphabeticallySorted);
    if (!isAlphabeticallySorted && Array.isArray(contactData?.data?.data)) {
      sortContactsAlphabetically();
    } else {
      setContactList({ all: contactData?.data?.data || [] });
    }
  };

  const sortContactsAlphabetically = () => {
    if (contactData?.data?.data) {
      const sortedContacts = {};
      const sorted = [...contactData.data.data].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      sorted.forEach((contact) => {
        const key = contact.first_name.charAt(0).toUpperCase();
        if (!sortedContacts[key]) {
          sortedContacts[key] = [];
        }
        sortedContacts[key].push(contact);
      });
      setContactList(sortedContacts);
    }
  };
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  };
  const handleExportContactBatches = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.EXPORT_CONTACT_WITHOUT_BATCH.URL}`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "contacts-without-batches.csv";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        console.error("error from exporting contacts", error);
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };
  const Pagination = () => {
    const { currentPage, lastPage, nextPage, prevPage } =
      contactData?.data?.pagination || {};

    const handleClickPage = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const renderPageNumbers = () => {
      const pageNumbers = [];
      const maxPagesToShow = 4;

      if (lastPage <= maxPagesToShow) {
        for (let i = 1; i <= lastPage; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handleClickPage(i)}
              className={`px-2 py-1 mx-1 ${
                currentPage === i ? "font-bold text-green-300" : ""
              }`}
            >
              {i}
            </button>
          );
        }
      } else {
        pageNumbers.push(
          <button
            key={1}
            onClick={() => handleClickPage(1)}
            className={`px-2 py-1 mx-1 ${
              currentPage === 1 ? "font-bold text-green-300" : ""
            }`}
          >
            1
          </button>
        );

        if (currentPage > 3) {
          pageNumbers.push(<span key="left-ellipsis">...</span>);
        }

        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(lastPage - 1, currentPage + 1);

        for (let i = startPage; i <= endPage; i++) {
          pageNumbers.push(
            <button
              key={i}
              onClick={() => handleClickPage(i)}
              className={`px-2 py-1 mx-1 ${
                currentPage === i ? "font-bold  text-green-300" : ""
              }`}
            >
              {i}
            </button>
          );
        }

        if (currentPage < lastPage - 2) {
          pageNumbers.push(<span key="right-ellipsis">...</span>);
        }

        pageNumbers.push(
          <button
            key={lastPage}
            onClick={() => handleClickPage(lastPage)}
            className={`px-2 py-1 mx-1 ${
              currentPage === lastPage ? "font-bold text-green-300" : ""
            }`}
          >
            {lastPage}
          </button>
        );
      }

      return pageNumbers;
    };

    return (
      <div className="flex justify-between items-center mt-4 space-x-2">
        <p>
          Page {currentPage} of {lastPage}
        </p>
        <div className="flex justify-center items-center space-x-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="disabled:opacity-50"
          >
            <ChevronsLeft size={20} />
          </button>

          <button
            onClick={() => setCurrentPage(prevPage)}
            disabled={!prevPage}
            className="disabled:opacity-50"
          >
            <ChevronLeft size={20} />
          </button>

          {renderPageNumbers()}

          <button
            onClick={() => setCurrentPage(nextPage)}
            disabled={!nextPage}
            className="disabled:opacity-50"
          >
            <ChevronRight size={20} />
          </button>

          <button
            onClick={() => setCurrentPage(lastPage)}
            disabled={currentPage === lastPage}
            className="disabled:opacity-50"
          >
            <ChevronsRight size={20} />
          </button>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="flex justify-between border-b border-black/20 pb-6">
        <div>
          <p className="font-bold text-[20px]">Contact</p>
        </div>
        {user.role_id !== 3 && (
          <button
            onClick={handleAddOptions}
            className="flex gap-1 relative bg-[#4F772D] w-[100px] h-[40px] rounded-md outline-none justify-center items-center text-white"
          >
            + {t("overview.add")}
            <Chevron />
          </button>
        )}
        {showAddOptions && (
          <div className="absolute top-[150px] z-40 right-12 rounded-md shadow-lg bg-white w-[180px] text-[14px] flex flex-col gap-2 py-2 ">
            <button
              onClick={handleCreateNewContact}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              {t("overview.createNewContact")}
            </button>
            <button
              onClick={handleImportCSV}
              className="hover:bg-black/30 duration-300 hover:bg-opacity-10 py-3 text-left px-4"
            >
              {t("overview.importCsv")}
            </button>
          </div>
        )}
      </div>{" "}
      <section>
        <div className="flex gap-6 border-b border-black/20 py-6">
          {user.role_id !== 3 && (
            <button
              onClick={async (event) => {
                event.preventDefault();
                setLoading(true);
                handleExportContactBatches();
                setLoading(false);
              }}
              className="flex gap-1 relative border border-black/20 text-[14px] w-[170px] h-[40px] rounded-md outline-none justify-center items-center text-black"
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <Download />
                  {t("overview.exportCsv")}
                </>
              )}
            </button>
          )}
          <div className="w-full relative">
            <input
              type="text"
              value={search}
              onChange={(event) => {
                setPage(1);
                setSearch(event.target.value);
              }}
              placeholder={t("search.searchByNumberOrName")}
              className="w-[100%] relative rounded-md outline-none border-none bg-black/10 focus:border-black/10 pl-12 text-[14px]"
            />
            <Search className="absolute mt-[-30px] ml-3" />
          </div>
        </div>
      </section>
      <section className="flex items-center flex-wrap gap-6 my-4">
        <div className="flex gap-2 items-center">
          {!Object.entries(contactList).length > 0 ? (
            ""
          ) : (
            <input
              type="checkbox"
              checked={
                selectedContacts.length === contactData?.data?.data.length
              }
              onChange={() => {
                selectedContacts.length === contactData?.data?.data.length
                  ? setSelectedContacts([])
                  : setSelectedContacts(
                      contactData?.data?.data?.map((d) => d.uuid)
                    );
              }}
              className="checked:bg-[#4F772D] rounded-sm focus:bg-[#4F772D] cursor-pointer"
            />
          )}
        </div>
        <button
          onClick={handleRefresh}
          className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-black/50 hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
        >
          <Refresh className="h-[20px] w-[20px]" />
          {t("overview.refresh")}
        </button>
        <div>
          {!Object.entries(contactList)?.length > 0 ? (
            ""
          ) : (
            <div className="flex gap-2 items-center">
              {user.role_id !== 3 && (
                <button
                  onClick={handleDeleteModal}
                  className="flex gap-2 w-[110px] h-[40px] items-center justify-center hover:bg-danger hover:border-0 hover:text-white duration-500 border-black/20 border text-[14px] rounded-md"
                >
                  <Trash className="h-[20px] w-[20px]" />
                  {t("overview.delete")}
                </button>
              )}

              <button
                onClick={handleSort}
                className={`flex gap-2 w-[180px] h-[40px] items-center justify-center ${
                  isAlphabeticallySorted
                    ? "bg-[#4F772D] text-white"
                    : "hover:bg-[#4F772D] hover:border-0 hover:text-white"
                } duration-500 border-black/20 border text-[14px] rounded-md`}
              >
                <SortDesc />
                {isAlphabeticallySorted ? "Reset Sort" : "Sort A-Z"}
              </button>
            </div>
          )}
        </div>
      </section>
      <div>
        {isPending ? (
          <div>
            <Spinner />
          </div>
        ) : Object.entries(contactList)?.length === 0 ? (
          <p className="mt-2 text-center font-bold">
            {t("overview.noContacts")}
          </p>
        ) : (
          <div className="flex flex-col gap-2 px-4">
            <Pagination />
            {Object.entries(contactList)?.map(([category, contacts]) => {
              return (
                <div key={category} className="flex gap-3 mt-2">
                  {isAlphabeticallySorted && category !== "all" && (
                    <h2 className="font-bold mt-2 text-[18px]">{category}</h2>
                  )}
                  <div className="w-full mt-2">
                    {contacts?.map((contact, index) => (
                      <div
                        key={index}
                        className="flex gap-4 items-center pt-2 my-3 hover:bg-black/20 hover:px-2 rounded-md cursor-pointer duration-500"
                      >
                        <input
                          type="checkbox"
                          checked={selectedContacts.includes(contact.uuid)}
                          onChange={() => {
                            const contactIndex = selectedContacts.findIndex(
                              (c) => c === contact.uuid
                            );

                            if (contactIndex !== -1) {
                              const clonedContacts = [...selectedContacts];
                              clonedContacts.splice(contactIndex, 1);
                              setSelectedContacts(clonedContacts);
                            } else {
                              setSelectedContacts([
                                ...selectedContacts,
                                contact.uuid,
                              ]);
                            }
                          }}
                          className="checked:bg-[#4F772D] rounded-sm focus:bg-[#4F772D] cursor-pointer"
                        />
                        <div className="flex border-b border-black/20 items-center w-full gap-4">
                          <div
                            onClick={() => handleContactDetails(contact)}
                            className="flex justify-between w-full items-center"
                          >
                            <div className="flex gap-2 pb-3 w-full items-center">
                              <Avatar
                                name={`${contact?.first_name} ${
                                  contact?.last_name ?? ""
                                }`}
                              />
                              <div className="flex flex-col gap-1">
                                <p className="text-[16px] font-semibold">
                                  {contact.first_name} {contact.last_name}
                                </p>

                                <p className="text-[14px] font-[400]">
                                  {user.role_id === 3
                                    ? maskNumber(contact.phone)
                                    : formatPhoneNumber(contact.phone)}
                                </p>
                              </div>
                            </div>
                            <div>
                              <InfoGrey className="text-[#4F772D] cursor-pointer" />
                            </div>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              _makeCall(contact.phone, contact.phone);
                            }}
                          >
                            <PhoneCall className="text-[#4F772D] cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {showModal === "newContact" && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <AddNewContact
              handleContactModalClose={handleModalClose}
              mutate={mutate}
              withoutBatch={true}
            />
          </div>
        </div>
      )}
      {showDeleteModal === "deleteContacts" && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleDeleteModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-[350px] h-[250px] flex flex-col justify-center text-center items-center overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="text-[20px] font-bold pb-2">
              {t("overview.deleteConfirmation")}
            </p>
            <div className="flex gap-4 mt-6 justify-center items-center">
              <button
                onClick={handleDeleteModalClose}
                className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
              >
                {t("overview.close")}
              </button>
              <button
                type="submit"
                className="bg-danger w-[100px] h-[40px] rounded flex items-center justify-center"
                onClick={async () => {
                  if (selectedContacts.length) {
                    await deleteContactMutate({
                      uuid: selectedContacts.join(","),
                    });
                  }
                  handleDeleteModalClose();
                  mutate({
                    page: 1,
                    limit: 1000,
                    search,
                  });
                }}
              >
                {t("overview.yes")}
              </button>
            </div>
          </div>
        </div>
      )}
      {showCSVModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCSVModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[550px] overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <ImportCSV
              handleCSVModalClose={handleCSVModalClose}
              withoutBatch={true}
            />
          </div>
        </div>
      )}
      {user.role_id !== 3 && activeContact && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleDetailsModalClose}
        >
          <div
            className="bg-white p-4 rounded-lg w-full sm:w-[500px] h-[500px] overflow-scroll"
            onClick={(e) => e.stopPropagation()}
          >
            <ContactDetails
              handleDetailsModalClose={handleDetailsModalClose}
              contact={activeContact}
              withoutBatch={true}
              mutate={mutate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DialContacts;
