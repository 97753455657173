/* eslint-disable no-unused-vars */
import { ZigiStart } from "assets/images";
import { useParams } from "react-router-dom";
import { Loader2 } from "lucide-react";

const { useEffect, useState, useRef } = require("react");
const { default: Footer } = require("./Footer");
const { default: Header } = require("./Header");
const { default: MessageBoard } = require("./MessageBoard");
const { toastEmitter } = require("components/Toast");
const { getToken } = require("shared/resources");
const { routes } = require("api/routes");
import axios from "axios";
import { useAuth } from "hooks/useAuth";
import { set } from "react-hook-form";
import { Button } from "components";

const InboxViewArea = () => {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [buttonClicked, setButtonClicked] = useState("");
  const [isHumanAgent, setIsHumanAgent] = useState(false);
  const [isInitialHumanAgent, setIsInitialHumanAgent] = useState(false);
  const addMessage = (from, body) => {
    setMessages((prev) => [...prev, { from, metadata: { body } }]);
  };
  const [chatIdentifier, setChatIdentifier] = useState(chatId);
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [needToJoinRoom, setNeedToJoinRoom] = useState(false);
  const { user, zigiSocket } = useAuth();

  const handleButtonClick = async (buttonType) => {
    addMessage(
      "user",
      buttonType.includes("_") ? buttonType.split("_").join(" ") : buttonType
    );
    let response = "";
    switch (buttonType) {
      case "menu":
        response = (
          <div className="w-[250px] font-[300] flex flex-col gap-2">
            <p>So, what are you doing today?</p>
            <p>
              😃Please <span className="font-bold">type in your request</span>{" "}
              using the phrases from the list below
            </p>
            <p className="font-bold">📝 My Balance</p>
            <p className="font-bold">🎮 Play Services</p>
            <p className="font-bold">😎 Play Hot Deals</p>
            <p className="font-bold">🎁 Zigi Daily Freebies</p>
            <p>
              You can also{" "}
              <span className="font-bold">
                select other options with the clickable buttons
              </span>
              👇🏾
            </p>
            <div className="absolute -bottom-4 left-10 flex items-center gap-2 w-[250px] flex-wrap">
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                💡 Airtime
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                💵 Data
              </button>
              <button
                onClick={() => handleButtonClick("assist")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                💁🏾‍♀️ Assist
              </button>
              <button
                onClick={() => handleButtonClick("menu")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                Menu
              </button>
              <button
                onClick={() => handleButtonClick("help")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                Help
              </button>
              <button
                onClick={() => handleButtonClick("exit")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                Exit
              </button>
            </div>
          </div>
        );
        break;
      case "help":
        response =
          "Need help? Here are some options:\n1. Check account details\n2. Create a ticket\n3. FAQs.";
        break;
      case "human_agent":
        setIsHumanAgent(true);
        localStorage.setItem("is_human_agent", true);
        setIsInitialHumanAgent(true);
        response = "Connecting you to a human agent...";
        break;
      case "exit":
        response =
          "Goodbye! Feel free to come back if you need further assistance.";
        break;
      case "assist":
        response = (
          <div className="flex flex-col gap-2 w-[250px]">
            <p>
              Please, <span className="font-bold">type in your request</span> as
              in the list or click a button if you need support
            </p>
            <p className="font-bold">📞 Tariff Plans</p>
            <p className="font-bold">🆔 NIN Services</p>
            <p className="font-bold">🔒 Get my PUK</p>
            <p className="font-bold">💓 Share Zigi</p>
            <div className="absolute -bottom-4 left-10 flex items-center gap-2 w-[250px] flex-wrap">
              <button
                onClick={() => {
                  handleButtonClick("human_agent");
                  setIsHumanAgent(true);
                }}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[200px] flex items-center justify-center bg-grey-200"
              >
                💁🏾‍♀️ Human Agent
              </button>
              <button
                onClick={() => handleButtonClick("menu")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                Menu
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                Help
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                Exit
              </button>
            </div>
          </div>
        );
        break;
      default:
        response = "I'm not sure how to handle that button.";
    }
    if (buttonType === "human_agent") {
      const newConversation = await createConversation();
      setConversation(newConversation);
      localStorage.setItem("conversation_uuid", newConversation.uuid);
      const botReply = mockZigiResponses("agent-connected");
      setMessages((prev) => [
        ...prev,
        { from: "zigi", metadata: { body: response } },
        { from: "zigi", metadata: { body: botReply } },
      ]);
    } else {
      addMessage("zigi", response);
    }
  };

  const mockZigiResponses = (userMessage, agentName = "Agent") => {
    if (userMessage.toLowerCase().includes("human")) {
      setIsHumanAgent(true);
      localStorage.setItem("is_human_agent", true);
      setIsInitialHumanAgent(true);
      return "Connecting you to a human agent...";
    }
    switch (userMessage.toLowerCase()) {
      case "hi":
      case "hello":
        return "Hi there! What can I help you with today?";
      case "agent-connected":
        return `${agentName} connected. Kindly describe your issue`;
      case "help":
        return "Sure! Here are some options:\n1. Check account details\n2. Create a ticket\n3. FAQs\nPlease type the number or keyword.";
      case "menu":
        return (
          <div className="w-[250px] font-[300] flex flex-col gap-2">
            <p>So, what are you doing today?</p>
            <p>
              😃Please <span className="font-bold">type in your request</span>{" "}
              using the phrases from the list below
            </p>
            <p className="font-bold">📝 My Balance</p>
            <p className="font-bold">🎮 Play Services</p>
            <p className="font-bold">😎 Play Hot Deals</p>
            <p className="font-bold">🎁 Zigi Daily Freebies</p>
            <p>
              You can also{" "}
              <span className="font-bold">
                select other options with the clickable buttons
              </span>
              👇🏾
            </p>
            <div className="absolute -bottom-4 left-10 flex items-center gap-2 w-[250px] flex-wrap">
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                💡 Airtime
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                💵 Data
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                💁🏾‍♀️ Assist
              </button>
              <button
                onClick={() => handleButtonClick("menu")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                Menu
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                Help
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                Exit
              </button>
            </div>
          </div>
        );
      case "assist":
        return (
          <div className="flex flex-col gap-2 w-[250px]">
            <p>
              Please, <span className="font-bold">type in your request</span> as
              in the list or click a button if you need support
            </p>
            <p className="font-bold">📞 Tariff Plans</p>
            <p className="font-bold">🆔 NIN Services</p>
            <p className="font-bold">🔒 Get my PUK</p>
            <p className="font-bold">💓 Share Zigi</p>
            <div className="absolute -bottom-4 left-10 flex items-center gap-2 w-[250px] flex-wrap">
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[200px] flex items-center justify-center bg-grey-200">
                💁🏾‍♀️ Human Agent
              </button>
              <button
                onClick={() => handleButtonClick("menu")}
                className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
              >
                Menu
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                Help
              </button>
              <button className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200">
                Exit
              </button>
            </div>
          </div>
        );
      default:
        return "I'm not sure how to help with that. Can you provide more details or type 'help' to see options?";
    }
  };

  const fetchMessages = async (chatId) => {
    setLoading(true);
    if (chatId === "zigi") {
      const zigiStart = (
        <div>
          <ZigiStart />
          <div className="pt-1 flex flex-col gap-2 w-[250px]">
            <p>Lets get you started! 😃</p>
            <p>Type in anything you would like me to do for you today 👇🏾</p>
            <p>
              For example:{" "}
              <span className="font-bold">
                I want to buy data/I want to check my balance
              </span>
            </p>
            <p>Or type in the options below:</p>
          </div>
          <div className="absolute bottom-10 left-10 flex items-center gap-2 w-[250px] flex-wrap">
            <button
              onClick={() => handleButtonClick("menu")}
              className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
            >
              Menu
            </button>
            <button
              onClick={() => handleButtonClick("help")}
              className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
            >
              Help
            </button>
            <button
              onClick={() => handleButtonClick("exit")}
              className="border-[#FFCC05] border rounded-full text-sm h-[40px] w-[70px] flex items-center justify-center bg-grey-200"
            >
              Exit
            </button>
          </div>
        </div>
      );
      const conversationUuid = localStorage.getItem("conversation_uuid");
      if (!conversationUuid) {
        setMessages([
          {
            from: "zigi",
            metadata: {
              body: zigiStart,
            },
          },
        ]);
      } else {
        try {
          const unparsedResponse = await fetch(
            `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION_MESSAGES_BY_ITERATION.URL}?conversationUuid=${conversationUuid}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken()}`,
                "X-Company-UUID": user?.company_uuid,
              },
            }
          );
          const response = await unparsedResponse.json();
          if (response && response.length > 0) {
            setMessages(response);
          } else {
            localStorage.removeItem("conversation_uuid");
            localStorage.removeItem("is_human_agent");
          }
        } catch (error) {
          console.log("error", error);
          toastEmitter("error", error);
        }
      }
    } else if (chatId !== "0") {
      localStorage.setItem("is_human_agent", true);
      localStorage.setItem("conversation_uuid", chatId);
      try {
        const unparsedResponse = await fetch(
          `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION_MESSAGES.URL}?conversationUuid=${chatId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
              "X-Company-UUID": user?.company_uuid,
            },
          }
        );
        const response = await unparsedResponse.json();
        setMessages(response);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          return (window.location.href = "/");
        } else {
          console.log("error", error);
          toastEmitter("error", error);
        }
      }
    } else {
      try {
        const unparsedResponse = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}${routes.LIST_WHATSAPP_MESSAGES.URL}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
              "X-Company-UUID": user?.company_uuid,
            },
          }
        );
        const response = await unparsedResponse.json();
        setMessages(response.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          return (window.location.href = "/");
        } else {
          console.log("error", error);
          toastEmitter("error", error);
        }
      }
    }
    if (chatId !== "0") {
      try {
        const conversationUuid =
          chatId === "zigi"
            ? localStorage.getItem("conversation_uuid")
            : chatId;
        if (conversationUuid) {
          const fetchConversations = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LIST_CONVERSATION_BY_UUID.URL}/${conversationUuid}/user/${user?.uuid}`,
                {
                  headers: {
                    Authorization: `Bearer ${getToken()}`,
                    "X-Company-UUID": user?.company_uuid,
                  },
                }
              );
              setConversation(response.data);
              setLoading(false);
            } catch (error) {
              console.error("Error fetching conversations:", error);
            }
          };
          await fetchConversations();
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const createConversation = async () => {
    try {
      const metadata = {
        status: "active",
        iteration: 1,
        name: `${user?.first_name} ${user?.last_name}`,
        company_name: user?.company_name,
      };

      const newConversation = {
        initiator: user?.uuid,
        company_uuid: "f583db32-2fe3-48e4-8955-019cd18cdb28",
        responder: "f583db32-2fe3-48e4-8955-019cd18cdb28",
        metadata,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.CREATE_CONVERSATION.URL}`,
        newConversation,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user?.company_uuid,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating conversation:", error);
    }
  };

  const endConversation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.END_CONVERSATION.URL}/${conversation.uuid}`,
        {
          displayName: `${user?.first_name} ${user?.last_name}`,
          userId: user?.uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user?.company_uuid,
          },
        }
      );
      setConversation(response.data);
    } catch (error) {
      console.error("Error ending conversation:", error);
    }
  };

  const joinRoom = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.JOIN_CONVERSATION.URL}/${conversation.uuid}`,
        {
          userId: user?.uuid,
          displayName: user?.first_name + " " + user?.last_name,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user?.company_uuid,
          },
        }
      );

      setNeedToJoinRoom(false);
    } catch (error) {
      console.error("Error joining conversation:", error);
    }
  };

  const leaveRoom = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_ZIGI_BASE_API_URL}${routes.LEAVE_CONVERSATION.URL}/${conversation.uuid}`,
        {
          userId: user?.uuid,
          displayName: user?.first_name + " " + user?.last_name,
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "X-Company-UUID": user?.company_uuid,
          },
        }
      );
      setNeedToJoinRoom(true);
    } catch (error) {
      console.error("Error leaving conversation:", error);
    }
  };

  const handleJoinRoom = (payload) => {
    if (payload?.userId !== user?.uuid) {
      setMessages((prev) => [
        ...prev,
        {
          from: "zigi",
          metadata: { body: `${payload.displayName} connected` },
          created_at: new Date(),
        },
      ]);
    }
  };

  const handleLeaveRoom = (payload) => {
    if (payload?.userId !== user?.uuid) {
      setMessages((prev) => [
        ...prev,
        {
          from: "zigi",
          metadata: { body: `${payload.displayName} disconnected` },
          created_at: new Date(),
        },
      ]);
    }
  };

  useEffect(() => {
    if (zigiSocket) {
      zigiSocket.on("conversationStarted", (conversation) => {
        setChatIdentifier(chatId);
        setConversation(conversation);
        fetchMessages(chatId);
      });

      zigiSocket.on("conversationEnded", (payload) => {
        console.log("conversationEnded", payload);
        if (payload?.userId !== user?.uuid) {
          setMessages((prev) => [
            ...prev,
            {
              type: "center",
              metadata: {
                body: `${payload.displayName} has ended the conversation`,
              },
              created_at: new Date(),
            },
          ]);
        } else {
          setMessages((prev) => [
            ...prev,
            {
              type: "center",
              metadata: {
                body: `You have ended the conversation`,
              },
              created_at: new Date(),
            },
          ]);
        }
        setConversation(payload.conversation);
      });

      zigiSocket.on("joinedRoom", handleJoinRoom);
      zigiSocket.on("leftRoom", handleLeaveRoom);
    }
  }, [zigiSocket]);

  useEffect(() => {
    setChatIdentifier(chatId);
    fetchMessages(chatId);
  }, [chatId]);

  useEffect(() => {
    if (
      conversation &&
      chatId !== "0" &&
      conversation?.metadata?.status === "active" &&
      (!conversation?.metadata?.members ||
        !conversation?.metadata?.members?.length ||
        !conversation?.metadata?.members.find(
          (m) => m?.uuid === user?.uuid && m?.activeResponder
        ))
    ) {
      setNeedToJoinRoom(true);
    } else {
      setNeedToJoinRoom(false);
    }
  }, [chatId, conversation]);

  const [showModal, setShowModal] = useState(false);

  return loading ? (
    <div className="h-screen flex justify-center items-center">
      <Loader2 className="animate-spin" />
    </div>
  ) : (
    <>
      <div className="flex items-center justify-between bg-white pt-5 px-6 pb-5">
        <Header
          chatId={chatIdentifier === "zigi" ? "Zigi Bot" : chatIdentifier}
          conversation={conversation}
          endConversation={endConversation}
          leaveRoom={leaveRoom}
          needToJoinRoom={needToJoinRoom}
        />
      </div>
      <span className="flex border-grey-300 border-b p-0 w-full "></span>
      <div className="pt-6 bg-white px-[60px] overflow-auto h-[calc(100vh_-_258px)]">
        <MessageBoard
          chatId={chatIdentifier}
          messages={messages}
          isZigiBot={chatIdentifier === "zigi"}
          setMessages={setMessages}
        />
      </div>
      {needToJoinRoom && (
        <div className="flex justify-center items-center w-[300px] my-6 m-auto">
          <Button
            // className="cursor-pointer text-blue-500 text-sm"
            onClick={() => setShowModal(true)}
          >
            Join Room
          </Button>
        </div>
      )}
      {chatId !== "0" &&
      conversation?.metadata?.status === "active" &&
      !needToJoinRoom ? (
        <Footer
          chatId={chatIdentifier}
          messages={messages}
          setMessages={setMessages}
          fetchMessages={fetchMessages}
          isZigiBot={chatIdentifier === "zigi"}
          mockZigiResponses={mockZigiResponses}
          humanAgent={
            chatIdentifier === "zigi" && localStorage.getItem("is_human_agent")
          }
          isInitialHumanAgent={isInitialHumanAgent}
          createConversation={createConversation}
          conversation={conversation}
          setConversation={setConversation}
          buttonClicked={buttonClicked}
        />
      ) : (
        <></>
      )}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={() => setShowModal(false)}
          />

          <div className="relative bg-white rounded-lg w-[400px] p-6">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              ✕
            </button>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Join Room</h3>
              <p className="text-grey-600">
                Are you sure you want to join room?
              </p>
            </div>

            <div className="flex justify-end gap-3">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 text-sm text-gray-600 border border-gray-300 rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  joinRoom();
                  setShowModal(false);
                }}
                className="px-4 py-2 text-sm text-white bg-green hover:bg-red-700 rounded-md"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InboxViewArea;
